import React from 'react'
import { Avatar, AvatarProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

type Props = AvatarProps & {
  imageSize?: number
}

const valueToPx = (value: number | string) => (typeof value === 'number' ? `${value}px` : value)

const StyledAvatar = styled(Avatar)`
  img {
    border-radius: ${(props: any) => valueToPx(props.borderRadius)};
  }
`

export const UserAvatar = ({ imageSize = 40, ...props }: Props) => {
  return <StyledAvatar {...props} />
}
