import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Flex } from '@chakra-ui/react'
import { CancelButton, CheckboxField, SelectField, SubmitButton, TextField } from 'components/form'
import {
  createUser,
  updateUser,
  User,
  UserPasswordTypes,
  rolesWithoutLocationRestrictions,
  BuiltinUserRoles,
  getRolesList,
  buildUserRoleOptions,
} from 'libs/api'
import { useAuthContext, useNotify, useQuery, useTranslations, useValidation } from 'hooks'
import { IntlShape, useIntl } from 'react-intl'
import translations from '../Actions.i18n.json'
import { Text } from 'components'
import { ZonePicker } from 'modules/Admin/components/ZonePicker'
import { requiredIf } from 'utils/form'
import { hasKeeeAdminAccess } from 'utils/access'

interface Props {
  item?: User
  onSuccess: () => void
  onCancel: () => void
}

interface Values {
  email: string
  roles: string[]
  locationIds: string[]
  passwordType: UserPasswordTypes
  firstName?: string
  lastName?: string
  userName: string
  temporaryPassword?: string
  manuallyEnroll: boolean
}

export const buildUserPasswordTypeOptions = (intl: IntlShape) => [
  { label: intl.formatMessage(translations.passwordTypeGenerate), value: UserPasswordTypes.Generate },
  { label: intl.formatMessage(translations.passwordTypeSpecify), value: UserPasswordTypes.Specify },
]

export const UpsertUser: React.FC<Props> = ({ onSuccess, onCancel, item }) => {
  const intl = useIntl()
  const t = useTranslations(translations)
  const snackbar = useNotify()
  const { currentRole } = useAuthContext()
  const isKeeeAdmin = hasKeeeAdminAccess(currentRole)
  const rolesQuery = useQuery(getRolesList)

  const includeKeeeAdmin = isKeeeAdmin || (item?.roles ?? []).includes(BuiltinUserRoles.KeeeAdmin)
  const { userRoleOptions, userRoleIds } = useMemo(() => {
    const userRoleOptions = buildUserRoleOptions(rolesQuery.data?.items ?? [], includeKeeeAdmin)
    return { userRoleOptions, userRoleIds: userRoleOptions.map((role) => role.value) }
  }, [rolesQuery.data, includeKeeeAdmin])

  const validationSchema = useValidation((rules) => {
    return rules.object({
      email: rules.email().required().label(t.emailLabel),
      roles: rules.array(rules.string().oneOf(userRoleIds).required()).label(t.roleLabel),
      locationIds: rules
        .array(rules.string().required())
        .when(
          'roles',
          requiredIf((roles: string[]) => roles.some((role) => !rolesWithoutLocationRestrictions.includes(role)))
        )
        .label(t.locationsLabel),
      userName: rules.string().when('manuallyEnroll', requiredIf()).label(t.userNameLabel),
      firstName: rules.string().when('manuallyEnroll', requiredIf()).label(t.firstNameLabel),
      lastName: rules.string().when('manuallyEnroll', requiredIf()).label(t.lastNameLabel),
      temporaryPassword: rules
        .password()
        .when(
          'passwordType',
          requiredIf((passwordType) => passwordType === UserPasswordTypes.Specify)
        )
        .label(t.passwordLabel),
    })
  })

  const onSubmit = async ({ firstName, lastName, roles, email, locationIds, userName, temporaryPassword }: Values) => {
    try {
      const basePayload = { firstName, lastName, roles, email, locationIds }
      if (item) {
        await updateUser(item, basePayload)
      } else {
        await createUser({ ...basePayload, userName, temporaryPassword })
      }
      snackbar.success(item ? t.editSuccess : t.createSuccess)
      onSuccess()
    } catch (e) {
      if (e?.response?.data?.code.code === 'UsernameExistsException') {
        snackbar.error(t.usernameExists)
      } else {
        snackbar.error()
      }
    }
  }

  const initialValues: Values = item
    ? {
        ...item,
        roles: item.roles.filter((role) => userRoleIds.includes(role)),
        locationIds: item.locationIds,
        passwordType: UserPasswordTypes.Generate,
        temporaryPassword: '',
        manuallyEnroll: true,
      }
    : {
        email: '',
        roles: [],
        locationIds: [],
        passwordType: UserPasswordTypes.Generate,
        firstName: '',
        lastName: '',
        userName: '',
        temporaryPassword: '',
        manuallyEnroll: true,
      }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form>
          <Flex direction="column" mt={6}>
            <TextField name="email" label={t.emailLabel} placeholder={t.emailPlaceholder} mb={6} />

            <SelectField
              mb={6}
              name="roles"
              label={t.roleLabel}
              placeholder={t.rolePlaceholder}
              options={userRoleOptions}
              isDisabled={!isKeeeAdmin && initialValues.roles.includes(BuiltinUserRoles.KeeeAdmin)}
              isMulti
            />

            {values.roles.some((role) => !rolesWithoutLocationRestrictions.includes(role)) && (
              <ZonePicker
                mb={6}
                name="locationIds"
                label={t.locationsLabel}
                placeholder={t.locationsPlaceholder}
                isMulti
              />
            )}

            {!item && <SelectField mb={6} name="passwordType" options={buildUserPasswordTypeOptions(intl)} />}

            {!item && values.passwordType === UserPasswordTypes.Specify && (
              <TextField
                type="password"
                name="temporaryPassword"
                label={t.passwordLabel}
                placeholder={t.passwordPlaceholder}
                mb={6}
              />
            )}

            {!item && (
              <CheckboxField mb={6} name="manuallyEnroll">
                <Text fontSize="xs" fontFamily="label" fontWeight="bold" color="dark.3">
                  {t.manuallyEnrollLabel}
                </Text>
              </CheckboxField>
            )}

            {values.manuallyEnroll && (
              <>
                <TextField name="firstName" label={t.firstNameLabel} placeholder={t.firstNamePlaceholder} mb={6} />

                <TextField name="lastName" label={t.lastNameLabel} placeholder={t.lastNamePlaceholder} mb={6} />

                {!item && (
                  <TextField name="userName" label={t.userNameLabel} placeholder={t.userNamePlaceholder} mb={6} />
                )}
              </>
            )}

            <Flex flexDirection="row-reverse" mt={10}>
              <SubmitButton>{item ? t.editButton : t.createButton}</SubmitButton>
              <CancelButton onClick={onCancel} mr={4} />
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
