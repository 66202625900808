import { useNotify, useRealTimeUpdates } from 'hooks'
import { AuthorizationEvent, DeviceGroup, DeviceItem, resolveAuthorizationEvent, TransitEvent } from 'libs/api'
import { map, noop, uniq, uniqBy } from 'lodash-es'
import { getInitialRemoteHistory } from 'modules/Locations/LocationZones/ZoneDetail/helpers/getInitialRemoteHistory'
import { transformHistoricEvent } from 'modules/Locations/LocationZones/ZoneDetail/helpers/transformHistoricEvent'
import { Channel, Socket } from 'phoenix'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import translations from './AuthorizationEventsProvider.i18n.json'
import {
  EventAcknowledgmentPayload,
  EventResolutionPayload,
  LanesLockPayload,
  LanesUnlockPayload,
  Message,
  MessageType,
} from './types'
import { useChellStore } from './useChellStore'

type SetRemoteMode = (value: boolean) => void

type ContextType = {
  connectToManagingDevices: (managingDevices: DeviceItem[]) => Promise<Socket[]>
  disconnectFromManagingDevices: (managingDevices: DeviceItem[]) => Promise<void[]>
  isConnecting: boolean
  isAllConnected: boolean
  lockedZones: string[]
  lockedLanes: string[]
  sendResolutionEvent: (managingDeviceId: string, data: EventResolutionPayload) => void
  sendAcknowledgmentEvent: (managingDeviceId: string, data: EventAcknowledgmentPayload) => void
  failedToConnect: boolean
  isRemote: boolean
  unlockLanes: (managingDeviceId: string, data: LanesUnlockPayload) => void
  lockLanes: (managingDeviceId: string, data: LanesLockPayload) => void
  setRemoteMode: SetRemoteMode
  setRemoteModeChangeCallback: (callback: SetRemoteMode) => void
  initializeRemoteHistory: (historicEvents: AuthorizationEvent[], deviceGroups: DeviceGroup[]) => void
  getDeviceGroup: (managingDeviceId: string, data: { device_group_id: string }) => void
  allowSingleTurnstileTransit: (
    managingDeviceId: string,
    data: { turnstile_id: string; direction: 'entry' | 'exit' }
  ) => void
}

const Context = React.createContext<ContextType | undefined>(undefined)

export const AuthorizationEventsConsumer = Context.Consumer

const MAX_CONNECTION_ERRORS = 3

export const AuthorizationEventsProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useChellStore((state) => state.dispatch)
  const deviceGroupsStates = useChellStore((state) => state.deviceGroupsStates)

  const intl = useIntl()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lockedZones, setLockedZones] = useState<string[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lockedLanes, setLockedLanes] = useState<string[]>([])
  const channelsRef = useRef<Record<string, Channel>>({})
  const [isConnecting, setIsConnecting] = useState(false)
  const [isAllConnected, setIsAllConnected] = useState(false)
  const [failedSocketsCount, setFailedSocketsCount] = useState(0)
  const notify = useNotify()
  const socketsRef = useRef<Record<string, Socket>>({})
  const socketErrorsRef = useRef<Record<string, number>>({})
  const [managingDeviceIds, setManagingDeviceIds] = useState<string[]>([])
  const [isRemote, setIsRemote] = useState(false)
  const [onRemoteModeChange, setOnRemoteModeChange] = useState<SetRemoteMode>(noop)

  const setRemoteModeChangeCallback = useCallback(
    (callback: SetRemoteMode) => setOnRemoteModeChange(() => callback),
    []
  )

  const getFailedSocketsCount = () =>
    Object.values(socketErrorsRef.current).filter((count) => count > MAX_CONNECTION_ERRORS).length

  // Remote/API events:
  useRealTimeUpdates<AuthorizationEvent>({
    channel: 'authorization_events',
    isDisabled: !isRemote,
    onMessage: ({ eventName, data: authEvent }) => {
      if (eventName === 'create') {
        dispatch({
          type: MessageType.AUTHORIZATION_EVENT,
          data: transformHistoricEvent(authEvent),
        })
      } else if (authEvent.resolution_id) {
        dispatch({
          type: MessageType.AUTHORIZATION_EVENT_RESOLVED,
          data: authEvent,
        })
      } else if (authEvent.acknowledged) {
        dispatch({
          type: MessageType.AUTHORIZATION_EVENT_ACKNOWLEDGED,
          data: { authorization_event_id: authEvent.authorization_event_id },
        })
      }
    },
  })
  useRealTimeUpdates<TransitEvent>({
    channel: 'transit_events',
    isDisabled: !isRemote,
    onMessage: ({ data: transitEvent }) => {
      if (transitEvent.status === 'passed') {
        dispatch({
          type: MessageType.TRANSIT_EVENT,
          data: transitEvent,
        })
      }
    },
  })

  const initializeRemoteHistory: ContextType['initializeRemoteHistory'] = useCallback(
    (historicEvents, deviceGroups) => {
      // Initialize lane status from historic events:
      for (const deviceGroup of deviceGroups) {
        const deviceGroupState = deviceGroupsStates[deviceGroup.device_group_id]
        if (!deviceGroupState || !deviceGroupState.currentAuthorizationEvents.length) {
          const deviceGroupHistory = getInitialRemoteHistory(historicEvents, deviceGroup)
          deviceGroupHistory.forEach((message) => dispatch(message))
        }
      }
    },
    [dispatch, deviceGroupsStates]
  )

  const connectToManagingDevices = useCallback(
    (managingDevices: DeviceItem[]) => {
      const socketErrors = socketErrorsRef.current
      const sockets = socketsRef.current

      let newManagingDeviceIds
      setManagingDeviceIds(
        (oldValue) => (newManagingDeviceIds = uniq([...oldValue, ...map(managingDevices, 'thing_id')]))
      )

      const connectionPromises = uniqBy(managingDevices, 'thing_id')
        .filter((managingDevice) => !sockets[managingDevice.thing_id]) // skip connecting to already connected
        .map(
          (managingDevice) =>
            new Promise<Socket>((resolvePromise, rejectPromise) => {
              const managingDeviceId = managingDevice.thing_id

              const protocol = process.env.REACT_APP_WEBSOCKET_PROTOCOL || 'wss'

              setIsConnecting(true)
              setIsAllConnected(false)
              socketErrors[managingDeviceId] = 0

              const socket = new Socket(`${protocol}://${managingDevice.local_ip_address}:1080/socket`, {
                params: { userToken: '123' },
              })

              console.log('Connecting to ' + socket.endPointURL())
              socket.connect()

              socket.onOpen(() => {
                console.log('Connected.')
                resolvePromise(socket)
                socketErrors[managingDeviceId] = 0
                setFailedSocketsCount(getFailedSocketsCount())
                sockets[managingDeviceId] = socket
                if (Object.keys(sockets).length === newManagingDeviceIds.length) {
                  setIsAllConnected(true)
                }
              })

              socket.onError(() => {
                console.log('Socket error, retrying...')
                socketErrors[managingDeviceId]++
                setIsAllConnected(false)
                if (socketErrors[managingDeviceId] > MAX_CONNECTION_ERRORS) {
                  console.log('Failed to connect')
                  rejectPromise()
                  socket.disconnect()
                  delete sockets[managingDeviceId]
                  setFailedSocketsCount(getFailedSocketsCount())
                }
              })

              const channel = socket.channel('default', { token: 'roomAuthToken' })
              channelsRef.current[managingDeviceId] = channel

              channel.onMessage = (type, payload) => {
                dispatch({ type, data: payload?.data } as Message)
                return payload
              }

              channel
                .join()
                .receive('ok', ({ messages }) => console.log('catching up', messages))
                .receive('error', ({ reason }) => console.log('failed join', reason))
                .receive('timeout', () => console.log('Networking issue. Still waiting...'))

              return socket
            })
        )

      const allPromises = Promise.all(connectionPromises)

      if (connectionPromises.length > 0) {
        allPromises
          .then(() => setIsAllConnected(true))
          .finally(() => {
            setFailedSocketsCount(getFailedSocketsCount())
            setIsConnecting(false)
          })
      }

      return allPromises
    },
    [dispatch]
  )

  const disconnectFromManagingDevices = useCallback((managingDevices: DeviceItem[]) => {
    const promises = managingDevices.map(
      (managingDevice) =>
        new Promise<void>((resolve) => {
          const socket = socketsRef.current[managingDevice.thing_id]
          if (!socket) return resolve()
          socket.onClose(() => {
            console.log('Socket closed.')
            delete socketsRef.current[managingDevice.thing_id]
            resolve()
          })
          socket?.disconnect()
        })
    )

    return Promise.all(promises)
  }, [])

  const sendResolutionEvent = useCallback(
    (managingDeviceId, data: EventResolutionPayload) => {
      if (isRemote) {
        resolveAuthorizationEvent(data.authorization_event_id, {
          resolution_id: data.resolution_id,
          note: data.note,
        })
        return
      }
      const channel = channelsRef.current[managingDeviceId]
      channel?.push('resolve_authorization_event', data)
    },
    [isRemote]
  )

  const sendAcknowledgmentEvent = useCallback(
    (managingDeviceId, data: EventAcknowledgmentPayload) => {
      if (isRemote) throw new Error('Unable to acknowledge events on a remote location')
      const channel = channelsRef.current[managingDeviceId]
      channel?.push('acknowledge_authorization_event', data)
    },
    [isRemote]
  )

  const lockLanes = useCallback((managingDeviceId, data: LanesLockPayload = []) => {
    const channel = channelsRef.current[managingDeviceId]
    data.forEach((deviceGroup) =>
      channel?.push(MessageType.DEVICE_GROUP, { ...deviceGroup, id: deviceGroup.device_group_id, status: 'locked' })
    )
  }, [])

  const unlockLanes = useCallback((managingDeviceId, data: LanesUnlockPayload = []) => {
    const channel = channelsRef.current[managingDeviceId]
    data.forEach((deviceGroup) =>
      channel?.push(MessageType.DEVICE_GROUP, { ...deviceGroup, id: deviceGroup.device_group_id, status: 'secure' })
    )
  }, [])

  const getDeviceGroup = useCallback((managingDeviceId, data) => {
    const channel = channelsRef.current[managingDeviceId]
    channel?.push(MessageType.GET_DEVICE_GROUP, data)
  }, [])

  const allowSingleTurnstileTransit = useCallback((managingDeviceId, data) => {
    const channel = channelsRef.current[managingDeviceId]
    channel?.push(MessageType.ALLOW_SINGLE_TURNSTILE_TRANSIT, data)
  }, [])

  const failedToConnect = !isRemote && failedSocketsCount >= managingDeviceIds.length && failedSocketsCount > 0
  useEffect(() => {
    if (!failedToConnect) return
    const toastId = notify.notifyWithActions(
      { description: intl.formatMessage(translations.failedToConnectMessage), duration: 10000, status: 'error' },
      [
        {
          title: intl.formatMessage(translations.switchToRemoteLabel),
          onClick: () => onRemoteModeChange(true),
        },
      ]
    )
    return () => {
      if (toastId) notify.close(toastId)
    }
  }, [failedToConnect, notify, onRemoteModeChange, intl])

  // Cleanup, probably useless
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const sockets = socketsRef.current
      Object.entries(sockets).forEach(([key, socket]) => {
        console.log('Disconnecting ', socket.endPointURL())
        socket.disconnect()
        delete sockets[key]
      })
    }
  }, [])

  return (
    <Context.Provider
      value={{
        isConnecting,
        isAllConnected,
        setRemoteMode: setIsRemote,
        setRemoteModeChangeCallback,
        initializeRemoteHistory,
        connectToManagingDevices,
        disconnectFromManagingDevices,
        lockedLanes,
        lockedZones,
        sendResolutionEvent,
        sendAcknowledgmentEvent,
        lockLanes,
        unlockLanes,
        failedToConnect,
        isRemote,
        getDeviceGroup,
        allowSingleTurnstileTransit,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export function useAuthorizationEvents() {
  const context = React.useContext(Context)
  if (!context) {
    throw Error('`useAuthorizationEvent` must be called inside AuthorizationEventsProvider')
  }
  return context
}
