import { lazy } from 'react'

export const Login = lazy(() => import('modules/Auth/Login/Login'))
export const SetupMFA = lazy(() => import('modules/Auth/SetupMFA/SetupMFA'))
export const ForgotPassword = lazy(() => import('modules/Auth/ForgotPassword/ForgotPassword'))
export const ResetPassword = lazy(() => import('modules/Auth/ResetPassword/ResetPassword'))
export const Admin = lazy(() => import('./Admin/Admin'))
export const UserDetail = lazy(() => import('./Admin/UserDetail'))
export const AccessGroupDetail = lazy(() => import('./Admin/AccessGroupDetail'))
export const Dashboard = lazy(() => import('modules/Dashboard'))
export const Settings = lazy(() => import('modules/Settings'))
export const Events = lazy(() => import('modules/Events'))
export const EventDetail = lazy(() => import('modules/Events/EventDetail'))
export const Devices = lazy(() => import('modules/Devices'))
export const DeviceDetail = lazy(() => import('modules/Devices/DeviceDetail'))
export const People = lazy(() => import('modules/People'))
export const PersonDetail = lazy(() => import('modules/People/PersonDetail'))
export const DeviceLiveFeed = lazy(() => import('modules/Devices/DeviceLiveFeed'))
export const TeamsNotifications = lazy(() => import('modules/Admin/TeamsNotifications'))
export const Locations = lazy(() => import('modules/Locations'))
export const LocationZoneDetail = lazy(() => import('modules/Locations/LocationZones/ZoneDetail'))
export const LocationZones = lazy(() => import('modules/Locations/LocationZones'))
export const LocationDevices = lazy(() => import('modules/Locations/LocationDevices'))
export const LocationDeviceDetail = lazy(() => import('modules/Locations/LocationDevices/LocationDeviceDetail'))
export const LocationEvents = lazy(() => import('modules/Locations/LocationEvents'))
export const LocationAccessGroups = lazy(() => import('modules/Locations/LocationAccessGroups'))
export const LocationAccessGroupDetail = lazy(() =>
  import('modules/Locations/LocationAccessGroups/LocationAccessGroupDetail')
)
export const LocationEmployees = lazy(() => import('modules/Locations/LocationEmployees'))
export const LocationVisitors = lazy(() => import('modules/Locations/LocationVisitors'))
export const Reporting = lazy(() => import('modules/Reporting'))
export const ReportingAccessEvents = lazy(() => import('modules/Reporting/AccessEvents'))
export const ReportingBadges = lazy(() => import('modules/Reporting/Badges'))
export const ReportingFacialRec = lazy(() => import('modules/Reporting/FacialRec'))
export const ReportingOverview = lazy(() => import('modules/Reporting/Overview'))
export const TurnstilesOverview = lazy(() => import('modules/Reporting/Turnstile'))
export const ReportingEmployees = lazy(() => import('modules/Reporting/Employees'))
export const ReportingOccupancy = lazy(() => import('modules/Reporting/Occupancy'))
export const ReportingInactivity = lazy(() => import('modules/Reporting/Inactivity'))
