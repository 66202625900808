import { SystemProps } from '@chakra-ui/react'
import { Chip } from 'components'
import { useBgColorFromShade } from 'components/core/utils'
import { useTranslations } from 'hooks'
import { Person, PersonListItem } from 'libs/api'
import React from 'react'
import translations from './BadgeEnabledChip.i18n.json'

type Props = SystemProps & {
  person: PersonListItem | Person
}

export const BadgeEnabledChip = ({ person, ...systemProps }: Props) => {
  const t = useTranslations(translations)
  const color = useBgColorFromShade(4)
  const isBadgeEnabled = person.badge?.status === 'Enabled'
  return (
    <Chip
      bgColor={isBadgeEnabled ? 'green.2' : 'red.0'}
      border="none"
      borderRadius={20}
      lineHeight={1}
      {...systemProps}
      color={color}
    >
      {isBadgeEnabled ? t.enabled : t.disabled}
    </Chip>
  )
}
