import React from 'react'
import { ChartValue, FocusedBar } from './types'
import { Box } from '@chakra-ui/react'

type Props = {
  focusedBar?: FocusedBar
  getTooltipLabel: (value: ChartValue) => string
}

export function BarChartTooltip({ focusedBar, getTooltipLabel }: Props) {
  return focusedBar ? (
    <Box
      bg="dark.1"
      borderRadius="8px"
      color="light.4"
      fontFamily="body"
      fontSize="xs"
      py={3}
      px={5}
      position="relative"
      transform="translateX(-50%) translateY(-130%)"
    >
      <Box
        width="10px"
        height="10px"
        bg="dark.1"
        transform=" translateY(50%)  translateX(-50%) rotate(45deg)"
        position="absolute"
        bottom={0}
        left="50%"
      />
      {getTooltipLabel(focusedBar.payload)}
    </Box>
  ) : null
}
