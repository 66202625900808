import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const PagerDutyIcon = createIcon({
  displayName: 'PagerDutyIcon',
  path: (
    <>
      <path
        fill="#06AC38"
        d="M20.3 1c-5.8 1.2-9.4 3.3-13.5 7.8C.3 16 .5 12.6.5 98c0 86-.3 82 6.7 89.6 7.6 8.3 3.2 7.9 90.8 7.9s83.2.4 90.8-7.9c7-7.6 6.7-3.6 6.7-89.6 0-63.1-.3-78.2-1.4-81.4-1.8-5.3-7.7-11.5-13.1-14C176.6.5 175.3.5 100.5.3 58.7.3 22.6.6 20.3 1zm94 45.9c8.5 3.3 13.7 7.7 17.2 14.6 2.7 5.3 3 6.8 3 14.9-.1 11.5-2.6 17.7-9.7 24.1-8.3 7.4-13.5 8.5-40.9 8.5H61V43.8l23.8.4c21.6.4 24.2.7 29.5 2.7zM77 137v15H61v-30h16v15z"
      />
      <path
        fill="#06AC38"
        d="M77 76.9V95h11.8c17.4 0 23.7-2.4 27.3-10.4 4-8.8 1.2-18.8-6.3-22.8-3.8-2-6.1-2.3-18.5-2.6L77 58.8v18.1z"
      />
    </>
  ),
  viewBox: '-10 -15 230 230',
})
