import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { useMediaDevices } from 'hooks'
import { ActionButton } from '../core'
import { AiFillCamera } from 'react-icons/ai'
import 'cropperjs/dist/cropper.css'
import Cropper from 'react-cropper'
import { Select } from '@chakra-ui/react'
import styled from '@emotion/styled'

const CameraContainer = styled.div`
  position: relative;
`

const CameraActions = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`

type CameraProps = {
  onFinish?: (blob: Blob) => void
  width: number
}

export const Camera: React.FC<CameraProps> = (props) => {
  const camera = useRef<Webcam>(null)
  const [snapshotSrc, setSnapshotSrc] = useState<string | null>(null)
  const cropperRef = useRef<Cropper | null>(null)
  const [mediaDeviceId, setMediaDeviceId] = useState<string | null>(null)
  const mediaDevices = useMediaDevices('videoinput')

  async function onFinish() {
    const blob = await new Promise<Blob>((resolve, reject) =>
      cropperRef.current!.getCroppedCanvas().toBlob((blob) => (blob ? resolve(blob) : reject()))
    )
    props.onFinish?.(blob)
  }

  return (
    <CameraContainer style={{ width: props.width }}>
      {snapshotSrc != null ? (
        <>
          <Cropper
            style={{ width: `${props.width}px` }}
            initialAspectRatio={2 / 3}
            aspectRatio={2 / 3}
            preview=".img-preview"
            src={snapshotSrc!}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              cropperRef.current = instance
            }}
          />
          <CameraActions>
            <ActionButton onClick={() => setSnapshotSrc(null)}>
              <AiFillCamera />
            </ActionButton>
            <ActionButton onClick={() => onFinish()}>Finish</ActionButton>
          </CameraActions>
        </>
      ) : null}
      {!snapshotSrc ? (
        <>
          <Select
            placeholder="Select option"
            value={mediaDeviceId || undefined}
            onChange={(e) => setMediaDeviceId(e.target.value)}
          >
            <option>Default</option>
            {mediaDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Select>
          <Webcam
            ref={camera}
            width={props.width}
            videoConstraints={mediaDeviceId ? { deviceId: mediaDeviceId } : undefined}
          />
          <CameraActions>
            <ActionButton onClick={() => setSnapshotSrc(camera.current!.getScreenshot())}>
              <AiFillCamera />
            </ActionButton>
          </CameraActions>
        </>
      ) : null}
    </CameraContainer>
  )
}
