import React from 'react'
import { useField } from 'formik'
import { PhotoUpload } from 'components/PhotoUpload'
import { FormControl, FormControlProps, FormErrorMessage } from '@chakra-ui/react'
import { DropzoneOptions } from 'react-dropzone'

type Props = {
  name: string
  label?: string
  placeholder?: string
  options?: DropzoneOptions
} & FormControlProps

export function PhotoUploadField({ name, label, placeholder, options, ...props }: Props) {
  const [field, meta, helpers] = useField(name)
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      <PhotoUpload {...field} onURL={helpers.setValue} label={label} placeholder={placeholder} options={options} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
