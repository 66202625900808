import React, { forwardRef, useLayoutEffect } from 'react'
import Konva from 'konva'
import { Image } from 'react-konva'
import { useBarcodeImage } from '../../hooks'

type Props = { code: string; onLoaded?: (image: HTMLImageElement) => void } & Omit<Konva.ImageConfig, 'image'>

export const Barcode = forwardRef(({ code, onLoaded, ...imageConf }: Props, ref) => {
  const image = useBarcodeImage(code)

  useLayoutEffect(() => {
    onLoaded?.(image)
  }, [image, onLoaded])

  return <Image {...imageConf} image={image} ref={ref as any} />
})
