import { Box } from 'components'
// import { useViewMode } from 'hooks/useViewMode'
import { DebouncedSearchInput } from './DebouncedSearchInput'
// import { MapSearch } from 'modules/Common/MapView'
import React from 'react'

type Props = {
  placeholder: string
  initialValue: string
  setValue: (search: string) => void
}

export function ViewModeSearch({ placeholder, initialValue, setValue }: Props) {
  // const { activeMode } = useViewMode()
  return (
    <Box mb={4}>
      {/* {activeMode === ViewMode.MAP ? (
        <MapSearch placeholder={placeholder} initialValue={initialValue} />
      ) : ( */}
      <DebouncedSearchInput placeholder={placeholder} initialValue={initialValue} onDebounce={setValue} />
      {/* )} */}
    </Box>
  )
}
