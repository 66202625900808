import { useMemo } from 'react'
import { DOMImplementation, XMLSerializer } from 'xmldom'
import Jsbarcode from 'jsbarcode'

export const useBarcode = (barcode: string) => {
  return useMemo(() => {
    const xmlSerializer = new XMLSerializer()
    const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null)
    const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    Jsbarcode(svgNode, barcode, {
      format: 'CODE128',
      xmlDocument: document,
      width: 4,
      displayValue: false,
      height: 115,
    })

    return xmlSerializer.serializeToString(svgNode)
  }, [barcode])
}

export const useBarcodeImage = (code: string) => {
  const svgBarcode = useBarcode(code)

  return useMemo(() => {
    let blob = new Blob([svgBarcode], { type: 'image/svg+xml' })
    const img = document.createElement('img')
    img.src = URL.createObjectURL(blob)
    return img
  }, [svgBarcode])
}
