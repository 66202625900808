import React from 'react'
import { ParsedColumn, ParsedColumns } from '../helpers/parseColumns'
import { TableHeadCell, TableHeadRow } from '../../../../components/core/Table'
import { Icon } from '@chakra-ui/react'
import { OrderDirection } from 'libs/api'
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons'

export type OrderProps = {
  orderBy?: string
  orderDirection: OrderDirection
  setOrderBy: (orderBy: string) => void
  setOrderDirection: (orderDirection: OrderDirection) => void
}

interface Props<T> {
  columns: ParsedColumns<T>
  orderProps?: OrderProps
}

export function ListPageHeader<T>({ columns, orderProps }: Props<T>) {
  function handleClick(column: ParsedColumn<T>) {
    if (orderProps && column.sortKey) {
      orderProps.setOrderBy(column.sortKey)
      orderProps.setOrderDirection(
        orderProps.orderBy !== column.sortKey
          ? orderProps.orderDirection
          : orderProps.orderDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
      )
    }
  }
  return (
    <TableHeadRow>
      {columns.map((column) => (
        <TableHeadCell
          key={column.key}
          width={column.width}
          onClick={column.sortKey ? () => handleClick(column) : undefined}
          textAlign={column.textAlign}
        >
          {column.title}{' '}
          {column.sortKey && orderProps?.orderBy === column.sortKey && (
            <Icon as={orderProps.orderDirection === 'ASC' ? TriangleUpIcon : TriangleDownIcon} boxSize="7px" />
          )}
        </TableHeadCell>
      ))}
    </TableHeadRow>
  )
}
