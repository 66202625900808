import { noop } from 'lodash-es'
import React, { ReactNode, useEffect, useState } from 'react'
import { ViewMode } from './types'

export type ViewModesContextType = {
  activeMode: ViewMode
  setActiveMode: (mode: ViewMode) => void
}

export const ViewModesContext = React.createContext<ViewModesContextType>({
  activeMode: ViewMode.LIST,
  setActiveMode: noop,
})

const buildKey = (key: string) => `list.${key}-view-mode`
const getStorageMode = (key: string) => (localStorage.getItem(buildKey(key)) as ViewMode) ?? ViewMode.LIST

export function ViewModesContextProvider({ children, storageKey }: { children: ReactNode; storageKey: string }) {
  const [viewMode, setViewMode] = useState<ViewMode>(() => getStorageMode(storageKey))

  useEffect(() => {
    localStorage.setItem(buildKey(storageKey), viewMode)
  }, [viewMode, storageKey])

  return (
    <ViewModesContext.Provider value={{ activeMode: viewMode, setActiveMode: setViewMode }}>
      {children}
    </ViewModesContext.Provider>
  )
}
