import { FormatDateOptions, useIntl } from 'react-intl'
import { parseISO } from 'date-fns'

// See https://devhints.io/wip/intl-datetime
type Options = FormatDateOptions & {
  timePos?: 'start' | 'end' | 'none'
  timeSpace?: boolean
  timeLower?: boolean
  includeSeconds?: boolean
  // redundant but serves as documentation:
  year?: 'numeric' | '2-digit'
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
  day?: 'numeric' | '2-digit'
}

export function useFormatDate() {
  const intl = useIntl()
  return (
    value: string | Date | number,
    { includeSeconds, timeSpace = true, timePos = 'end', timeLower = true, ...dateOptions }: Options = {}
  ) => {
    const isoDate = typeof value === 'string' ? parseISO(value) : value
    const date = intl.formatDate(isoDate, {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      ...dateOptions,
    })
    if (timePos === 'none') return date

    let time = intl.formatTime(isoDate, {
      hour: 'numeric',
      minute: 'numeric',
      second: includeSeconds ? 'numeric' : undefined,
    })
    if (timeLower) {
      time = time.toLowerCase()
    }
    if (!timeSpace) {
      // space between "hh:mm" and "am/pm"
      time = time.replace(' ', '')
    }
    return timePos === 'start' ? `${time} ${date}` : `${date} ${time}`
  }
}
