import React from 'react'
import { Form, Formik } from 'formik'
import { Flex, Text } from '@chakra-ui/react'
import { SubmitButton, TextField, CancelButton } from 'components/form'
import {
  createAuthorizationEventResolution,
  CreateAuthorizationEventResolutionPayload,
  AuthorizationEventResolution,
  updateAuthorizationEventResolution,
} from 'libs/api'
import { useNotify, useTranslations, useValidation } from 'hooks'
import translations from '../Actions.i18n.json'

interface Props {
  item?: AuthorizationEventResolution
  onSuccess: () => void
  onCancel: () => void
}

export const UpsertAuthorizationEventResolution: React.FC<Props> = ({ onSuccess, onCancel, item }) => {
  const t = useTranslations(translations)
  const snackbar = useNotify()

  const validationSchema = useValidation((rules) =>
    rules.object({
      name: rules.string().required().label(t.authorizationEventResolutionNameLabel),
    })
  )

  const onSubmit = async (values: CreateAuthorizationEventResolutionPayload) => {
    try {
      await (item ? updateAuthorizationEventResolution(item, values) : createAuthorizationEventResolution(values))
      snackbar.success(item ? t.editSuccess : t.createSuccess)
      onSuccess()
    } catch (e) {
      snackbar.error()
    }
  }

  const initialValues = item ?? { name: '' }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Text>{t.createDescription}</Text>

        <Flex direction="column" mt={6}>
          <TextField
            name="name"
            label={t.authorizationEventResolutionNameLabel}
            placeholder={t.authorizationEventResolutionNamePlaceholder}
            mb={6}
          />

          <Flex flexDirection="row-reverse" mt={10}>
            <SubmitButton>{item ? t.updateButton : t.createButton}</SubmitButton>
            <CancelButton onClick={onCancel} mr={4} />
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}
