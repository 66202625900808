import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const FaceIcon = createIcon({
  displayName: 'FaceIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47998 2 2 6.48 2 12C2 17.52 6.47998 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM7.75 13C7.75 12.31 8.31006 11.75 9 11.75C9.68994 11.75 10.25 12.31 10.25 13C10.25 13.69 9.68994 14.25 9 14.25C8.31006 14.25 7.75 13.69 7.75 13ZM13.75 13C13.75 12.31 14.3101 11.75 15 11.75C15.6899 11.75 16.25 12.31 16.25 13C16.25 13.69 15.6899 14.25 15 14.25C14.3101 14.25 13.75 13.69 13.75 13ZM4 12C4 16.41 7.58997 20 12 20C16.41 20 20 16.41 20 12C20 11.21 19.88 10.45 19.67 9.74C18.95 9.91 18.2 10 17.42 10C14.05 10 11.0699 8.32999 9.26001 5.77C8.28003 8.16 6.41003 10.09 4.05005 11.14C4.02002 11.42 4 11.71 4 12Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
