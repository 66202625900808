import { SystemProps } from '@chakra-ui/react'
import React from 'react'
import { Box } from '../Box'

type Props = Omit<SystemProps, 'width'> & {
  borderSpacing?: string
}

export const Table: React.FC<Props> = ({ children, borderSpacing, ...systemProps }) => {
  const sx = {
    width: '100%',
    position: 'relative',
    borderCollapse: 'separate',
    borderSpacing: borderSpacing ?? '0 0.5rem',
  }
  return (
    <Box as="table" sx={sx} {...systemProps}>
      {children}
    </Box>
  )
}
