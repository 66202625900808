import { EventType, getEventTypesList } from 'libs/api'
import React from 'react'
import { Column, ListPage } from 'modules/Common/ListPage'
import translations from './EventType.i18n.json'
import { IntlShape, useIntl } from 'react-intl'
import { Actions } from './Actions/Actions'
import { buildActionColumns } from '../../helpers/buildActionColumns'
import { EventTypeEditButton } from './Actions/EventTypeEditButton/EventTypeEditButton'
import { EventTypeDeleteButton } from './Actions/EventTypeDeleteButton/EventTypeDeleteButton'
import { Flex, SignedImage, useOmniSearchType } from 'components'

export const buildColumns = (intl: IntlShape, withActions = true): Column<EventType>[] => [
  {
    key: 'avatar',
    width: 10,
    render: (item) =>
      item.image_url && (
        <Flex bgShade={1} boxSize="40px" borderRadius={2} align="center" justify="center">
          <SignedImage src={item.image_url} objectFit="cover" boxSize="24px" />
        </Flex>
      ),
  },
  {
    title: intl.formatMessage(translations.name),
    field: 'name',
    width: '25%',
  },
  {
    title: intl.formatMessage(translations.description),
    field: 'description',
  },
  ...(withActions
    ? buildActionColumns({
        EditButton: EventTypeEditButton,
        DeleteButton: EventTypeDeleteButton,
      })
    : []),
]

export function EventTypes({ search }: { search: string }) {
  useOmniSearchType('eventTypes')
  const intl = useIntl()

  return (
    <ListPage
      fetchItems={getEventTypesList}
      columns={buildColumns(intl)}
      getId={(item) => item.event_type_id}
      components={{
        Actions,
      }}
      countLabel={translations.eventTypesCount}
      variables={{ search }}
      itemName={intl.formatMessage(translations.eventType)}
      realTimeChannel="event_types"
    />
  )
}
