import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useHealthMode } from './useHealthMode'

export function useHistoryFilters<T extends {}>(defaultFilters: T = {} as T) {
  const history = useHistory<{ filters: T } | undefined>()
  const initialFilters = history.location.state?.filters ?? defaultFilters
  const [filters, setFilters] = useState<T>(initialFilters)

  const { healthMode } = useHealthMode()
  const lastHealthMode = useRef<boolean>()
  useEffect(() => {
    if (lastHealthMode.current !== undefined) setFilters(defaultFilters)
    lastHealthMode.current = healthMode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthMode])

  return [filters, setFilters] as const
}
