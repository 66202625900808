import { Box, Divider, Flex, ViewMode, ViewModes } from 'components'
import React, { ReactNode } from 'react'
import { ItemCount, ItemCountProps } from './ItemCount'

type Props = ItemCountProps & {
  modes?: ViewMode[]
  children?: ReactNode
}

export const ActionBar = ({
  modes = [ViewMode.LIST, ViewMode.GRID],
  // modes = [ViewMode.LIST, ViewMode.GRID, ViewMode.MAP],
  children,
  ...itemCountProps
}: Props) => {
  return (
    <Flex align="center" justify="flex-end" my={4}>
      <Box flex={1}>
        <ItemCount {...itemCountProps} />
      </Box>
      <ViewModes modes={modes} justifyContent="flex-end" />
      {modes.length > 0 && <Divider orientation="vertical" h="24px" borderColor="dark.4" mx={4} />}
      {children}
    </Flex>
  )
}
