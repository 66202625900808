import React from 'react'
import { useField } from 'formik'
import { FormControl, FormControlProps, FormErrorMessage, Switch, Box } from '@chakra-ui/react'
import { FormLabel } from './FormLabel'

export type SwitchFieldProps = {
  label?: string
  name: string
} & FormControlProps

export const SwitchField = ({ label, name, id, ...props }: SwitchFieldProps) => {
  const [field, meta] = useField(name)
  const { value, ...fieldProps } = field
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Box>
        <Switch id={id} isChecked={value} {...fieldProps} />
      </Box>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
