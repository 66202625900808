import React, { createContext } from 'react'
import { ShadeValue } from '../utils/shade'

export const TableStyleContext = createContext<{ bgShade: ShadeValue }>({ bgShade: 4 })

type Props = {
  bgShade: ShadeValue
  children: React.ReactNode
}
export function TableStyleProvider({ bgShade, children }: Props) {
  return <TableStyleContext.Provider value={{ bgShade }}>{children}</TableStyleContext.Provider>
}
