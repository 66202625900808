import { fetchCurrentUserRoles, Role } from 'libs/api'
import { noop } from 'lodash-es'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { CognitoUser } from 'types'
import PubSub from 'pubsub-js'
import { queryClient, useQuery } from 'hooks'
import ErrorState from './State/ErrorState'

export type AuthContextType = {
  currentAuthenticatedUser?: CognitoUser
  setCurrentAuthenticatedUser: (user?: CognitoUser) => void
  challengeUser?: CognitoUser
  setChallengeUser: (challengeUser?: CognitoUser) => void
  resetPasswordUsername?: string
  setResetPasswordUsername: (username: string | undefined) => void
  currentRole?: Role
  setCurrentRole: (role: Role) => void
}

export const AuthContext = React.createContext<AuthContextType>({
  setCurrentAuthenticatedUser: noop,
  setChallengeUser: noop,
  setResetPasswordUsername: noop,
  setCurrentRole: noop,
})

type Props = Pick<AuthContextType, 'currentAuthenticatedUser' | 'setCurrentAuthenticatedUser'> & {
  children: ReactNode
}

const currentRoleIdKey = 'currentRoleId'
const userRolesInitialData: Role[] = JSON.parse(localStorage.getItem('fetchCurrentUserRoles') ?? '[]')

let refreshPromise: Promise<unknown>

export async function getCurrentRoleHeader() {
  await refreshPromise
  return localStorage.getItem(currentRoleIdKey) ?? ''
}

export const AuthContextProvider = ({ children, currentAuthenticatedUser, setCurrentAuthenticatedUser }: Props) => {
  const [challengeUser, setChallengeUser] = useState<CognitoUser>()
  const [resetPasswordUsername, setResetPasswordUsername] = useState<string>()
  const [currentRole, _setCurrentRole] = useState<Role>()
  const { data: userRoles, refetch, error } = useQuery(
    fetchCurrentUserRoles,
    {},
    {
      enabled: Boolean(currentAuthenticatedUser),
      // Initialize with the stored values, but refresh immediately:
      staleTime: 0,
      initialData: userRolesInitialData,
    }
  )

  const setCurrentRole = useCallback((role?: Role) => {
    localStorage.setItem(currentRoleIdKey, role?.role_id ?? '')
    _setCurrentRole((oldRole) => {
      if (oldRole) setTimeout(() => queryClient.invalidateQueries(), 10)
      return role
    })
  }, [])

  useEffect(() => {
    PubSub.subscribe('currentRoleError', () => {
      setCurrentRole(undefined)
      if (userRoles) refreshPromise = refetch()
    })
    return () => PubSub.unsubscribe('currentRoleError')
  }, [setCurrentRole, refetch, userRoles])

  useEffect(() => {
    if (!currentAuthenticatedUser) return
    localStorage.setItem('tenantId', currentAuthenticatedUser.attributes['custom:tenant_id'])

    if (!userRoles) return
    localStorage.setItem('fetchCurrentUserRoles', JSON.stringify(userRoles))
    const storedRoleId = localStorage.getItem(currentRoleIdKey)
    const currentRole = userRoles.find((role) => role.role_id === storedRoleId) ?? userRoles[0]
    setCurrentRole(currentRole)
  }, [currentAuthenticatedUser, setCurrentRole, setCurrentAuthenticatedUser, userRoles, refetch])

  if (error) return <ErrorState retry={refetch} />

  const authProps: AuthContextType = {
    currentAuthenticatedUser,
    setCurrentAuthenticatedUser,
    challengeUser,
    setChallengeUser,
    resetPasswordUsername,
    setResetPasswordUsername,
    currentRole,
    setCurrentRole,
  }

  return <AuthContext.Provider value={authProps}>{children}</AuthContext.Provider>
}
