import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const DevicesIcon = createIcon({
  displayName: 'DevicesIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.521 3.09935C5.17933 1.44102 7.471 0.416016 10.0043 0.416016C12.5377 0.416016 14.8293 1.44102 16.4793 3.10768L15.3043 4.28268C13.9543 2.92435 12.0793 2.08268 10.0043 2.08268C7.92933 2.08268 6.05433 2.92435 4.696 4.27435L3.521 3.09935ZM5.87933 5.45768L7.05433 6.63268C7.81266 5.88268 8.85433 5.41602 10.0043 5.41602C11.1543 5.41602 12.196 5.88268 12.9543 6.63268L14.1293 5.45768C13.071 4.39935 11.6127 3.74935 10.0043 3.74935C8.396 3.74935 6.93766 4.39935 5.87933 5.45768ZM6.671 7.91602H13.3377V19.5827H6.671V7.91602ZM8.33766 11.2493C8.33766 12.166 9.08766 12.916 10.0043 12.916C10.921 12.916 11.671 12.166 11.671 11.2493C11.671 10.3327 10.921 9.58268 10.0043 9.58268C9.08766 9.58268 8.33766 10.3327 8.33766 11.2493Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
})
