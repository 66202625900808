import React from 'react'
import { SidebarNav, SidebarNavLink, SidebarNavProps, Divider, OnlineIndicator, TenantSelector } from 'components'
import { Box } from '@chakra-ui/react'
import translations from './MainLayout.i18n.json'
import { useAuthContext, useTranslations } from 'hooks'
import { SettingsIcon } from '@chakra-ui/icons'
import { PeopleIcon, DevicesIcon, AdminIcon, LogoutIcon, ReportingIcon, ThermometerIcon } from 'components/icons'
import { useHealthMode } from 'hooks/useHealthMode'
import { can, hasKeeeAdminAccess } from 'utils/access'
import { MdLayers } from 'react-icons/md'
import { useConnectionStatus } from '../../../hooks/useConnectionStatus'
import * as routes from 'routes'

type Props = SidebarNavProps & {
  onLogout: () => any
}

export function MainNavigation({ onLogout, ...navigationProps }: Props) {
  const t = useTranslations(translations)
  const { currentRole } = useAuthContext()
  const { healthMode } = useHealthMode()
  const online = useConnectionStatus()
  const onlineStyles = online ? {} : { opacity: 0.3, pointerEvents: 'none' as const }
  const isKeeeAdmin = hasKeeeAdminAccess(currentRole)

  return (
    <SidebarNav {...navigationProps}>
      {isKeeeAdmin && !navigationProps.isCollapsed && (
        <Box pb={2} pt={2} pr={4} mb={4}>
          <TenantSelector />
        </Box>
      )}
      <Box flex={1}>
        {/* <SidebarNavLink exact to={routes.home} icon={HomeIcon}>
          {t.dashboard}
        </SidebarNavLink> */}
        {can(currentRole, 'list', 'zones.root') && (
          <SidebarNavLink to={routes.locations} icon={MdLayers}>
            {t.locations}
          </SidebarNavLink>
        )}
        {/* <SidebarNavLink to={routes.events} icon={BellIcon}>
          {t.events}
        </SidebarNavLink> */}
        {can(currentRole, 'list', 'people') && (
          <Box style={onlineStyles}>
            <SidebarNavLink to={routes.people} icon={PeopleIcon}>
              {t.people}
            </SidebarNavLink>
          </Box>
        )}
        {can(currentRole, 'list', 'things') && (
          <Box style={onlineStyles}>
            <SidebarNavLink to={routes.devices} icon={healthMode ? ThermometerIcon : DevicesIcon}>
              {t.devices}
            </SidebarNavLink>
          </Box>
        )}
        {can(currentRole, 'list', 'users') && ( // TODO should we check any other subject?
          <Box style={onlineStyles}>
            <SidebarNavLink to={routes.admin} icon={AdminIcon}>
              {t.admin}
            </SidebarNavLink>
          </Box>
        )}
        {can(currentRole, 'list', 'reporting') && (
          <Box style={onlineStyles}>
            <SidebarNavLink to={routes.reporting} icon={ReportingIcon}>
              {t.reporting}
            </SidebarNavLink>
          </Box>
        )}
      </Box>
      <OnlineIndicator />
      <Divider mr={6} />
      {/* <DarkModeSwitch /> */}
      <Box>
        <Box style={onlineStyles}>
          <SidebarNavLink to="/settings" icon={SettingsIcon}>
            {t.settings}
          </SidebarNavLink>
        </Box>
        <SidebarNavLink icon={LogoutIcon} onClick={onLogout}>
          {t.logOut}
        </SidebarNavLink>
      </Box>
    </SidebarNav>
  )
}
