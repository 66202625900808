import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 4H19V6H5V4H8.5L9.5 3H14.5L15.5 4ZM18 21H6V7H18V21Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
