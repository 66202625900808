import { ButtonProps } from '@chakra-ui/react'
import { ButtonOutlined } from '../core/Button'
import { useFormikContext } from 'formik'
import React, { ReactNode } from 'react'
import translations from './form.i18n.json'
import { useTranslations } from 'hooks'

export const CancelButton = (props: Omit<ButtonProps, 'children'> & { children?: ReactNode }) => {
  const t = useTranslations(translations)
  const { isSubmitting } = useFormikContext()
  return (
    <ButtonOutlined isDisabled={isSubmitting} {...props}>
      {props.children ?? t.cancel}
    </ButtonOutlined>
  )
}
