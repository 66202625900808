import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useAuthContext } from '../../hooks'
import { Modal, ModalOverlay, ModalContent, Heading, ModalFooter, ModalBody, useDisclosure } from '@chakra-ui/react'
import { Button } from './Button'
import { TOCtext } from './TOCtext'

export function AcceptTOS() {
  const { currentAuthenticatedUser, setCurrentAuthenticatedUser } = useAuthContext()

  // Setting a cookie for now but we will need to persist this
  const [isModalOpen, setModalState] = useState(true)
  const handleClick = async () => {
    console.log('%c close', 'color:green')
    const now = Date.now()
    const response = await Auth.updateUserAttributes(currentAuthenticatedUser, {
      'custom:tos_accepted': now.toString(),
    })
    if (response === 'SUCCESS') {
      setCurrentAuthenticatedUser(await Auth.currentAuthenticatedUser())
      setModalState(!isModalOpen)
    } else {
      alert('Failed to accept Terms of Service')
    }
  }

  const { onClose } = useDisclosure()

  const modalBodyStyles = {
    maxHeight: 'calc(70vh - 3.75rem)',
    overflowY: 'scroll',
  }

  return (
    <>
      {!currentAuthenticatedUser?.attributes['custom:tos_accepted'] && (
        <Modal isOpen={isModalOpen} onClose={handleClick} size="2xl" closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <Heading size="lg" px={6} pt={10} pb={4}>
              Terms of Service
            </Heading>
            <ModalBody sx={modalBodyStyles}>
              <TOCtext />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  onClose()
                  handleClick()
                }}
              >
                Accept & Continue
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
