import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { ViewMode } from 'components/ViewModes/types'
import { ViewModeButton } from 'components/ViewModes/ViewModeButton'

type ViewModesProps = BoxProps & {
  modes: ViewMode[]
}

export function ViewModes({ modes, children, ...boxProps }: ViewModesProps) {
  return (
    <Box display="flex" {...boxProps}>
      {modes.map((mode) => (
        <ViewModeButton key={mode} mode={mode} />
      ))}
    </Box>
  )
}
