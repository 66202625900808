import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const PeopleIcon = createIcon({
  displayName: 'PeopleIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1585 6.66602C9.1585 8.04935 8.05016 9.16602 6.66683 9.16602C5.2835 9.16602 4.16683 8.04935 4.16683 6.66602C4.16683 5.28268 5.2835 4.16602 6.66683 4.16602C8.05016 4.16602 9.1585 5.28268 9.1585 6.66602ZM15.8252 6.66602C15.8252 8.04935 14.7168 9.16602 13.3335 9.16602C11.9502 9.16602 10.8335 8.04935 10.8335 6.66602C10.8335 5.28268 11.9502 4.16602 13.3335 4.16602C14.7168 4.16602 15.8252 5.28268 15.8252 6.66602ZM6.66683 10.8327C4.72516 10.8327 0.833496 11.8077 0.833496 13.7493V15.8327H12.5002V13.7493C12.5002 11.8077 8.6085 10.8327 6.66683 10.8327ZM12.5252 10.8743C12.8168 10.8493 13.0918 10.8327 13.3335 10.8327C15.2752 10.8327 19.1668 11.8077 19.1668 13.7493V15.8327H14.1668V13.7493C14.1668 12.516 13.4918 11.5743 12.5252 10.8743Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
})
