import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ThermometerIcon = createIcon({
  displayName: 'ThermometerIcon',
  path: (
    <>
      <path
        d="M7 9H6M7 7H6M7 5H6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.22646 13.0413c-.547.4344-.94558 1.028-1.14065 1.6987-.19508.6707-.17702 1.3854.05168 2.0454.2287.66.65674 1.2326 1.22498 1.6388.56824.4062 1.24861.6259 1.9471.6287.69848.0028 1.38059-.2114 1.95206-.6131.57147-.4016 1.00408-.9708 1.23805-1.629.23397-.6581.25775-1.3727.06805-2.0449-.18971-.6723-.58352-1.269-1.12703-1.7077-.08446-.067-.15275-.1522-.19981-.2493a.718532.718532 0 0 1-.07201-.3112V3.51926c.00001-.4827-.19102-.94578-.53135-1.28809-.34033-.3423-.8023-.536-1.28499-.53879-.49519.00996-.96655.21455-1.31201.56948-.34547.35493-.53725.83164-.53382 1.32693v8.88571c-.00102.1095-.02675.2173-.07527.3155-.04853.0981-.1186.1841-.20498.2513z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        fill="transparent"
      />
    </>
  ),
  viewBox: '0 0 10 21',
})
