import { ICredentials } from '@aws-amplify/core'
import { signUrl } from 'utils/aws/util'

export type PersonAvatarParam = {
  image_url?: string
}

export function personAvatarUrl(person: PersonAvatarParam, credentials?: ICredentials, size = 40) {
  if (!person.image_url) {
    return '/images/avatar.svg'
  }
  return signUrl(person.image_url, credentials)
}
