import { PaginationSearchParams, ListResponse } from './types'
import { API } from 'aws-amplify'
import { getNextCursor } from './utils'

export interface AuthorizationEventResolution {
  name: string
  resolution_id: string
}

export type CreateAuthorizationEventResolutionPayload = Pick<AuthorizationEventResolution, 'name'>

export type UpdateAuthorizationEventResolutionPayload = CreateAuthorizationEventResolutionPayload

export const getAuthorizationEventResolutionsList = async (
  cursor: PaginationSearchParams = {}
): Promise<ListResponse<AuthorizationEventResolution>> => {
  const response = await API.get('authorization-events', '/authorization-events/resolutions', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const deleteAuthorizationEventResolution = async (resolution: AuthorizationEventResolution) => {
  return API.del('authorization-events', `/authorization-events/resolutions/${resolution.resolution_id}`, {})
}

export const createAuthorizationEventResolution = async (payload: CreateAuthorizationEventResolutionPayload) => {
  return API.post('authorization-events', `/authorization-events/resolutions`, {
    body: payload,
  })
}

export const updateAuthorizationEventResolution = async (
  resolution: AuthorizationEventResolution,
  payload: UpdateAuthorizationEventResolutionPayload
) => {
  return API.put('authorization-events', `/authorization-events/resolutions/${resolution.resolution_id}`, {
    body: payload,
  })
}
