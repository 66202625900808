import React, { SVGProps } from 'react'

const colorModes = {
  dark: {
    key: '#fff',
    text: '#fff',
  },
  light: {
    key: '#001432',
    text: '#0C73FF',
  },
}

type Props = SVGProps<any> & {
  colorMode?: 'light' | 'dark'
}

export const Logo = ({ colorMode, ...props }: Props) => {
  const mode = colorMode === 'dark' ? 'dark' : 'light'
  return (
    <svg width="162" height="56" fill="none" viewBox="1 0 162 56" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M51.255 31.51l2.89-3.73 6.8-7.457h7.475l-9.647 10.638 10.233 13.884h-7.648l-6.995-9.93-2.847 2.303v7.633h-6.627V10.715h6.627v15.223l-.348 5.577.087-.005zM83.042 45.284c-3.91 0-6.965-1.088-9.163-3.265-2.198-2.176-3.3-5.26-3.303-9.25 0-4.11 1.018-7.287 3.052-9.531 2.035-2.245 4.846-3.37 8.434-3.377 3.433 0 6.105.988 8.016 2.962 1.912 1.974 2.868 4.701 2.87 8.181v3.246H77.283c.073 1.905.63 3.39 1.673 4.454 1.044 1.065 2.506 1.598 4.39 1.601a18.039 18.039 0 004.15-.461 20.691 20.691 0 004.084-1.47v5.177a15.428 15.428 0 01-3.715 1.305c-1.318.285-2.926.428-4.824.428zm-.934-20.661c-1.406 0-2.507.45-3.303 1.35-.796.899-1.252 2.172-1.369 3.82h9.298c-.028-1.652-.455-2.928-1.282-3.828-.826-.9-1.94-1.347-3.344-1.342zM109.331 45.284c-3.911 0-6.965-1.088-9.163-3.265-2.198-2.176-3.3-5.26-3.303-9.25 0-4.11 1.018-7.287 3.054-9.531 2.035-2.245 4.847-3.37 8.435-3.377 3.432 0 6.105.988 8.017 2.962 1.913 1.974 2.869 4.701 2.868 8.181v3.246h-15.666c.073 1.905.631 3.39 1.674 4.454 1.043 1.065 2.505 1.598 4.388 1.601a18.046 18.046 0 004.151-.461 20.696 20.696 0 004.084-1.47v5.177a15.436 15.436 0 01-3.715 1.305c-1.318.285-2.926.428-4.824.428zm-.934-20.661c-1.406 0-2.507.45-3.303 1.35-.796.899-1.252 2.172-1.369 3.82h9.298c-.028-1.652-.455-2.928-1.282-3.828-.826-.9-1.941-1.347-3.344-1.342zM135.62 45.284c-3.91 0-6.964-1.088-9.163-3.265-2.198-2.176-3.299-5.26-3.302-9.25 0-4.11 1.018-7.287 3.053-9.531 2.036-2.245 4.848-3.37 8.435-3.377 3.433 0 6.105.988 8.018 2.962 1.913 1.974 2.868 4.701 2.867 8.181v3.246h-15.664c.071 1.905.628 3.39 1.672 4.454 1.044 1.065 2.507 1.598 4.389 1.601a18.037 18.037 0 004.15-.461 20.673 20.673 0 004.084-1.47v5.177a15.406 15.406 0 01-3.715 1.305c-1.317.285-2.925.428-4.824.428zm-.933-20.661c-1.405 0-2.506.45-3.303 1.35-.797.899-1.253 2.172-1.369 3.82h9.298c-.03-1.652-.458-2.928-1.283-3.828-.826-.9-1.94-1.347-3.343-1.342z"
          fill={colorModes[mode].key}
        />
        <path
          d="M27.734 0C12.418 0 0 12.534 0 28c0 15.464 12.418 28 27.734 28 1.072 0 2.1-.43 2.858-1.195a4.101 4.101 0 001.184-2.886V4.08c0-1.082-.425-2.12-1.184-2.886A4.023 4.023 0 0027.734 0zM15.888 34.597a6.491 6.491 0 01-3.63-1.112 6.584 6.584 0 01-2.408-2.96 6.657 6.657 0 01-.372-3.813 6.619 6.619 0 011.789-3.378 6.516 6.516 0 013.346-1.806 6.477 6.477 0 013.776.376 6.55 6.55 0 012.933 2.43 6.643 6.643 0 011.102 3.665 6.63 6.63 0 01-1.915 4.666 6.504 6.504 0 01-4.62 1.932z"
          fill={colorModes[mode].text}
        />
        <path
          d="M149.691 9.98h-1.752V3.129h-2.235V1.666h6.218v1.462h-2.237l.006 6.853zM156.682 9.98L154.7 3.459h-.052c.071 1.326.107 2.212.108 2.655v3.868h-1.56V1.666h2.377l1.948 6.36h.034l2.068-6.359H162v8.314h-1.627V6.045c0-.186 0-.4.008-.642s.031-.888.075-1.934h-.05l-2.123 6.512h-1.601z"
          fill={colorModes[mode].key}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h162v56H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
