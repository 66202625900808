import { AuthorizationEventResolution, getAuthorizationEventResolutionsList } from 'libs/api'
import React from 'react'
import { Column, ListPage } from 'modules/Common/ListPage'
import translations from './AuthorizationEventResolutions.i18n.json'
import { IntlShape, useIntl } from 'react-intl'
import { Actions } from './Actions/Actions'
import { buildActionColumns } from '../../helpers/buildActionColumns'
import { AuthorizationEventResolutionEditButton } from './Actions/AuthorizationEventResolutionEditButton/AuthorizationEventResolutionEditButton'
import { AuthorizationEventResolutionDeleteButton } from './Actions/AuthorizationEventResolutionDeleteButton/AuthorizationEventResolutionDeleteButton'
import { useOmniSearchType } from 'components'

export const buildColumns = (intl: IntlShape, withActions = true): Column<AuthorizationEventResolution>[] => [
  {
    title: intl.formatMessage(translations.name),
    field: 'name',
  },
  ...(withActions
    ? buildActionColumns({
        EditButton: AuthorizationEventResolutionEditButton,
        DeleteButton: AuthorizationEventResolutionDeleteButton,
      })
    : []),
]

export function AuthorizationEventResolutions({ search }: { search: string }) {
  useOmniSearchType('authorizationEventResolutions')
  const intl = useIntl()

  return (
    <ListPage
      fetchItems={getAuthorizationEventResolutionsList}
      columns={buildColumns(intl)}
      getId={(item) => item.resolution_id}
      components={{
        Actions,
      }}
      countLabel={translations.authorizationEventResolutionsCount}
      variables={{ search }}
      itemName={intl.formatMessage(translations.authorizationEventResolution)}
      realTimeChannel="authorization_event_resolutions"
    />
  )
}
