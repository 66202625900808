import React, { ReactElement } from 'react'
import { useModal } from 'hooks'
import { Modal as ModalWrapper } from 'components'
import { ModalProps } from '@chakra-ui/react'

interface Props<T, SuccessFunction, InitialFormValues = {}> {
  labels: {
    createTitle: string
    editTitle: string
  }
  children?: ({ open }: { open: () => void }) => ReactElement
  onSuccess: SuccessFunction
  item?: T
  presetValues?: Partial<InitialFormValues>
  size?: ModalProps['size']
  Modal: React.FC<{
    onSuccess: SuccessFunction
    onCancel: () => void
    item?: T
    presetValues?: Partial<InitialFormValues>
  }>
}

export function UpsertModal<T, SuccessFunction extends Function>({
  labels,
  Modal,
  children,
  onSuccess,
  item,
  presetValues,
  size = 'md',
}: Props<T, SuccessFunction>) {
  const [visible, open, hide] = useModal(!children)

  const handleSuccess = async (...args: any) => {
    hide()
    return onSuccess(...args)
  }

  return (
    <>
      {children && children({ open })}

      <ModalWrapper isVisible={visible} onClose={hide} title={item ? labels.editTitle : labels.createTitle} size={size}>
        <Modal
          onSuccess={(handleSuccess as unknown) as SuccessFunction}
          onCancel={hide}
          item={item}
          presetValues={presetValues}
        />
      </ModalWrapper>
    </>
  )
}
