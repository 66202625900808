import { useIntl } from 'react-intl'

export function useTranslations<T extends Record<string, string>>(json: T): T {
  const { formatMessage } = useIntl()
  if (typeof Proxy === 'undefined') {
    const translations: Record<string, string> = {}
    Object.keys(json).forEach((key) => {
      translations[key] = formatMessage(json[key])
    })
    return translations as T
  }
  return new Proxy(json, {
    get: (target, name: string) => formatMessage(target[name]),
  })
}
