import { PaginationSearchParams, ListResponse } from './types'
import { API } from 'aws-amplify'
import { getNextCursor } from './utils'

export interface DeviceType {
  name: string
  color: string
  description?: string | null
  image_url?: string
  thing_type_id: string
  kind: string
}

export type CreateDeviceTypePayload = Pick<DeviceType, 'name' | 'description'>

export type UpdateDeviceTypePayload = CreateDeviceTypePayload

export const getDeviceTypesList = async (cursor: PaginationSearchParams = {}): Promise<ListResponse<DeviceType>> => {
  const response = await API.get('things', '/things/types', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const deleteDeviceType = async (deviceType: DeviceType) => {
  return API.del('things', `/things/types/${deviceType.thing_type_id}`, {})
}

export const createDeviceType = async (payload: CreateDeviceTypePayload) => {
  return API.post('things', `/things/types`, {
    body: payload,
  })
}

export const updateDeviceType = async (deviceType: DeviceType, payload: UpdateDeviceTypePayload) => {
  return API.put('things', `/things/types/${deviceType.thing_type_id}`, {
    body: payload,
  })
}
