import React, { ComponentType } from 'react'
import { ShadeProps, useShadeColors } from './shade'
import { forwardRef } from '@chakra-ui/react'

export function withShade<T>(Component: ComponentType<T>) {
  return forwardRef<T & ShadeProps, 'div'>(({ shade, bgShade, ...props }, ref) => {
    const shadeColors = useShadeColors({ shade, bgShade })
    return <Component ref={ref} {...shadeColors} {...(props as T)} />
  })
}
