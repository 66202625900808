import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const PendingIcon = createIcon({
  displayName: 'PendingIcon',
  path: (
    <>
      <path
        d="M17 19C17 15.6863 13.4183 13 9 13C4.58172 13 1 15.6863 1 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
      <circle cx="8.5" cy="5.5" r="4.5" stroke="currentColor" strokeWidth="2" fill="transparent" />
      <circle cx="16" cy="17.8462" r="7" fill="#E5F0FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9941 12.0128C12.7741 12.0128 10.1666 14.6262 10.1666 17.8462C10.1666 21.0662 12.7741 23.6795 15.9941 23.6795C19.22 23.6795 21.8333 21.0662 21.8333 17.8462C21.8333 14.6262 19.22 12.0128 15.9941 12.0128ZM16 22.5128C13.4216 22.5128 11.3333 20.4245 11.3333 17.8462C11.3333 15.2678 13.4216 13.1795 16 13.1795C18.5783 13.1795 20.6666 15.2678 20.6666 17.8462C20.6666 20.4245 18.5783 22.5128 16 22.5128ZM15.4166 14.9295H16.2916V17.992L18.9166 19.5495L18.4791 20.267L15.4166 18.4295V14.9295Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 23 25',
})
