import React, { ComponentProps, ReactElement } from 'react'
import { ActionButton } from 'components'
import { useTranslations } from 'hooks'

type ChildrenProps = {
  children: ({ open }: { open: () => void }) => ReactElement
}

type BuildProps<T> = {
  UpsertModal: (props: T) => JSX.Element
  translations: {
    createButton: string
  }
}

export function createCreateButton<T extends ChildrenProps>({ translations, UpsertModal }: BuildProps<T>) {
  type ModalProps = ComponentProps<typeof UpsertModal>
  return (modalProps: Omit<ModalProps, 'children'>) => {
    const t = useTranslations(translations)

    return (
      <UpsertModal {...(modalProps as ModalProps)}>
        {({ open }) => <ActionButton onClick={open}>{t.createButton}</ActionButton>}
      </UpsertModal>
    )
  }
}
