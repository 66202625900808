import { createIcon } from '@chakra-ui/icon'

export const AccessEventIcon = createIcon({
  displayName: 'AccessEventIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.875 10.8332H48.75V5.4165H43.3333V10.8332H21.6667V5.4165H16.25V10.8332H8.125V59.5832H56.875V10.8332ZM44.7687 32.6623L41.8979 29.7915L28.6812 43.0082L22.9396 37.2665L20.0688 40.1373L28.6812 48.7498L44.7687 32.6623ZM13.5417 54.1665H51.4583V24.3748H13.5417V54.1665Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 65 65',
})
