import { PaginationSearchParams, ListResponse } from './types'
import { API } from 'aws-amplify'
import { memoize } from 'lodash-es'
import { getNextCursor } from './utils'

export interface Label {
  name: string
  color: string
  description?: string | null
  time_limit: boolean
  count: number
  category_id: string
}

export type CreateLabelPayload = Pick<Label, 'name' | 'description' | 'color' | 'time_limit'>

export type UpdateLabelPayload = CreateLabelPayload

export const getLabelsList = async (cursor: PaginationSearchParams = {}): Promise<ListResponse<Label>> => {
  const response = await API.get('categories', '/categories', {
    queryStringParameters: {
      ...cursor,
      responseFormat: 'list',
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const deleteLabel = async (label: Label) => {
  return API.del('categories', `/categories/${label.category_id}`, {})
}

export const createLabel = async (payload: CreateLabelPayload) => {
  return API.post('categories', `/categories`, {
    body: payload,
  })
}

export const updateLabel = async (label: Label, payload: UpdateLabelPayload) => {
  return API.put('categories', `/categories/${label.category_id}`, {
    body: payload,
  })
}

export function bulkAddLabels(body: CreateLabelPayload[]) {
  return API.post('categories', `/categories/bulk`, {
    body,
  })
}

export const getMemoizedLabels = memoize(async () => {
  return {}
  // const { items } = await getLabelsList()

  // return Object.fromEntries(items.map((item) => [item.category_id, item]))
})
