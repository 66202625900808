import React from 'react'
import { Box, BoxProps, Icon } from '@chakra-ui/react'
import { Flex } from 'components'
import { ShadeProps } from 'components/core/utils/shade'
import { CloseIcon, MenuIcon } from 'components/icons'
import { LogoMenu } from './LogoMenu'

export const SidebarNavContext = React.createContext<{ isCollapsed: boolean }>({ isCollapsed: false })

export type SidebarNavProps = {
  isCollapsed: boolean
  setIsCollapsed: (isCollapsed: boolean) => void
  collapsedWidth: string
  expandedWidth: string
} & BoxProps

export function SidebarNav({
  isCollapsed,
  setIsCollapsed,
  collapsedWidth,
  expandedWidth,
  children,
  ...other
}: SidebarNavProps) {
  const styles: BoxProps & ShadeProps = {
    width: isCollapsed ? collapsedWidth : expandedWidth,
    height: '100%',
    position: 'fixed',
    top: 0,
    py: 6,
    bgShade: 4,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.32), 0px 0px 1px rgba(0, 0, 0, 0.04);',
    zIndex: 1,
  }

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <SidebarNavContext.Provider value={{ isCollapsed }}>
      <Flex direction="column" {...styles} {...other}>
        <Icon
          as={isCollapsed ? MenuIcon : CloseIcon}
          boxSize="20px"
          mb={6}
          ml={6}
          cursor="pointer"
          onClick={toggleCollapse}
        />
        <Box mb={4} mx={2}>
          <LogoMenu isCollapsed={isCollapsed} />
        </Box>
        <Flex flex={1} pl={6} direction="column">
          {children}
        </Flex>
      </Flex>
    </SidebarNavContext.Provider>
  )
}
