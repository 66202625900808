import { DarkMode, LightMode, useColorMode } from '@chakra-ui/react'
import { MarkerFeature, MarkerFeatureWithReactPopup } from 'components/MapboxGLMap/types'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Box } from 'components'
import { AppProvider } from 'AppProvider'
import { AuthCredentialsContext } from 'components/AuthCredentialsProvider'
import { useAuthCredentials } from './useAuthCredentials'

/**
 * This hook is used because popup must accept HTML string, and not React component
 * Previous solution was to simply use `ReactDOMServer.renderToString` but recently it started throwing errors about useLayoutEffect
 * So the solution is to use regular `ReactDOM.render` but it is async, so it's not completely straightforward
 */

export function useMarkersWithPopup(initialValue: MarkerFeatureWithReactPopup[] = []) {
  const [reactMarkers, setReactMarkers] = useState(initialValue)
  const [htmlMarkers, setHtmlMarkers] = useState<MarkerFeature[]>([])
  const credentials = useAuthCredentials()
  const { colorMode } = useColorMode()
  // const colorMode = 'light' // force light mode always

  useEffect(() => {
    async function runEffect() {
      const el = document.createElement('div')

      await new Promise<void>((resolve) => {
        ReactDOM.render(
          <div>
            <AppProvider noGlobalStyle>
              <AuthCredentialsContext.Provider value={credentials}>
                <Box as={colorMode === 'light' ? LightMode : DarkMode}>
                  {reactMarkers.map((marker, index) => (
                    <div key={index}>
                      <Box bgShade={4}>{marker.properties.popup}</Box>
                    </div>
                  ))}
                </Box>
              </AuthCredentialsContext.Provider>
            </AppProvider>
          </div>,
          el,
          resolve
        )
      })

      const childNodes = el.firstElementChild?.children

      if (!childNodes) {
        return
      }

      setHtmlMarkers(
        reactMarkers.map((marker, index) => ({
          ...marker,
          properties: {
            ...marker.properties,
            popup: childNodes[index].innerHTML,
          },
        }))
      )
    }

    runEffect()
  }, [colorMode, reactMarkers, credentials])

  return [htmlMarkers, setReactMarkers] as const
}
