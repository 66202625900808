import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ImageIcon = createIcon({
  displayName: 'ImageIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0h24v24H0V0zm10.667 18.013L7.333 14l-4.666 6h18.666l-6-8-4.666 6.013z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
