import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const PersonIcon = createIcon({
  displayName: 'PersonIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4zM4 18c0-2.66 5.33-4 8-4s8 1.34 8 4v2H4v-2z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
