import ErrorState from 'components/State/ErrorState'
import { useQuery } from 'hooks'
import { configureAmplifyAuth, fetchCognitoConfig } from 'libs/api'
import React, { useEffect } from 'react'
import { Flex } from 'components'
import { Spinner } from '@chakra-ui/react'
import { useConnectionStatus } from '../../hooks/useConnectionStatus'

export const SubdomainConfigLoader: React.FC = ({ children }) => {
  const { data: cognitoConfig, isLoading, error, refetch } = useQuery(fetchCognitoConfig)

  const online = useConnectionStatus()

  useEffect(() => {
    if (cognitoConfig) {
      configureAmplifyAuth(cognitoConfig)
    } else if (!isLoading && !error) {
      // no config for this subdomain
      window.location.href = 'https://keee.app'
    }
  }, [cognitoConfig, isLoading, error])

  if (error) return <ErrorState retry={refetch} width="100vw" height="100vh" />
  if (isLoading && online) {
    return (
      <Flex align="center" justify="center" width="100vw" height="100vh">
        <Spinner size="xl" />
      </Flex>
    )
  }
  return <>{children}</>
}
