import React from 'react'
import { ConfirmModalOptions, useConfirmModal } from 'hooks'
import { ButtonLink } from 'components'

type Props = {
  onConfirm: ConfirmModalOptions['onConfirm']
  labels: Omit<ConfirmModalOptions, 'onConfirm'> & {
    deleted: string
    delete: string
  }
}

export function DeleteButton({ labels, onConfirm }: Props) {
  const confirm = useConfirmModal()

  const onDelete = async (event: React.SyntheticEvent) => {
    event.stopPropagation()
    await confirm({
      ...labels,
      onConfirm,
    })
  }

  return (
    <ButtonLink onClick={onDelete} colorScheme="red">
      {labels.delete}
    </ButtonLink>
  )
}
