import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import { AppProvider, history } from 'AppProvider'
import { CognitoConfigLoader } from 'components/CognitoConfigLoader'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: 'keee-web@' + process.env.VERCEL_GITHUB_COMMIT_SHA,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.2,
})

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<div>An error has occured</div>}>
    <AppProvider>
      <CognitoConfigLoader>
        <App />
      </CognitoConfigLoader>
    </AppProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
)
