import React, { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'
import { useModal, useTranslations } from 'hooks'
import { ActionButton, ButtonLink, ButtonOutlined, FilterIcon, Box, Modal } from 'components'
import styled from '@emotion/styled'
import translations from './FilterModal.i18n.json'

interface Props {
  onSubmit: () => void
  onCancel: () => void
  onReset: () => void
  children: ReactNode
  isActive?: boolean
}

const Line = styled.hr``

export function FilterModal({ onSubmit, onCancel, onReset, children, isActive }: Props) {
  const [visible, open, hide] = useModal(false)
  const t = useTranslations(translations)

  const handleSubmit = () => [onSubmit(), hide()]
  const handleCancel = () => [onCancel(), hide()]

  return (
    <>
      <FilterIcon onClick={open} color={isActive ? 'primary.main' : undefined} />

      <Modal
        onClose={hide}
        isVisible={visible}
        footer={
          <Box>
            <ButtonOutlined onClick={handleCancel} mr={4}>
              {t.cancel}
            </ButtonOutlined>
            <ActionButton onClick={handleSubmit}>{t.apply}</ActionButton>
          </Box>
        }
      >
        <Flex mb={8} alignItems="center" justifyContent="space-between">
          <Box fontWeight="bold" mb={0} shade={0}>
            {t.filters}
          </Box>
          <ButtonLink onClick={onReset}>{t.reset}</ButtonLink>
        </Flex>
        <Line />
        <Box my={4}>{children}</Box>
        <Line />
      </Modal>
    </>
  )
}
