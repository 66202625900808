import { getRolesList, getUsersList, mapRoleNames, Role, User } from 'libs/api'
import React from 'react'
import { Column, ListPage } from 'modules/Common/ListPage'
import translations from './Users.i18n.json'
import { IntlShape, useIntl } from 'react-intl'
import { Actions } from './Actions/Actions'
import { UserEditButton } from './Actions/UserEditButton/UserEditButton'
import { UserDeleteButton } from './Actions/UserDeleteButton/UserDeleteButton'
import { buildActionColumns } from '../../helpers/buildActionColumns'
import { UsersFilter } from './components/UsersFilter/UsersFilter'
import * as routes from 'routes'
import { useOmniSearchType, UserAvatar } from 'components'
import { getUsername } from './helpers/getUsername'
import { EnabledChip } from './components/EnabledChip'
import { useQuery } from 'hooks'

export const buildColumns = (intl: IntlShape, roles: Role[], withActions = true): Column<User>[] => [
  {
    key: 'avatar',
    width: 12,
    render: (item) => <UserAvatar name={item.fullName} borderRadius={2} />,
  },
  {
    title: intl.formatMessage(translations.name),
    field: 'fullName',
    sortKey: 'fullName',
  },
  {
    title: intl.formatMessage(translations.enabled),
    render: (item) => <EnabledChip user={item} />,
    textAlign: 'center',
  },
  {
    title: intl.formatMessage(translations.username),
    field: 'userName',
  },
  // {
  //   title: intl.formatMessage(translations.email),
  //   field: 'email',
  // },
  {
    title: intl.formatMessage(translations.permission),
    render: (item) => mapRoleNames(item.roles, roles).join(', '),
  },
  ...(withActions
    ? buildActionColumns({
        EditButton: UserEditButton,
        DeleteButton: UserDeleteButton,
      })
    : []),
]

export function Users({ search }: { search: string }) {
  useOmniSearchType('users')
  const intl = useIntl()
  const { data } = useQuery(getRolesList, {}, { staleTime: Infinity })
  const roles = data?.items ?? []

  return (
    <ListPage
      fetchItems={getUsersList}
      columns={buildColumns(intl, roles)}
      getId={(item) => item.userName}
      getName={(item) => getUsername(item)}
      getRoute={(item) => routes.userDetailWithUsername(getUsername(item))}
      components={{
        Actions,
        Filters: UsersFilter,
      }}
      countLabel={translations.usersCount}
      variables={{ search }}
      itemName={intl.formatMessage(translations.user)}
      realTimeChannel="users"
    />
  )
}
