import { extendTheme, Theme, ThemeOverride } from '@chakra-ui/react'
import { styles } from './styles'
import { colors } from './colors'
import { fontSizes } from './fontSizes'
import { components } from './components'
import { shadows } from './shadows'
import { fonts } from './fonts'

type CustomTheme = DeepMergeTwoTypes<
  Theme,
  {
    colors: typeof colors
    fonts: typeof fonts
    fontSizes: typeof fontSizes
    shadows: typeof shadows
  }
>

type CustomThemeOverride = Omit<ThemeOverride, 'colors' | 'fonts' | 'fontSizes' | 'shadows'> & {
  colors: any
  fonts: any
  fontSizes: any
  shadows: any
}

const theme: CustomTheme = extendTheme<CustomThemeOverride>({
  config: { useSystemColorMode: false },
  styles,
  fonts,
  shadows,
  colors,
  fontSizes,
  components,
})

export default theme
