import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const FiltersIcon = createIcon({
  displayName: 'FiltersIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6V8H21V6H3ZM10 18H14V16H10V18ZM18 13H6V11H18V13Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
