import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ThermostatIcon = createIcon({
  displayName: 'ThermostatIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C6.66 0 8 1.34 8 3V11C9.21 11.91 10 13.37 10 15C10 17.76 7.76 20 5 20C2.24 20 0 17.76 0 15C0 13.37 0.79 11.91 2 11V3C2 1.34 3.34 0 5 0ZM5 2C4.45 2 4 2.45 4 3V9H6V7H5V6H6V4H5V3H6C6 2.45 5.55 2 5 2Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 -2 10 24',
})
