import React from 'react'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { withShade } from './utils'

type Props = LinkProps & ReactRouterLinkProps

export const Link = withShade<Props>((props) => {
  return <ChakraLink fontWeight="bold" fontSize="xs" as={ReactRouterLink as any} {...props} />
})

export const SubtleLink = (props: Props) => (
  <ChakraLink as={ReactRouterLink as any} _hover={{ textDecoration: 'none' }} {...props} />
)
