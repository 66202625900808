import { SelectField, SelectFieldProps } from 'components'
import { useQuery } from 'hooks'
import { getZones, Zone } from 'libs/api'
import { useMemo } from 'react'

type Props = Omit<SelectFieldProps, 'options' | 'isLoading'> & {
  currentZones?: Pick<Zone, 'zone_id' | 'name'>[]
  parentZoneId?: string | null
}

// parentZoneId null loads Root zones aka "Locations"
export function ZonePicker({ currentZones, parentZoneId = null, ...props }: Props) {
  const { data, isLoading } = useQuery(
    getZones,
    {
      parent_zone_id: parentZoneId,
    },
    { cacheTime: Infinity, staleTime: 5000 }
  )

  const options = useMemo(() => {
    const notAllowedZones = (currentZones ?? []).filter(
      (item) => !data?.find((otherItem) => otherItem.zone_id === item.zone_id)
    )
    return notAllowedZones.concat(data ?? []).map((item) => ({
      label: item.name,
      value: item.zone_id,
      isFixed: notAllowedZones.includes(item),
    }))
  }, [data, currentZones])

  return (
    <SelectField
      options={options}
      isLoading={isLoading}
      SelectProps={{
        isClearable: options.some((option) => !option.isFixed),
      }}
      {...props}
    />
  )
}
