import React, { ComponentType, useContext } from 'react'
import { Link as RouterLink, matchPath, NavLinkProps, useLocation } from 'react-router-dom'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { SidebarNavContext } from './SidebarNav'
import { omit } from 'lodash-es'
import { useTextColorFromShade } from 'components/core/utils/shade'

type Props = Omit<NavLinkProps, 'to'> & {
  icon: ComponentType
  to?: string
}

export function SidebarNavLink({ icon, children, ...linkProps }: Props) {
  const location = useLocation()
  const isActive = !!matchPath(location.pathname, {
    path: linkProps.to as string,
    exact: linkProps.exact,
  })
  const { isCollapsed } = useContext(SidebarNavContext)

  const borderStyle = {
    borderRight: '4px solid',
    borderRightColor: isActive ? 'primary.main' : 'transparent',
  }

  const Component: ComponentType<any> = linkProps.to ? RouterLink : Box
  const componentProps = omit(linkProps, 'exact')

  const color = useTextColorFromShade(2)

  return (
    <Component {...componentProps}>
      <Box py={2} my={1}>
        <Flex color={isActive ? 'primary.main' : color} align="center" {...borderStyle} height={6} cursor="pointer">
          <Icon as={icon} boxSize="20px" mr={2} color={isActive ? 'inherit' : 'dark.4'} />
          <Text fontFamily="label" fontSize="sm" fontWeight="bold">
            {!isCollapsed && children}
          </Text>
        </Flex>
      </Box>
    </Component>
  )
}
