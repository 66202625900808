import React, { Dispatch, SetStateAction, useState } from 'react'
import translations from './UsersFilter.i18n.json'
import { useTranslations } from 'hooks'
import { Radio, Box } from 'components'
import { Collapse } from 'modules/Admin/components'
import { UsersFilters, UserStatus } from 'libs/api'
import { FilterModal } from 'modules/Common/ListPage/components/FilterModal'
import { RadioGroup } from '@chakra-ui/react'
import { isEmpty, pickBy } from 'lodash-es'

interface Props {
  defaultFilters: UsersFilters
  applyFilters: Dispatch<SetStateAction<UsersFilters>>
}

export const UsersFilter: React.FC<Props> = ({ defaultFilters, applyFilters }) => {
  const [filters, setFilters] = useState(defaultFilters)
  const t = useTranslations(translations)

  const activeFilters = pickBy(defaultFilters)
  return (
    <Box mr={4}>
      <FilterModal
        isActive={!isEmpty(activeFilters)}
        onSubmit={() => applyFilters(filters)}
        onCancel={() => setFilters(defaultFilters)}
        onReset={() => setFilters({})}
      >
        <Collapse defaultOpened title={t.status}>
          <RadioGroup
            fontSize="12px"
            fontWeight="bold"
            value={filters.status ?? UserStatus.All}
            onChange={(value) => setFilters({ status: value as UserStatus })}
          >
            <Radio value={UserStatus.All}>{t.all}</Radio>
            <Radio value={UserStatus.Active}>{t.active}</Radio>
            <Radio value={UserStatus.Inactive}>{t.inactive}</Radio>
          </RadioGroup>
        </Collapse>
      </FilterModal>
    </Box>
  )
}
