import React, { useEffect } from 'react'
import { Flex } from '@chakra-ui/react'
import { useLogout } from 'hooks'
import { MainNavigation } from './MainNavigation'
import styled from '@emotion/styled'
import { useNavigationCollapse } from './hooks/useNavigationCollapse'
import { useIntercomWithLocation } from './hooks/useIntercomWithLocation'
import { HeaderBox } from './HeaderBox'
import { Box } from 'components'

const COLLAPSED_NAV_WIDTH = '64px'
const EXPANDED_NAV_WIDTH = '200px'
const TRANSITION = '0.15s ease-out'

type Props = {
  children: React.ReactNode
  withoutHeader?: boolean
}

const ContentBox = styled(Flex)({
  flexDirection: 'column',
  minHeight: '100vh',
})

export const MainLayout = ({ children, withoutHeader }: Props) => {
  useIntercomWithLocation()
  const onLogout = useLogout()
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useNavigationCollapse()

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => {
      if (e.matches) {
        setIsNavigationCollapsed(true)
      }
    }

    const media = window.matchMedia('(max-width: 1024px)')
    if (media.addEventListener) {
      media.addEventListener('change', handleChange)
      return () => media.removeEventListener('change', handleChange)
    } else {
      media.addListener(handleChange)
      return () => media.removeListener(handleChange)
    }
  })

  return (
    <Box bgShade={3} data-test-id="MainLayout">
      <MainNavigation
        isCollapsed={isNavigationCollapsed}
        setIsCollapsed={setIsNavigationCollapsed}
        collapsedWidth={COLLAPSED_NAV_WIDTH}
        expandedWidth={EXPANDED_NAV_WIDTH}
        transition={TRANSITION}
        onLogout={onLogout}
      />
      <ContentBox
        pl={8}
        pr={8}
        ml={isNavigationCollapsed ? COLLAPSED_NAV_WIDTH : EXPANDED_NAV_WIDTH}
        transition={TRANSITION}
      >
        <Box as={withoutHeader ? Box : HeaderBox}>{children}</Box>
      </ContentBox>
    </Box>
  )
}
