import React, { useMemo } from 'react'
import { useNotify, useQuery } from 'hooks'
import { bulkAddDevices, getDeviceTypesList } from 'libs/api'
import { BulkImportButton } from 'components'
import { getDevicesSchema, DevicesSchemaKey, FlatfileResults } from 'libs/flatfile'
import translations from './DeviceImportButton.i18n.json'
import { useIntl } from 'react-intl'
import { sample } from 'lodash-es'
import { palette } from 'utils/palette'

type Props = {
  refetch: () => Promise<unknown>
}

export const DeviceImportButton: React.FC<Props> = ({ refetch }) => {
  const intl = useIntl()
  const notify = useNotify()
  const { data: deviceTypes } = useQuery(getDeviceTypesList)
  const devicesSchema = useMemo(() => {
    if (deviceTypes) {
      return getDevicesSchema(deviceTypes.items)
    }
  }, [deviceTypes])

  async function handleImport(results: FlatfileResults<DevicesSchemaKey>) {
    try {
      const items = results.data.map((item) => ({
        ...item,
        color: item.color || sample(palette)!.toString(),
      }))

      const response = await bulkAddDevices(items)
      notify.success(intl.formatMessage(translations.imported, { count: response.length }))
      await refetch()
    } catch (e) {
      console.log(e) // replace with logger
      notify.error()
    }
  }

  return <BulkImportButton fields={devicesSchema} onImport={handleImport} />
}
