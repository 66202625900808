import React from 'react'
import styled from '@emotion/styled'
import { Button, Menu, MenuButton, MenuItem, MenuList, Spinner } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useTenants } from '../TenantProvider'

const TenantSelectorButton = styled(Button)`
  width: 100%;
  text-align: left;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  border-radius: 0;
`

export const TenantSelector = () => {
  const { current: currentTenant, allTenants: tenants, isLoading } = useTenants()

  const onTenantSelected = (domain: string) => {
    window.open(window.location.href.replace(`://${currentTenant?.domain}.`, `://${domain}.`))
  }

  return (
    <Menu>
      <MenuButton
        as={TenantSelectorButton}
        rightIcon={!isLoading ? <ChevronDownIcon color="dark.2" boxSize="20px" /> : <Spinner size="xs" />}
        bgColor="transparent"
        color="primary.main"
        fontSize="sm"
        disabled={isLoading}
      >
        {currentTenant?.company ?? ''}
      </MenuButton>
      {!isLoading && (
        <MenuList>
          {tenants?.map((tenant) => (
            <MenuItem
              key={tenant.domain}
              fontSize="sm"
              fontWeight="bold"
              onClick={() => onTenantSelected(tenant.domain)}
            >
              {tenant.company}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Menu>
  )
}
