import React, { useEffect, useRef } from 'react'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration'
import { useToast } from '@chakra-ui/react'
import { Button, Text, VStack } from 'components'
import { useTranslations } from '../useTranslations'
import translations from './useSWUpdate.i18n.json'

export const useSWUpdate = () => {
  const toast = useToast()
  const t = useTranslations(translations)
  const currentToast = useRef<string | number | undefined>()
  const waitingWorkerRef = useRef<ServiceWorker | null>(null)

  useEffect(() => {
    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
      currentToast.current = toast({
        status: 'info',
        render() {
          return (
            <VStack>
              <Text>{t.versionAvailable}</Text>
              <Button onClick={reloadPage}>{t.update}</Button>
            </VStack>
          )
        },
        isClosable: false,
      })
      waitingWorkerRef.current = registration.waiting
    }

    const reloadPage = () => {
      if (currentToast.current) {
        toast.close(currentToast.current)
      }

      waitingWorkerRef.current?.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload(true)
    }

    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
