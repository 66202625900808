import { v4 as uuidv4 } from 'uuid'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import { ICredentials } from '@aws-amplify/core'
import { memoize } from 'lodash-es'

var crypto = require('crypto-js')
var aws4 = require('./aws4')

export function setSigV4Credentials(credentials: ICredentials) {
  localStorage.setItem('accessKeyId', credentials.accessKeyId)
  localStorage.setItem('secretAccessKey', credentials.secretAccessKey)
  localStorage.setItem('sessionToken', credentials.sessionToken)
  memoizedSign.cache.clear!()
}

const memoizedSign = memoize(
  (url, credentials) => {
    const request = {
      host: 's3.us-east-2.amazonaws.com',
      path: `/redlyst/${url}`,
      service: 's3',
      signQuery: true,
    }
    const protocol = 'https://'
    const signedRequest = aws4.sign(request, credentials)
    return protocol + signedRequest.host + signedRequest.path
  },
  (url, credentials: ICredentials) => url + '#' + credentials.accessKeyId
)

export function signUrl(url: string | undefined, credentials: ICredentials | undefined) {
  if (!url || !credentials) return
  return memoizedSign(url, credentials)
}

export function getFileUploadFormInputs(file: File) {
  const region = 'us-east-2'
  const dateNow = new Date()
  const nowIsoString = dateNow.toISOString()
  const x_amz_date = nowIsoString.replace(/-/g, '').replace(/:/g, '').split('.')[0] + 'Z'
  const yyyyddmmString = x_amz_date.split('T')[0]

  const meta_uuid = uuidv4()

  const upload_policy = getUploadPolicy(
    dateNow,
    region,
    'redlyst',
    `${localStorage.getItem('tenantId')}/images/`,
    meta_uuid,
    file.type
  )
  const base64Policy = btoa(upload_policy)

  const secretAccessKey = localStorage.getItem('secretAccessKey')!
  const signingKey = getSignatureKey(secretAccessKey, yyyyddmmString, region, 's3')
  const signature = crypto.HmacSHA256(base64Policy, signingKey).toString()

  var fields = []

  var key = document.createElement('input')
  key.type = 'hidden'
  key.name = 'key'
  key.value = `${localStorage.getItem('tenantId')}/images/\${filename}`
  fields.push(key)

  var acl = document.createElement('input')
  acl.type = 'hidden'
  acl.name = 'acl'
  acl.value = 'private'
  fields.push(acl)

  var amz_meta_uuid = document.createElement('input')
  amz_meta_uuid.type = 'hidden'
  amz_meta_uuid.name = 'x-amz-meta-uuid'
  amz_meta_uuid.value = meta_uuid
  fields.push(amz_meta_uuid)

  var content_type = document.createElement('input')
  content_type.type = 'hidden'
  content_type.name = 'Content-Type'
  content_type.value = file.type
  fields.push(content_type)

  var amz_credential = document.createElement('input')
  amz_credential.type = 'hidden'
  amz_credential.name = 'X-Amz-Credential'
  amz_credential.value = `${localStorage.getItem('accessKeyId')}/${yyyyddmmString}/${region}/s3/aws4_request`
  fields.push(amz_credential)

  var amz_security_token = document.createElement('input')
  amz_security_token.type = 'hidden'
  amz_security_token.name = 'X-Amz-Security-Token'
  amz_security_token.value = localStorage.getItem('sessionToken')!
  fields.push(amz_security_token)

  var amz_algorithm = document.createElement('input')
  amz_algorithm.type = 'hidden'
  amz_algorithm.name = 'X-Amz-Algorithm'
  amz_algorithm.value = 'AWS4-HMAC-SHA256'
  fields.push(amz_algorithm)

  var amz_date = document.createElement('input')
  amz_date.type = 'hidden'
  amz_date.name = 'X-Amz-Date'
  amz_date.value = x_amz_date
  fields.push(amz_date)

  var policy = document.createElement('input')
  policy.type = 'hidden'
  policy.name = 'Policy'
  policy.value = base64Policy
  fields.push(policy)

  var amz_signature = document.createElement('input')
  amz_signature.type = 'hidden'
  amz_signature.name = 'X-Amz-Signature'
  amz_signature.value = signature
  fields.push(amz_signature)

  return fields
}

export async function uploadFile(
  file: File,
  onUploadProgress: AxiosRequestConfig['onUploadProgress'],
  collectCancelSource?: (source: CancelTokenSource) => void
) {
  const data = new FormData()
  const inputs = getFileUploadFormInputs(file)
  for (const input of inputs) {
    data.set(input.name, input.value)
  }
  const ext = file.name.replace(/.*(\.\w{3,4})/, '$1')
  const meta_uuid = inputs.find((input) => input.name === 'x-amz-meta-uuid')!.value
  const basename = meta_uuid + ext
  data.set('file', file, basename)

  let cancelToken
  if (collectCancelSource) {
    const source = axios.CancelToken.source()
    collectCancelSource(source)
    cancelToken = source.token
  }
  return axios
    .post('https://redlyst.s3.amazonaws.com/', data, {
      onUploadProgress,
      cancelToken,
    })
    .then(() => {
      return `${localStorage.getItem('tenantId')}/images/${basename}`
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.info('Axios request canceled')
        return ''
      } else {
        throw thrown
      }
    })
}

function getSignatureKey(key: string, dateStamp: string, regionName: string, serviceName: string) {
  var kDate = crypto.HmacSHA256(dateStamp, 'AWS4' + key)
  var kRegion = crypto.HmacSHA256(regionName, kDate)
  var kService = crypto.HmacSHA256(serviceName, kRegion)
  const kSigning = crypto.HmacSHA256('aws4_request', kService)
  return kSigning
}

function getUploadPolicy(
  date: Date,
  region: string,
  bucket: string,
  starts_with_key: string,
  meta_uuid: string,
  content_type: string
) {
  const msNow = date.getTime()
  const msExpiration = msNow + 86400000
  const dateExpiration = new Date(msExpiration)

  const nowIsoString = date.toISOString()
  const expirationIsoString = dateExpiration.toISOString()

  const x_amz_date = nowIsoString.replace(/-/g, '').replace(/:/g, '').split('.')[0] + 'Z'
  const yyyyddmmString = x_amz_date.split('T')[0]

  const access_key_id = localStorage.getItem('accessKeyId')
  const security_token = localStorage.getItem('sessionToken')

  return `{ "expiration": "${expirationIsoString}",\r\n  "conditions": [\r\n    {"bucket": "${bucket}"},\r\n    ["starts-with", "$key", "${starts_with_key}"],\r\n    {"acl": "private"},\r\n    {"x-amz-meta-uuid": "${meta_uuid}"},\r\n    {"Content-Type": "${content_type}"},\r\n    {"x-amz-credential": "${access_key_id}/${yyyyddmmString}/${region}/s3/aws4_request"},\r\n    {"x-amz-security-token": "${security_token}"},\r\n    {"x-amz-algorithm": "AWS4-HMAC-SHA256"},\r\n    {"x-amz-date": "${x_amz_date}" }\r\n  ]\r\n}`
}
