import { format, differenceInMilliseconds, startOfTomorrow } from 'date-fns'
import { useEffect, useState } from 'react'

export const useCurrentDate = ({ withTime = false } = {}) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    const millisecondsBeforeTomorrow = differenceInMilliseconds(startOfTomorrow(), currentDate)
    const updateInterval = withTime ? 1000 : millisecondsBeforeTomorrow + 3000 // 3000 is for safety (to run after the midnight)

    const timerId = setTimeout(() => {
      setCurrentDate(new Date())
    }, updateInterval)

    return () => window.clearTimeout(timerId)
  }, [currentDate, withTime])

  return format(currentDate, `${withTime ? 'H:mm:ss ' : ''}iiii, LLL d yyyy`)
}
