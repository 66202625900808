import React, { useMemo } from 'react'
import { useToast, UseToastOptions } from '@chakra-ui/react'
import { ToastWithActions, ToastAction } from 'components/ToastWithActions'

const defaultOptions: UseToastOptions = {
  duration: 3000,
  isClosable: true,
}

export function useNotify() {
  const toast = useToast()
  return useMemo(() => {
    const success = (title: string, description?: string) => {
      return toast({ ...defaultOptions, title, description, status: 'success' })
    }

    const error = (error?: string | Error, _description?: string) => {
      const title = typeof error === 'string' ? error : 'An error occurred'
      const description = _description || (typeof error === 'string' ? undefined : error?.message?.toString?.())
      return toast({ ...defaultOptions, title, description, status: 'error' })
    }

    const notifyWithActions = (options: UseToastOptions, actions: ToastAction[]) =>
      toast({
        ...defaultOptions,
        ...options,
        render: (renderProps) => (
          <ToastWithActions {...defaultOptions} {...options} {...renderProps} actions={actions} />
        ),
      })

    return { success, error, notifyWithActions, close: toast.close }
  }, [toast])
}
