export const fontSizes = {
  '2xs': '10px',
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  '2xl': '28px',
  '3xl': '32px',
  '4xl': '40px',
  '5xl': '56px',
  '6xl': '64px',
}
