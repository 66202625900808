import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const GridViewIcon = createIcon({
  displayName: 'GridViewIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 9H2.5V5H6.5V9ZM6.5 14H2.5V10H6.5V14ZM7.5 14H11.5V10H7.5V14ZM16.5 14H12.5V10H16.5V14ZM7.5 9H11.5V5H7.5V9ZM12.5 9V5H16.5V9H12.5ZM17.5 14H21.5V10H17.5V14ZM6.5 19H2.5V15H6.5V19ZM7.5 19H11.5V15H7.5V19ZM16.5 19H12.5V15H16.5V19ZM17.5 19H21.5V15H17.5V19ZM17.5 9V5H21.5V9H17.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
