import { PaginationSearchParams, ListResponse } from './types'
import { API } from 'aws-amplify'
import { getNextCursor } from './utils'

export interface EventType {
  name: string
  color: string
  description?: string | null
  event_type_id: string
  image_url?: string
}

export type CreateEventTypePayload = Pick<EventType, 'name' | 'description'>

export type UpdateEventTypePayload = CreateEventTypePayload

export const getEventTypesList = async (cursor: PaginationSearchParams = {}): Promise<ListResponse<EventType>> => {
  const response = await API.get('events', '/events/types', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const deleteEventType = async (eventType: EventType) => {
  return API.del('events', `/events/types/${eventType.event_type_id}`, {})
}

export const createEventType = async (payload: CreateEventTypePayload) => {
  return API.post('events', `/events/types`, {
    body: payload,
  })
}

export const updateEventType = async (eventType: EventType, payload: UpdateEventTypePayload) => {
  return API.put('events', `/events/types/${eventType.event_type_id}`, {
    body: payload,
  })
}
