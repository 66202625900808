import React, { createContext, useContext, useMemo } from 'react'
import { fetchListTenants, Tenant } from '../../libs/api'
import { useAuthContext, useQuery } from '../../hooks'
import { hasKeeeAdminAccess } from '../../utils/access'

export interface TenantContextValue {
  current: Tenant | null
  allTenants: Tenant[]
  isLoading: boolean
}

const initialState: TenantContextValue = {
  current: null,
  allTenants: [],
  isLoading: false,
}

const TenantContext = createContext(initialState)

export const TenantProvider: React.FC = (props) => {
  const { currentRole } = useAuthContext()
  const enabled = hasKeeeAdminAccess(currentRole)
  const { data, isLoading } = useQuery(fetchListTenants, {}, { enabled })
  const currentDomain = useMemo(() => window.location.host.split('.')[0], [])
  const tenants = useMemo(() => (data ? data.filter((tenant) => tenant.domain !== currentDomain) : []), [
    data,
    currentDomain,
  ])
  const currentTenant = useMemo(() => {
    return data?.find((tenant) => tenant.domain === currentDomain) || null
  }, [data, currentDomain])

  const value = useMemo(() => {
    return {
      current: currentTenant,
      isLoading,
      allTenants: tenants || [],
    }
  }, [currentTenant, tenants, isLoading])

  return <TenantContext.Provider {...props} value={value} />
}

export const useTenants = () => {
  const context = useContext(TenantContext)
  if (!context) {
    throw new Error('Tenant Context is not defined')
  }

  return context
}
