import React from 'react'
import { Text } from '../index'
import { Box } from '../Box'
import { SystemProps } from '@chakra-ui/react'

interface Props extends SystemProps {
  onClick?: () => void
}

export const TableHeadCell: React.FC<Props> = ({ children, onClick, ...systemProps }) => {
  const styles = {
    p: 4,
    boxSizing: 'content-box',
    position: 'sticky',
    top: 0,
    bg: 'inherit',
    cursor: onClick ? 'pointer' : 'default',
    zIndex: 1,
  }

  return (
    <Box as="th" onClick={onClick} sx={styles} {...systemProps}>
      <Text fontFamily="label" fontSize="xs" fontWeight="bold" shade={2} whiteSpace="nowrap">
        {children}
      </Text>
    </Box>
  )
}
