import React from 'react'
import { Box, Skeleton, SkeletonProvider } from 'components'

export function OmniSearchSkeleton() {
  return (
    <SkeletonProvider isLoaded={false}>
      {[1, 2, 3].map((index) => (
        <Box key={index} mt={10}>
          <Skeleton width={20} height={5} mb={2} />
          <Skeleton height="72px" mb={2} />
          <Skeleton height="72px" mb={2} />
          <Skeleton height="72px" mb={2} />
        </Box>
      ))}
    </SkeletonProvider>
  )
}
