import { withProps } from 'components/core/utils'
import { Text } from 'components'
import { EventDetail } from 'libs/api/events'
import { IntlShape } from 'react-intl'
import translations from '../Events.i18n.json'
import { Column } from 'modules/Common/ListPage'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { getSubjectName } from './getSubjectName'
// import { HealthScreeningChip } from 'components/HealthScreeningChip'
import { PersonAvatar } from 'modules/People/components/PersonAvatar'

const RowText = withProps(Text, { fontSize: 'sm', shade: 1 })

// function renderSimilarity(event: EventDetail, intl: IntlShape) {
//   if (event.status === 'open' && event.attributes?.similarity) {
//     return <RowText>{event.attributes?.similarity}%</RowText>
//   }
//   if (event.status === 'confirmed') {
//     return <RowText color="green.500">{intl.formatMessage(translations.confirmed)}</RowText>
//   } else if (event.status === 'archived') {
//     return <RowText color="red.500">{intl.formatMessage(translations.archived)}</RowText>
//   }
//   return null
// }

export const buildColumns = (intl: IntlShape, healthMode: boolean): Column<EventDetail>[] => [
  {
    title: '',
    width: 10,
    render: (event) => <PersonAvatar person={event.subject} borderRadius={2} />,
  },
  {
    sortKey: 'name',
    title: intl.formatMessage(translations.name),
    render: (event) => <RowText ml={-4}>{getSubjectName(event)}</RowText>,
  },
  // healthMode
  //   ? {
  //       sortKey: 'screening',
  //       title: intl.formatMessage(translations.screening),
  //       render: (event) => <HealthScreeningChip value={event.attributes?.screening} />,
  //     }
  //   : {
  //       title: intl.formatMessage(translations.label),
  //       render: (event) => <ChipList items={event.subject.labels || []} />,
  //     },
  {
    sortKey: 'date',
    title: intl.formatMessage(translations.date),
    render: (event) => <RowText>{format(parseISO(event.timestamp), 'MM/dd/yyy')}</RowText>,
  },
  ...(healthMode
    ? []
    : [
        {
          sortKey: 'event',
          title: intl.formatMessage(translations.event),
          render: (event: EventDetail) => <RowText>{event.event_type}</RowText>,
        },
        // {
        //   sortKey: 'similarity',
        //   title: intl.formatMessage(translations.similarity),
        //   render: (event: EventDetail) => renderSimilarity(event, intl),
        // },
      ]),
  {
    sortKey: 'location',
    title: intl.formatMessage(translations.location),
    render: (event: EventDetail) => <RowText>{event.address?.place_name}</RowText>,
  },
]
