import { withProps } from 'components/core/utils'
import { Text } from 'components'
import { badgeRoles, PersonListItem } from 'libs/api'
import { IntlShape } from 'react-intl'
import translations from '../People.i18n.json'
import { Column } from 'modules/Common/ListPage'
import { getName } from './getName'
import React from 'react'
// import { HealthScreeningChip } from 'components/HealthScreeningChip'
import { PersonAvatar } from '../components/PersonAvatar'
import { BadgeEnabledChip } from '../components/BadgeEnabledChip/BadgeEnabledChip'

const RowText = withProps(Text, { fontSize: 'sm', shade: 1 })

export const buildColumns = (intl: IntlShape, healthMode: boolean): Column<PersonListItem>[] => [
  {
    width: 10,
    title: '',
    render: (person) => <PersonAvatar person={person} borderRadius={2} />,
  },
  {
    sortKey: 'name',
    title: intl.formatMessage(translations.name),
    render: (person) => <RowText>{getName(person)}</RowText>,
  },
  {
    title: intl.formatMessage(translations.badge),
    render: (item) => <BadgeEnabledChip person={item} />,
    textAlign: 'center',
  },
  {
    title: intl.formatMessage(translations.badgeRole),
    render: (person) => <RowText>{person.badge?.custom_fields?.badge_role ?? badgeRoles[0].name}</RowText>,
    textAlign: 'center',
  },
  // healthMode
  //   ? {
  //       sortKey: 'health_screening',
  //       title: intl.formatMessage(translations.screening),
  //       render: (person) => <HealthScreeningChip value={person.health_screening} />,
  //     }
  //   : {
  //       sortKey: 'labels',
  //       title: intl.formatMessage(translations.label),
  //       render: (person) => <ChipList items={person.labels || []} maxVisible={3} spacing={2} />,
  //     },
  {
    sortKey: 'zones',
    title: intl.formatMessage(translations.locations),
    render: (person) => <RowText>{person.zones.map((zone) => zone.name).join(', ')}</RowText>,
  },
  {
    sortKey: 'access_groups',
    title: intl.formatMessage(translations.accessGroups),
    render: (person) => <RowText>{person?.access_groups?.map((it) => it.name).join(', ') ?? ''}</RowText>,
  },
]
