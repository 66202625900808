import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const EditPhotoButtonIcon = createIcon({
  displayName: 'EditPhotoButtonIcon',
  path: (
    <>
      <circle opacity="0.5" cx="20" cy="20" r="20" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11L15.17 13H10V29H30V13H24.83L23 11H17ZM20 18C18.3431 18 17 19.3431 17 21C17 22.6569 18.3431 24 20 24C21.6569 24 23 22.6569 23 21C23 19.3431 21.6569 18 20 18ZM15 21C15 23.76 17.24 26 20 26C22.76 26 25 23.76 25 21C25 18.24 22.76 16 20 16C17.24 16 15 18.24 15 21Z"
        fill="white"
      />
    </>
  ),
  defaultProps: { color: 'black' },
  viewBox: '0 0 40 40',
})
