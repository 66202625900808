import React from 'react'
import { Text, Box } from '../index'
import { SystemProps } from '@chakra-ui/react'

type Props = Omit<SystemProps, 'width' | 'height'>

export const TableCell: React.FC<Props> = ({ children, ...systemProps }) => {
  const child =
    typeof children === 'string' ? (
      <Text fontFamily="label" fontSize="sm" shade={1}>
        {children}
      </Text>
    ) : (
      children
    )

  return (
    <Box as="td" p={4} {...systemProps}>
      {child}
    </Box>
  )
}
