import { DeviceType, getDeviceTypesList } from 'libs/api'
import React, { useMemo } from 'react'
import { Column, ListPage } from 'modules/Common/ListPage'
import translations from './DeviceType.i18n.json'
import { IntlShape, useIntl } from 'react-intl'
import { Actions } from './Actions/Actions'
import { buildActionColumns } from '../../helpers/buildActionColumns'
import { DeviceTypeEditButton } from './Actions/DeviceTypeEditButton/DeviceTypeEditButton'
import { DeviceTypeDeleteButton } from './Actions/DeviceTypeDeleteButton/DeviceTypeDeleteButton'
import { useOmniSearchType, SignedImage } from 'components'
import { hasKeeeAdminAccess } from 'utils/access'
import { useAuthContext } from 'hooks'

export const buildColumns = (intl: IntlShape, withActions = true): Column<DeviceType>[] => [
  {
    key: 'avatar',
    width: 10,
    render: (item) => <SignedImage src={item.image_url} objectFit="cover" boxSize="40px" />,
  },
  {
    title: intl.formatMessage(translations.name),
    field: 'name',
    width: '15%',
  },
  {
    title: intl.formatMessage(translations.description),
    field: 'description',
  },
  {
    title: intl.formatMessage(translations.count),
    render: (item) => intl.formatMessage(translations.devicesCount, item),
    width: '15%',
  },
  ...(withActions
    ? buildActionColumns({
        EditButton: DeviceTypeEditButton,
        DeleteButton: DeviceTypeDeleteButton,
      })
    : []),
]

export function DeviceTypes({ search }: { search: string }) {
  useOmniSearchType('deviceTypes')
  const intl = useIntl()
  const { currentRole } = useAuthContext()
  const isKeeeAdmin = hasKeeeAdminAccess(currentRole)

  const columns = useMemo(() => buildColumns(intl, isKeeeAdmin), [intl, isKeeeAdmin])

  return (
    <ListPage
      fetchItems={getDeviceTypesList}
      columns={columns}
      getId={(item) => item.thing_type_id}
      components={isKeeeAdmin ? { Actions } : {}}
      countLabel={translations.deviceTypesCount}
      variables={{ search }}
      itemName={intl.formatMessage(translations.deviceType)}
      realTimeChannel="thing_types"
    />
  )
}
