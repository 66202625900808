import { FlatfileSchema } from '../index'
import { DeviceType } from 'libs/api'

export type DevicesSchemaKey =
  | 'thing_type_id'
  | 'name'
  | 'description'
  | 'color'
  | 'local_ip_address'
  | 'device_string'
  | 'mac_address'
  | 'certificate_cn'

export function getDevicesSchema(deviceType: DeviceType[]): FlatfileSchema<DevicesSchemaKey> {
  return [
    {
      key: 'thing_type_id',
      label: 'Thing Type',
      type: 'select',
      options: deviceType.map((type) => ({ value: type.thing_type_id, label: type.name })),
      isRequired: true,
    },
    {
      key: 'name',
      label: 'Name',
      isRequired: true,
    },
    {
      key: 'description',
      label: 'Description',
      isRequired: false,
    },
    {
      key: 'local_ip_address',
      label: 'Local IP Address',
      isRequired: false,
    },
    {
      key: 'device_string',
      label: 'Device String',
      isRequired: false,
    },
    {
      key: 'mac_address',
      label: 'MAC address',
      isRequired: false,
    },
    {
      key: 'certificate_cn',
      label: 'Certificate Common Name',
      isRequired: false,
    },
    {
      key: 'color',
      label: 'Color',
      isRequired: false,
    },
  ]
}
