import { FormControl, FormControlProps, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { useField } from 'formik'
import React, { ComponentProps } from 'react'
import { OptionTypeBase } from 'react-select'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import { FormLabel } from './FormLabel'
import { useSelectStyles } from './hooks/useSelectStyles'

export type AsyncSelectFieldProps<OptionType extends OptionTypeBase> = {
  label?: string
  name: string
  type?: string
  placeholder?: string
  isMulti?: boolean
  isDisabled?: boolean
  SelectProps?: ComponentProps<typeof AsyncSelect>
} & AsyncProps<OptionType> &
  FormControlProps

export const AsyncSelectField = <OptionType extends OptionTypeBase>({
  label,
  loadOptions,
  defaultOptions,
  cacheOptions,
  name,
  id,
  type,
  placeholder,
  isMulti,
  isDisabled,
  SelectProps,
  ...props
}: AsyncSelectFieldProps<OptionType>) => {
  const [field, meta, helper] = useField(name)
  const styles = useSelectStyles()

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} isDisabled={isDisabled} {...props}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <InputGroup>
        <AsyncSelect
          placeholder={placeholder}
          value={field.value}
          onChange={(value) => helper.setValue(value)}
          onBlur={field.onBlur}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions={cacheOptions}
          styles={styles}
          isMulti={isMulti}
          isDisabled={isDisabled}
          {...SelectProps}
        />
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
