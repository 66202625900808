export type AdminTab =
  | 'users'
  | 'roles'
  | 'accessGroups'
  | 'locations'
  | 'labels'
  | 'deviceTypes'
  | 'eventTypes'
  | 'authorizationEventResolutions'
  | 'notifications'
  | 'billing'

export const home = '/'
export const login = '/login'
export const forgotPassword = '/forgot-password'
export const resetPassword = '/reset-password'
export const setupMfa = '/setup-mfa'
export const admin = '/admin'
export const adminTabWithName = (tab: AdminTab | ':tab') => `${admin}/${tab}`
export const adminTab = adminTabWithName(':tab')
export const users = adminTabWithName('users')
export const roles = adminTabWithName('roles')
export const accessGroups = adminTabWithName('accessGroups')
export const accessGroupsDetailWithId = (id: string) => `${accessGroups}/${id}`
export const accessGroupsDetail = accessGroupsDetailWithId(':id')
export const labels = adminTabWithName('labels')
export const deviceTypes = adminTabWithName('deviceTypes')
export const eventTypes = adminTabWithName('eventTypes')
export const authorizationEventResolutions = adminTabWithName('authorizationEventResolutions')
export const billing = adminTabWithName('billing')
export const adminNotifications = adminTabWithName('notifications')
export const notifications = '/notifications' // from Slack redirect callback
export const teamsNotifications = '/notifications/teams'
export const userDetailWithUsername = (username: string) => `${users}/${username}`
export const userDetail = userDetailWithUsername(':username')
export const roleDetailWithId = (id: string) => `${users}/${id}`
export const roleDetail = roleDetailWithId(':id')
export const events = '/events'
export const eventDetailWithId = (id: string) => `${events}/${id}`
export const eventDetail = eventDetailWithId(':id')
export const people = '/employees'
export const personDetailWithId = (id: string) => `${people}/${id}`
export const personDetail = personDetailWithId(':id')
export const devices = '/devices'
export const deviceDetailWithId = (id: string) => `${devices}/${id}`
export const deviceDetail = deviceDetailWithId(':id')
export const deviceLiveFeedWithId = (id: string) => `${devices}/${id}/feed`
export const deviceLiveFeed = deviceLiveFeedWithId(':id')
export const settings = '/settings'
export const locations = '/locations'
export const locationDetailWithId = (id: string) => `${locations}/${id}`
export const locationZonesWithId = (id: string) => `${locations}/${id}/zones`
export const locationZones = locationZonesWithId(':id')
export const locationDevicesWithId = (id: string) => `${locations}/${id}/devices`
export const locationDevices = locationDevicesWithId(':id')
export const locationDetail = locationDetailWithId(':id')
export const locationZoneDetailWithId = (locationId: string, zoneId: string) =>
  `${locationZonesWithId(locationId)}/${zoneId}`
export const locationZoneDetail = locationZoneDetailWithId(':locationId', ':zoneId')
export const locationDeviceDetailWithId = (locationId: string, deviceId: string) =>
  `${locationDevicesWithId(locationId)}/${deviceId}`
export const locationDeviceDetail = locationDeviceDetailWithId(':locationId', ':deviceId')
export const locationAccessGroupsWithId = (id: string) => `${locations}/${id}/access-groups`
export const locationAccessGroups = locationAccessGroupsWithId(':id')
export const locationAccessGroupDetailWithId = (locationId: string, accessGroupId: string) =>
  `${locationAccessGroupsWithId(locationId)}/${accessGroupId}`
export const locationAccessGroupDetail = locationAccessGroupDetailWithId(':locationId', ':accessGroupId')
export const locationEmployeesWithId = (id: string) => `${locations}/${id}/employees`
export const locationEmployees = locationEmployeesWithId(':id')
export const locationVisitorsWithId = (id: string) => `${locations}/${id}/visitors`
export const locationVisitors = locationVisitorsWithId(':id')
export const locationEventsWithId = (id: string) => `${locations}/${id}/events`
export const locationEvents = locationEventsWithId(':id')
export const reporting = '/reporting'
export const reportingAccessEvents = `${reporting}/access-events`
export const reportingBadges = `${reporting}/badges`
export const reportingFacialRec = `${reporting}/facial-rec`
export const reportingInactivity = `${reporting}/inactivity`
export const reportingOccupancy = `${reporting}/occupancy`
export const reportingOverview = `${reporting}/overview`
export const turnstileOverview = `${reporting}/turnstile`
export const reportingEmployees = `${reporting}/employees`
