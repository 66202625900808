import React, { useContext } from 'react'
import { BoxProps, Flex, Icon, useColorMode } from '@chakra-ui/react'
import { ViewMode } from './types'
import { ViewModesContext } from './ViewModesContextProvider'
import { GridViewIcon, ListViewIcon } from 'components/icons'

type Props = BoxProps & { mode: ViewMode }

const icons = {
  [ViewMode.LIST]: ListViewIcon,
  [ViewMode.GRID]: GridViewIcon,
  // [ViewMode.MAP]: MapViewIcon,
}

export function ViewModeButton({ mode, ...boxProps }: Props) {
  const { colorMode } = useColorMode()
  const context = useContext(ViewModesContext)

  if (!context) {
    throw Error('<ViewModeButton> can only be used inside <ViewModes> component')
  }

  const { activeMode, setActiveMode } = context
  const iconColor = colorMode === 'light' ? 'dark.3' : 'dark.4'
  const activeBorderColor = colorMode === 'light' ? 'primary.subtle' : 'primary.darker'

  return (
    <Flex
      width={8}
      height={8}
      align="center"
      justify="center"
      cursor="pointer"
      color={mode === activeMode ? 'primary.main' : iconColor}
      border="1px solid"
      borderColor={mode === activeMode ? activeBorderColor : 'transparent'}
      onClick={() => setActiveMode(mode)}
      {...boxProps}
    >
      <Icon as={icons[mode]} width={6} height={6} />
    </Flex>
  )
}
