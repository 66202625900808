import { API } from 'aws-amplify'
import { DeviceGroup } from './deviceGroups'
import { ListResponse } from './types'
import { refetchQueries } from 'hooks'
import { getDeviceGroups } from 'libs/api'

export type Zone = {
  created_at: string
  description: string
  latitude: number
  longitude: number
  name: string
  parent_zone_id?: string | null
  updated_at: string
  zone_id: string
  zones?: Zone[]
  device_groups?: DeviceGroup[]
  abbreviation?: string
  similarity_threshold: number
}

export type ZoneFormValues = {
  name: string
  description: string
  parent_zone_id?: string | null
  latitude: string
  longitude: string
  abbreviation?: string
  similarity_threshold?: string
}

export type CreateZonePayload = Pick<Zone, keyof ZoneFormValues>

export async function getZonesList(): Promise<ListResponse<Zone>> {
  return API.get('zones', '/zones', {
    queryStringParameters: {
      responseFormat: 'list',
    },
  })
}

export async function getZones(params: any): Promise<Zone[]> {
  return API.get('zones', '/zones', { queryStringParameters: params })
}

export async function fetchZone({ id }: { id: number | string }): Promise<Zone> {
  return API.get('zones', `/zones/${id}`, {})
}

export const deleteZone = async (zone: Zone): Promise<void> => {
  return API.del('zones', `/zones/${zone.zone_id}`, {})
}

export async function createZone(body: CreateZonePayload): Promise<Zone> {
  return API.post('zones', `/zones`, { body })
}

export async function updateZone(zone: Zone, payload: Partial<Zone>): Promise<Zone> {
  return API.put('zones', `/zones/${zone.zone_id}`, { body: payload })
}

export async function refetchZone(zoneId: string) {
  return Promise.all([
    refetchQueries(fetchZone, { id: zoneId }, { exact: true }),
    refetchQueries(getDeviceGroups, { zone_id: [zoneId] }, { exact: true }),
  ])
}
