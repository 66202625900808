import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const RadioActiveIcon = createIcon({
  displayName: 'RadioActiveIcon',
  path: (
    <>
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="16" height="16" fill="currentColor" />
        <path
          d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
          fill="white"
        />
      </g>
    </>
  ),
  viewBox: '0 0 16 16',
})
