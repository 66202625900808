import React from 'react'
import { Box } from '@chakra-ui/react'
import { DeviceTypeCreateButton } from './DeviceTypeCreateButton/DeviceTypeCreateButton'

interface Props {
  refetch: () => Promise<unknown>
}

export const Actions: React.FC<Props> = ({ refetch }) => {
  return (
    <Box>
      <DeviceTypeCreateButton refetch={refetch} />
    </Box>
  )
}
