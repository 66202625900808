import { BoxProps, Icon, IconProps, useColorMode } from '@chakra-ui/react'
import { SystemStyleObject } from '@chakra-ui/styled-system'
import React, { ComponentType } from 'react'
import { useIntl } from 'react-intl'
import { Box, Flex, Label, Skeleton, useSkeletonContext } from './core/index'

type StepIconProps = IconProps & {
  icon: ComponentType
}

export const StepIcon = ({ icon, ...otherProps }: StepIconProps) => {
  return <Icon as={icon} color="primary.main" boxSize="24px" {...otherProps} />
}

type StepHeaderProps = {
  icon: JSX.Element
  title: React.ReactNode
  subtitle: string
  timestamp?: string
  actionButton?: React.ReactNode
  styles?: Partial<Record<'title' | 'timestamp' | 'subtitle' | 'actionButton', SystemStyleObject>>
}

export const StepHeader = ({ icon, title, subtitle, timestamp, actionButton, styles }: StepHeaderProps) => {
  const intl = useIntl()
  const { isLoaded } = useSkeletonContext()

  return (
    <Flex alignItems="flex-start">
      <Skeleton mr={2}>{icon}</Skeleton>
      <Box>
        <Flex alignItems="center">
          <Skeleton mr={4}>
            <Label sx={styles?.title}>{title}</Label>
          </Skeleton>
          {timestamp && (
            <Skeleton>
              <Label fontSize={10} shade={2} sx={styles?.timestamp}>
                {intl.formatTime(timestamp).replace(' ', '')} {intl.formatDate(timestamp, { dateStyle: 'medium' })}
              </Label>
            </Skeleton>
          )}
        </Flex>
        <Skeleton display="inline-block" verticalAlign="top">
          <Label fontSize={10} shade={2} sx={styles?.subtitle}>
            {subtitle}
          </Label>
        </Skeleton>
      </Box>
      {actionButton && isLoaded && (
        <Box ml={8} sx={styles?.actionButton}>
          {actionButton}
        </Box>
      )}
    </Flex>
  )
}

type StepBodyProps = {
  children: React.ReactNode
  borderLeftWidth?: number
  borderLeftColor?: BoxProps['borderLeftColor']
  sx?: SystemStyleObject
}

export const StepBody = ({ children, borderLeftWidth = 2, borderLeftColor: _borderLeftColor, sx }: StepBodyProps) => {
  const { colorMode } = useColorMode()
  const borderLeftColor = _borderLeftColor || (colorMode === 'dark' ? 'dark.4' : 'light.0')
  return (
    <Flex mt={4} sx={sx}>
      <Flex w={6} mr={2} justifyContent="center">
        <Box borderLeftStyle="solid" borderLeftWidth={borderLeftWidth} borderLeftColor={borderLeftColor}></Box>
      </Flex>
      <Box pb={3} flex={1}>
        {children}
      </Box>
    </Flex>
  )
}
