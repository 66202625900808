import { AccessGroup, Zone } from 'libs/api'
import { FlatfileSchema } from '../index'

export type PeopleSchemaKey =
  | 'first_name'
  | 'last_name'
  | 'facial_rec_enabled'
  | 'employee_string'
  | 'badge_string'
  | 'zone_ids'
  | 'access_group_ids'

export function getPeopleSchema(locations: Zone[], accessGroups: AccessGroup[]): FlatfileSchema<PeopleSchemaKey> {
  return [
    {
      key: 'first_name',
      label: 'First Name',
      isRequired: true,
    },
    {
      key: 'last_name',
      label: 'Last Name',
      isRequired: true,
    },
    {
      key: 'facial_rec_enabled',
      label: 'Facial Rec Enabled (true/false)',
      isRequired: false,
      type: 'checkbox',
    },
    {
      key: 'employee_string',
      label: 'Employee ID',
      isRequired: false,
    },
    {
      key: 'badge_string',
      label: 'Badge ID',
      isRequired: false,
    },
    {
      key: 'zone_ids',
      label: 'Location',
      type: 'select',
      options: locations.map((item) => ({ value: item.zone_id, label: item.name })),
      isRequired: false,
    },
    {
      key: 'access_group_ids',
      label: 'Access Group',
      type: 'select',
      options: accessGroups.map((item) => ({ value: item.access_group_id, label: item.name })),
      isRequired: false,
    },
  ]
}
