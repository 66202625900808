import React, { useState, useEffect } from 'react'
import { Input } from '@chakra-ui/react'
import styled from '@emotion/styled'

export type TimePickerProps = {
  onChange?: (nextState: string) => void
  value: string | null
  label?: string
}

const TimeSelector = styled.div`
  display: flex;
  padding: 0 24px 0 24px;
  align-items: center;
  label {
    font-size: 14px;
    padding-right: 5px;
    width: 80px;
  }
`

const TimeSelectorLabel = styled.label`
  flex-shrink: 0;
  flex-grow: 0;
`

function coerceTime(time: string) {
  let [hours, minutes] = time.split(':').map(Number)
  if (time.toUpperCase().includes('PM') && hours !== 12) hours += 12
  return `${`${hours}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`
}

const noop = () => void 0
export const TimePicker = ({ label = 'Time', onChange = noop, ...props }: TimePickerProps) => {
  const [state, setState] = useState('')

  useEffect(() => {
    if (props.value) {
      setState(coerceTime(props.value))
    }
  }, [props.value])

  return (
    <TimeSelector>
      <TimeSelectorLabel>{label}:</TimeSelectorLabel>
      <Input
        type="time"
        value={state}
        onChange={(e) => {
          setState(e.target.value)
        }}
        onBlur={() => {
          onChange(state)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onChange(state)
          }
        }}
      />
    </TimeSelector>
  )
}
