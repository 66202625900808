import React from 'react'
import { useField } from 'formik'
import { Checkbox, FormControl, FormControlProps, FormErrorMessage, InputGroup } from '@chakra-ui/react'
import { useTextColorFromShade } from 'components/core/utils/shade'

export type Props = {
  name: string
} & FormControlProps

export const CheckboxField: React.FC<Props> = ({ children, name, ...props }) => {
  const [field, meta] = useField(name)
  const borderColor = useTextColorFromShade(3)

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      <InputGroup>
        <Checkbox isChecked={meta.value} borderColor={borderColor} {...field}>
          {children}
        </Checkbox>
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
