import React, { ReactElement } from 'react'
import { UpsertDeviceType } from './UpsertDeviceType'
import { UpsertModal } from '../../../../components'
import { useTranslations } from 'hooks'
import { DeviceType } from 'libs/api'
import translations from '../Actions.i18n.json'

interface Props {
  children: ({ open }: { open: () => void }) => ReactElement
  refetch: () => Promise<unknown>
  item?: DeviceType
}

export function UpsertDeviceTypeModal({ children, refetch, item }: Props) {
  const labels = useTranslations(translations)

  return (
    <UpsertModal labels={labels} Modal={UpsertDeviceType} item={item} onSuccess={refetch}>
      {children}
    </UpsertModal>
  )
}
