import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const RadioInactiveIcon = createIcon({
  displayName: 'RadioInactiveIcon',
  path: (
    <path
      d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
      stroke="#3E7BFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#fff"
    />
  ),
  viewBox: '0 0 16 16',
})
