import React, { ReactNode, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import { useAuthContext, useNotify, useTranslations } from 'hooks'
import translations from './WebsocketProvider.i18n.json'
import { useAccessToken } from './useAccessToken'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types'
import { usePingCheck } from './usePingCheck'
import { SubscribeHook, useChannelSubscribe } from './useChannelSubscribe'
import { noop } from 'lodash-es'
import { Role } from 'libs/api'

type ContextProps = WebSocketHook & SubscribeHook

export const WebsocketContext = React.createContext<ContextProps>({} as never)

type Props = {
  jwtToken: string
  currentRole: Role
  children: React.ReactNode
}

const reconnectAttempts = 20

const WebsocketProviderWithToken = ({ jwtToken, currentRole, children }: Props) => {
  const t = useTranslations(translations)
  const notify = useNotify()
  const [retryCount, setRetryCount] = useState(0)

  const websocketHook = useWebSocket(process.env.REACT_APP_API_WSS_URL!, {
    //share: true,
    retryOnError: true,
    reconnectAttempts,
    shouldReconnect: () => {
      setRetryCount(retryCount + 1)
      if (retryCount === reconnectAttempts) {
        notify.error(t.unableToConnect)
        // , {
        //   retryAction: () => window.location.reload(),
        //   retryLabel: 'Reload page',
        //   persist: true,
        // })
      }
      return true
    },
    onOpen: () => setRetryCount(0),
    queryParams: {
      token: jwtToken,
      role: currentRole.role_id,
    },
  })

  const subscribeHook = useChannelSubscribe(websocketHook)
  usePingCheck(websocketHook)

  return (
    <WebsocketContext.Provider value={{ ...websocketHook, ...subscribeHook }}>{children}</WebsocketContext.Provider>
  )
}

export const WebsocketProvider = ({ children }: { children: ReactNode }) => {
  const jwtToken = useAccessToken()
  const { currentRole } = useAuthContext()
  if (!jwtToken || !currentRole) {
    return (
      <WebsocketContext.Provider value={{ subscribe: noop, unsubscribe: noop } as ContextProps}>
        {children}
      </WebsocketContext.Provider>
    )
  }
  return (
    <WebsocketProviderWithToken jwtToken={jwtToken} currentRole={currentRole}>
      {children}
    </WebsocketProviderWithToken>
  )
}
