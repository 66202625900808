import { API } from 'aws-amplify'
import { pickBy } from 'lodash-es'

export async function fetchCognitoConfig({ origin }: { origin?: string }) {
  return API.get('config', '/config/cognito', {
    queryStringParameters: pickBy({ origin }),
  })
}

export type Tenant = {
  company: string
  contact: string
  createdAt: number
  domain: string
}

export async function fetchListTenants({ origin }: { origin?: string }): Promise<Tenant[]> {
  return API.get('config', '/config/list-tenants', {
    queryStringParameters: pickBy({ origin }),
  })
}
