import mapboxGlobalStyles from 'components/MapboxGLMap/mapboxGlobalStyles'

export const styles = {
  global: (props: any) => ({
    html: {
      color: props.colorMode === 'light' ? 'dark.0' : 'light.4',
      fontFamily: 'body',
    },
    ...mapboxGlobalStyles(props),
  }),
}
