import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const NotApprovedIcon = createIcon({
  displayName: 'NotApprovedIcon',
  path: (
    <path
      d="M18.627 7.757L14.385 12l4.242 4.243-1.414 1.414-4.243-4.243-4.242 4.243-1.414-1.414L11.556 12 7.314 7.757l1.414-1.414 4.242 4.243 4.243-4.243 1.414 1.414z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 25 24',
})
