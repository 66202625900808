import React, { ReactNode } from 'react'
import { Collapse as ChakraCollapse, Icon } from '@chakra-ui/react'
import { Box, Flex } from 'components'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'

const StyledCollapse = styled(ChakraCollapse)((props) => ({
  overflow: props.in ? `initial !important` : undefined,
}))

interface Props {
  title: ReactNode
  defaultOpened?: boolean
}

export const Collapse: React.FC<Props> = ({ title, children, defaultOpened }) => {
  const [show, setShow] = React.useState(Boolean(defaultOpened))

  const handleToggle = () => setShow(!show)

  return (
    <>
      <Flex cursor="pointer" align="center" justify="space-between" onClick={handleToggle}>
        <Box fontWeight="bold" mb={0} shade={1}>
          {title}
        </Box>
        <Icon as={show ? ChevronUpIcon : ChevronDownIcon} boxSize="24px" />
      </Flex>

      <Box mt={4}>
        <StyledCollapse in={show}>{children}</StyledCollapse>
      </Box>
    </>
  )
}
