import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const AccountIcon = createIcon({
  displayName: 'AccountIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 21H21V3H3V21ZM15 9C15 10.66 13.66 12 12 12C10.34 12 9 10.66 9 9C9 7.34 10.34 6 12 6C13.66 6 15 7.34 15 9ZM12 13.9C10 13.9 6 15 6 17V18H18V17C18 15 14 13.9 12 13.9Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
