import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useAuthContext } from 'hooks'
import { MainLayout, Flex } from 'components'
import * as routes from 'routes'
import { can, Subject } from 'utils/access'
import { AcceptTOS } from '../../components/core/AcceptTOS'

type Props = RouteProps & {
  withoutHeader?: boolean
  admin?: boolean
  subject?: Subject
}

const PermissionDenied = () => {
  return (
    <Flex justifyContent="center" padding={40}>
      You don't have permission to access this page.
    </Flex>
  )
}

const RouteFallback = ({ resetError }: { resetError: () => void }) => {
  useEffect(() => {
    // reset error boundary when route change
    return () => resetError()
  })
  return <div>An error has occured</div>
}

export const AuthenticatedRoute = ({ component: Component, admin, subject, withoutHeader, ...props }: Props) => {
  const { currentAuthenticatedUser, currentRole } = useAuthContext()

  if (!currentAuthenticatedUser) {
    return <Redirect to={{ pathname: routes.login, state: { redirectTo: props.location } }} />
  }

  if (admin && !can(currentRole, 'list', 'users')) {
    // TODO should we check any other subject?
    Component = PermissionDenied
  }

  if (subject && !can(currentRole, 'list', subject)) {
    Component = PermissionDenied
  }

  return (
    <Route
      {...props}
      render={(routeProps) => (
        <MainLayout withoutHeader={withoutHeader}>
          <Sentry.ErrorBoundary fallback={({ resetError }) => <RouteFallback resetError={resetError} />}>
            {/* @ts-ignore */}
            <Component {...routeProps} />
          </Sentry.ErrorBoundary>
          {currentAuthenticatedUser && <AcceptTOS />}
        </MainLayout>
      )}
    />
  )
}
