import Konva from 'konva'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Image } from 'react-konva'

function scaleDimension(
  width: number | undefined,
  height: number | undefined,
  newWidth: number | null = null,
  newHeight: number | null = null
) {
  if (width === undefined || height === undefined) {
    return {
      width: 0,
      height: 0,
    }
  }

  if (newWidth && newHeight === null) {
    let ratio = newWidth / width
    return {
      width: width * ratio,
      height: height * ratio,
    }
  }

  if (newHeight && newWidth === null) {
    let ratio = newHeight / height
    return {
      width: width * ratio,
      height: height * ratio,
    }
  }

  return {
    width,
    height,
  }
}

type Props = {
  src: string
  width?: number
  height?: number
  onLoaded?: (img: HTMLImageElement, ref: Konva.Image | null) => void
  stroke?: string
  strokeWidth?: number
} & Omit<Konva.ImageConfig, 'image'>

/**
 * Image component with support to image src support
 */
export const ImageURL = ({ src, onLoaded, width, height, ...imageProps }: Props) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const imageRef = useRef<Konva.Image | null>(null)

  useEffect(() => {
    const image = new window.Image()
    image.crossOrigin = 'Anonymous'
    image.src = src
    image.addEventListener('load', () => {
      setImage(image)
      onLoaded?.(image, imageRef.current)
    })
  }, [src, onLoaded])

  const size = useMemo(() => {
    return scaleDimension(image?.width, image?.height, width, height)
  }, [image, width, height])

  return image ? (
    <Image {...imageProps} image={image} width={size.width} height={size.height} ref={imageRef as any} />
  ) : null
}
