import React from 'react'
import { useTranslations } from 'hooks'
import { DeleteButton } from './DeleteButton'
import { useIntl } from 'react-intl'
import { Box } from '@chakra-ui/react'
import { Action, can, Subject } from 'utils/access'
import { useAuthContext } from 'hooks'

type BuildProps<T> = {
  getName: (item: T) => string
  onConfirm: (item: T) => Promise<unknown>
  translations: {
    title: string
    subtitle: string
    submit: string
    cancel: string
    deleted: string
    delete: string
  }
  subject: Subject
  action?: Action
}

type Props<T> = {
  item: T
  refetch: () => Promise<unknown>
}

export function createDeleteButton<T>({ translations, onConfirm, getName, subject, action = 'delete' }: BuildProps<T>) {
  return ({ item, refetch }: Props<T>) => {
    const intl = useIntl()

    const labels = {
      ...useTranslations(translations),
      subtitle: (
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(translations.subtitle, { name: `<b>${getName(item)}</b>` }),
          }}
        />
      ),
    }

    const handleConfirm = async () => {
      await onConfirm(item)
      refetch()
    }

    const { currentRole } = useAuthContext()

    return <Box>{can(currentRole, action, subject) && <DeleteButton labels={labels} onConfirm={handleConfirm} />}</Box>
  }
}
