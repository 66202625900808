import React, { SVGProps } from 'react'

type Props = SVGProps<any> & {
  colorMode?: 'light' | 'dark'
}

export const HealthLogoIconOnly = ({ colorMode, ...props }: Props) => (
  <svg width="18" height="30" viewBox="0 0 18 30" color="#FF3B3B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.2365 0C6.82173 0 0 6.82098 0 15.2373c0 8.4163 6.82173 15.2365 15.2365 15.2365.5891 0 1.1539-.234 1.5704-.6505.4165-.4164.6505-.9813.6505-1.5703V2.22085c0-.589-.234-1.15389-.6505-1.570377C16.3904.233982 15.8256 0 15.2365 0zm-3.0906 15.62l-2.68876 2.6872L6.7699 15.62c-2.10166-2.1024.58631-4.7904 2.68798-2.6894 2.10162-2.1017 4.78962.5863 2.68802 2.6894z"
      fill="currentColor"
    />
  </svg>
)
