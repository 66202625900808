import React, { ReactElement } from 'react'
import { useTranslations } from 'hooks'
import translations from './DeviceLinkFormModal.i18n.json'
import { DeviceItem, DeviceLink } from 'libs/api'
import { UpsertModal } from 'modules/Admin/components'
import { DeviceLinkForm } from '../DeviceLinkForm'

type EditProps = {
  device: DeviceItem
  link?: DeviceLink
}

type Props = EditProps & {
  children: ({ open }: { open: () => void }) => ReactElement
  onSuccess: () => void
}

export function DeviceLinkFormModal({ children, ...props }: Props) {
  const labels = useTranslations(translations)

  const handleSuccess = async () => {
    props.onSuccess()
  }
  return (
    <UpsertModal
      labels={labels}
      Modal={DeviceLinkForm}
      item={{ device: props.device, link: props.link }}
      onSuccess={handleSuccess}
    >
      {children}
    </UpsertModal>
  )
}
