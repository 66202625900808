import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  path: (
    <path
      d="M8.33317 17.0827V12.0827H11.6665V17.0827H15.8332V10.416H18.3332L9.99984 2.91602L1.6665 10.416H4.1665V17.0827H8.33317Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
})
