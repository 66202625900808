import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { SearchInput } from './SearchInput'

type Props = {
  onDebounce: (value: string) => void
  initialValue: string
  placeholder: string
}

export function DebouncedSearchInput({ onDebounce, initialValue, placeholder }: Props) {
  const [search, setSearch] = useState(initialValue)
  const [debouncedSearch] = useDebounce(search, 300)

  useEffect(() => {
    if (debouncedSearch !== initialValue) {
      onDebounce(debouncedSearch)
    }
  }, [debouncedSearch, initialValue, onDebounce])

  return <SearchInput placeholder={placeholder} value={search} onChange={(e: any) => setSearch(e.target.value)} />
}
