import { FlatfileSchema } from '../types'

export type CategoriesSchemaKey = 'name' | 'description' | 'color'

export const categoriesSchema: FlatfileSchema<CategoriesSchemaKey> = [
  {
    key: 'name',
    label: 'Name',
    isRequired: true,
  },
  {
    key: 'description',
    label: 'Description',
    isRequired: false,
  },
  {
    key: 'color',
    label: 'Color',
    isRequired: false,
  },
]
