import React from 'react'
import { DevicesGridItem } from 'modules/Devices/DevicesGridItem'
import { Link } from 'react-router-dom'
import * as routes from 'routes'
import { DeviceItem } from 'libs/api'

type Props = {
  device: DeviceItem
}

export const DevicePopup = ({ device }: Props) => {
  return (
    <Link to={routes.deviceDetailWithId(device.thing_id)}>
      <DevicesGridItem data={device} />
    </Link>
  )
}
