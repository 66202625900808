import React, { useState } from 'react'
import { ConfirmModal, ConfirmModalProps } from 'components'
import { useNotify } from 'hooks/useNotify'
import { useModal } from 'hooks/useModal'

export type ConfirmModalOptions = Omit<NonNullable<ConfirmModalProps['options']>, 'onSubmit'> & {
  onConfirm: () => unknown | Promise<unknown>
}

const ConfirmModalContext = React.createContext<(options: ConfirmModalOptions) => Promise<any>>(null as never)

export const ConfirmModalProvider: React.FC = ({ children }) => {
  const [visible, open, hide] = useModal(false)
  const [options, setOptions] = useState<undefined | ConfirmModalProps['options']>(undefined)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  const onOpen = (options: ConfirmModalOptions) =>
    new Promise<void>((resolve) => {
      const close = () => [hide(), resolve()]

      setOptions({
        ...options,
        onSubmit: (result: boolean) => {
          if (!result) return close()

          setLoading(true)
          Promise.resolve(options.onConfirm())
            .then(close, notify.error)
            .finally(() => {
              setLoading(false)
            })
        },
      })
      open()
    })

  return (
    <ConfirmModalContext.Provider value={onOpen}>
      {children}
      <ConfirmModal options={options} loading={loading} visible={visible} />
    </ConfirmModalContext.Provider>
  )
}

export const useConfirmModal = () => React.useContext(ConfirmModalContext)
