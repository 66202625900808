import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const NAV_COLLAPSED_KEY = 'navigationCollapsed'

export const useNavigationCollapse = () => {
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(localStorage.getItem(NAV_COLLAPSED_KEY) === 'true')

  useEffect(() => {
    localStorage.setItem(NAV_COLLAPSED_KEY, isNavigationCollapsed.toString())
  }, [isNavigationCollapsed])

  return [isNavigationCollapsed, setIsNavigationCollapsed] as [boolean, Dispatch<SetStateAction<boolean>>]
}
