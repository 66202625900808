import { Auth } from 'aws-amplify'
import { useState, useEffect } from 'react'
import { useAuthContext } from 'hooks'

export const useAccessToken = () => {
  const [jwtToken, setJwtToken] = useState('')
  const { currentAuthenticatedUser } = useAuthContext()

  useEffect(() => {
    if (!currentAuthenticatedUser) {
      setJwtToken('')
      return
    }
    let timeout: number
    const getToken = async () => {
      try {
        const accessToken = (await Auth.currentSession()).getAccessToken()
        setJwtToken(accessToken.getJwtToken())
        const msToExpiration = accessToken.getExpiration() * 1000 - new Date().getTime()
        timeout = window.setTimeout(getToken, Math.max(msToExpiration, 5000))
      } catch (error) {
        console.log(error)
        timeout = window.setTimeout(getToken, 5000)
      }
    }
    getToken()
    return () => window.clearTimeout(timeout)
  }, [currentAuthenticatedUser])

  return jwtToken
}
