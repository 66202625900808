import React from 'react'
import { Logo, LogoIconOnly, HealthLogo, HealthLogoIconOnly } from 'components/images'
import { useColorMode, Menu, MenuButton, Box } from '@chakra-ui/react'
// import { ChevronDownIcon } from '@chakra-ui/icons'
// import { useBgColorFromShade } from 'components/core/utils/shade'
import { useHealthMode } from 'hooks/useHealthMode'
import { Link } from 'react-router-dom'
import * as routes from 'routes'
// import translations from './LogoMenu.i18n.json'
// import { useTranslations } from 'hooks'
// import { Label } from 'components/core/Label'
// import { Text } from 'components'

// type ItemProps = {
//   onClick: () => void
//   label: string
//   description: string
//   children: React.ReactNode
// }

// const LogoMenuItem = ({ onClick, children, label, description }: ItemProps) => {
//   const backgroundColor = useBgColorFromShade(3)
//   return (
//     <MenuItem onClick={onClick} px={4} py={2} _focus={{ backgroundColor }} _hover={{ backgroundColor }}>
//       <Flex backgroundColor={backgroundColor} boxSize="41px" align="center" justify="center">
//         {children}
//       </Flex>
//       <Box ml={2}>
//         <Label>{label}</Label>
//         <Text fontSize={10} shade={2}>
//           {description}
//         </Text>
//       </Box>
//     </MenuItem>
//   )
// }

export const LogoMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
  // const t = useTranslations(translations)
  const { colorMode } = useColorMode()
  // const backgroundColor = useBgColorFromShade(3)
  const { healthMode } = useHealthMode()
  const IconComponent = healthMode ? HealthLogoIconOnly : LogoIconOnly
  const LogoComponent = healthMode ? HealthLogo : Logo

  return (
    <Menu autoSelect={false}>
      {isCollapsed ? (
        <MenuButton ml={4} mt="17px">
          <IconComponent height="30px" width="17.5px" colorMode={colorMode} />
        </MenuButton>
      ) : (
        // <MenuButton
        //   as={Button}
        //   w="184px"
        //   py={8}
        //   backgroundColor='transparent'
        // >
        <Link to={routes.locations}>
          <Box py={8} pl={4}>
            <LogoComponent height="30px" width="89px" colorMode={colorMode} />
          </Box>
        </Link>
        // </MenuButton>
      )}
      {/* <MenuList
        border={0}
        boxShadow=" 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
        borderRadius={0}
      >
        <Label fontSize="xs" shade={2} px={4} py={3}>
          {t.changeApp}
        </Label>
        <LogoMenuItem onClick={() => setHealthMode(false)} label={t.identity} description={t.identityDescription}>
          <LogoIconOnly height="30px" width="17.5px" colorMode={colorMode} />
        </LogoMenuItem>
        <LogoMenuItem onClick={() => setHealthMode(true)} label={t.health} description={t.healthDescription}>
          <HealthLogoIconOnly height="30px" width="17.5px" colorMode={colorMode} />
        </LogoMenuItem>
      </MenuList> */}
    </Menu>
  )
}
