import { createIcon } from '@chakra-ui/icon'

export const BadgeIcon = createIcon({
  displayName: 'BadgeIcon',
  path: (
    <g>
      <rect x="9" y="15" width="47" height="33.1136" rx="2" fill="currentColor" />
      <rect x="14.3409" y="20.3408" width="11.75" height="11.75" fill="white" />
      <rect x="14.3409" y="39.5684" width="36.3182" height="2.13636" rx="1.06818" fill="white" />
    </g>
  ),
  viewBox: '0 0 65 65',
})
