import React from 'react'
import { Image, ImageProps } from '@chakra-ui/react'
import { useAuthCredentials } from 'hooks'
import { personAvatarUrl, PersonAvatarParam } from '../helpers/personAvatarUrl'

type Props = Omit<ImageProps, 'src'> & {
  person: PersonAvatarParam
}

export const PersonAvatar = ({ person, ...imageProps }: Props) => {
  const credentials = useAuthCredentials()
  return <Image src={personAvatarUrl(person, credentials)} objectFit="cover" boxSize="40px" {...imageProps} />
}
