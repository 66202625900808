import { FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, SystemProps } from '@chakra-ui/react'
import { Box } from 'components'
import { useField } from 'formik'
import React from 'react'

type Props = { name: string; domain: string } & SystemProps

export const SubdomainField = ({ name, domain, ...props }: Props) => {
  const [field, meta] = useField(name)
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      <InputGroup>
        <Input
          type="text"
          placeholder="your-company"
          fontSize="sm"
          borderRadius={0}
          py="22px"
          maxLength={30}
          {...field}
        />
        <InputRightElement fontSize="sm" h="100%" w="100%" justifyContent="flex-start" pointerEvents="none">
          <Box visibility="hidden">{field.value || 'your-company'}</Box>
          <Box fontWeight="bold" ml={1}>
            .{domain}
          </Box>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
