import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const WorkIcon = createIcon({
  displayName: 'WorkIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6.5H22V21.5H2V6.5H8V4.5C8 3.40002 8.90002 2.5 10 2.5H14C15.1 2.5 16 3.40002 16 4.5V6.5ZM10 6.5H14V4.5H10V6.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
