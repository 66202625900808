import React from 'react'
import { Column, ListPage } from 'modules/Common/ListPage'
import translations from './Labels.i18n.json'
import { IntlShape, useIntl } from 'react-intl'
import { createDummyLabel, getLabelsList, Label } from '../../../../libs/api'
import { Actions } from './Actions/Actions'
import { NameRenderer } from './components/NameRenderer'
import { LabelDeleteButton } from './Actions/LabelDeleteButton/LabelDeleteButton'
import { LabelEditButton } from './Actions/LabelEditButton/LabelEditButton'
import { buildActionColumns } from '../../helpers/buildActionColumns'
import { useOmniSearchType } from 'components'

export const buildColumns = (intl: IntlShape, withActions = true): Column<Label>[] => [
  {
    title: intl.formatMessage(translations.name),
    render: NameRenderer,
    width: '15%',
  },
  {
    title: intl.formatMessage(translations.description),
    field: 'description',
  },
  {
    title: intl.formatMessage(translations.timeLimit),
    render: () => intl.formatMessage(translations.timeLimitNone),
    width: '15%',
  },
  {
    title: intl.formatMessage(translations.count),
    render: (item) => intl.formatMessage(translations.peopleCount, item),
    width: '15%',
  },
  ...(withActions
    ? buildActionColumns({
        EditButton: LabelEditButton,
        DeleteButton: LabelDeleteButton,
      })
    : []),
]

export function Labels({ search }: { search: string }) {
  useOmniSearchType('labels')
  const intl = useIntl()

  return (
    <ListPage
      fetchItems={getLabelsList}
      columns={buildColumns(intl)}
      getId={(item) => item.category_id}
      components={{
        Actions,
      }}
      countLabel={translations.labelsCount}
      variables={{ search }}
      dummyItem={createDummyLabel()}
      itemName={intl.formatMessage(translations.label)}
      realTimeChannel="categories"
    />
  )
}
