import React, { ReactElement } from 'react'
import { UpsertUser } from './UpsertUser'
import { UpsertModal } from '../../../../components'
import { useTranslations } from '../../../../../../hooks'
import translations from '../Actions.i18n.json'
import { User } from '../../../../../../libs/api'

interface Props {
  children: ({ open }: { open: () => void }) => ReactElement
  refetch: () => Promise<unknown>
  item?: User
}

export function UpsertUserModal({ children, refetch, item }: Props) {
  const labels = useTranslations(translations)

  return (
    <UpsertModal labels={labels} Modal={UpsertUser} item={item} onSuccess={refetch}>
      {children}
    </UpsertModal>
  )
}
