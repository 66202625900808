import React from 'react'
import { signUrl } from 'utils/aws/util'
import { Box } from 'components'
import { BoxProps } from '@chakra-ui/react'
import deviceGenericImage from './generic-device.svg'
import { useAuthCredentials } from 'hooks'

type Props = {
  color: string
  url: string | undefined
  fit: string
  fallbackImageSize?: BoxProps['backgroundSize']
} & Omit<BoxProps, 'color'>

export function DeviceImage({ color, url, fit = 'cover', fallbackImageSize = 'contain', ...boxProps }: Props) {
  const credentials = useAuthCredentials()
  const signedImage = signUrl(url, credentials)

  return (
    <Box
      backgroundColor={color}
      backgroundImage={`url("${signedImage || deviceGenericImage}")`}
      backgroundSize={signedImage ? fit : fallbackImageSize}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      {...boxProps}
    />
  )
}
