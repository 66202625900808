import { Box, Flex } from 'components'
import styled from '@emotion/styled'
import React from 'react'

import { Logo } from '../../images/Logo'
import Cover from './cover.svg'
import translations from './AuthLayout.i18n.json'

type Props = {
  children: React.ReactNode
}

const Bg = (props: any) => <Flex height="100vh" position="relative" {...props} />

const Title: React.FC = ({ children }) => (
  <Box color="#ffffff" fontFamily="Anton" fontSize={['24px', null, '42px', '64px']}>
    {children}
  </Box>
)

const Subtitle = styled.div`
  color: white;
  font-family: Open Sans;
  font-size: 16px;
`

const AbsoluteContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const AuthLayout = ({ children }: Props) => (
  <Flex>
    <Bg flex={1} backgroundColor="primary.main">
      <img alt={translations.cover} src={Cover} width="100%" height="100%" />
      <AbsoluteContent>
        <Box marginLeft="15%" marginTop="9%" marginRight="15%">
          <Logo colorMode="dark" style={{ marginBottom: '30%', maxWidth: '100%' }} />
          <Title>Creating Safer Environments</Title>
          <Subtitle>
            monitor the ingress and egress of employees and guests via facial recognition and our touchless, access
            control system
          </Subtitle>
        </Box>
      </AbsoluteContent>
    </Bg>
    <Bg bgShade={4} alignItems="center" justifyContent="center" width={['100%', '70%', '60%', '40%']} paddingTop="15vh">
      <Box height="100%" width={['90%', '80%', '70%', '65%']}>
        {children}
      </Box>
    </Bg>
  </Flex>
)
