import { useColorMode } from '@chakra-ui/react'

export type ShadeValue = 0 | 1 | 2 | 3 | 4

const textShades = [
  ['dark.0', 'light.4'],
  ['dark.1', 'light.3'],
  ['dark.2', 'light.2'],
  ['dark.3', 'light.1'],
  ['dark.4', 'light.0'],
]

const bgShades = [
  ['light.0', '#000'],
  ['light.1', '#0d0e15'],
  ['light.2', '#13141d'],
  ['light.3', '#171923'],
  ['light.4', '#1A202C'],
]

export function useTextColorFromShade(shade?: ShadeValue) {
  const { colorMode } = useColorMode()
  if (typeof shade !== 'undefined') return textShades[shade][colorMode === 'light' ? 0 : 1]
}

export function useBgColorFromShade(shade?: ShadeValue) {
  const { colorMode } = useColorMode()
  if (typeof shade !== 'undefined') return bgShades[shade][colorMode === 'light' ? 0 : 1]
}

export type ShadeProps = {
  shade?: ShadeValue
  bgShade?: ShadeValue
}

export function useShadeColors(props: ShadeProps): { color?: string; bg?: string } {
  const color = useTextColorFromShade(props.shade)
  const bg = useBgColorFromShade(props.bgShade)
  return { color, bg }
}
