import React from 'react'
import { Box, Flex, Skeleton, SkeletonText } from 'components'
import { Stack } from '@chakra-ui/react'
import { DeviceItem } from 'libs/api/devices'
import { DeviceImage } from './components'

type Props = {
  data: DeviceItem
}

export function DevicesGridItem({ data }: Props) {
  return (
    <Box bgShade={4} maxWidth="400px" h="100%">
      <DeviceImage url={data.image_url} color={data.color} height="148px" fallbackImageSize="80px" fit="cover" />
      <Box p={4} fontSize="sm" fontFamily="label" lineHeight={1.71} shade={1}>
        <Stack spacing={2}>
          <Flex justify="space-between">
            <Box fontWeight="bold">{data.name}</Box>
            {/* <DeviceChip device={data} /> */}
          </Flex>
          <Box>{data.place_name}</Box>
          <Box>{data.description}</Box>
        </Stack>
      </Box>
    </Box>
  )
}

export const DevicesGridSkeletonItem = () => (
  <Box bgShade={4} maxWidth="400px">
    <Skeleton h="148px" />
    <Box p={4} fontSize="sm" fontFamily="label" lineHeight={1.71} shade={1}>
      <Stack spacing={4}>
        <Flex justify="space-between">
          <Skeleton h={8} w="100px" />
          <Skeleton h={8} w="60px" />
        </Flex>
        <SkeletonText noOfLines={2} />
      </Stack>
    </Box>
  </Box>
)
