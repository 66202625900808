import { ICredentials } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import React, { ReactNode, useEffect, useState } from 'react'
import { setSigV4Credentials } from 'utils/aws/util'

export const AuthCredentialsContext = React.createContext<ICredentials | undefined>(undefined)

type Props = {
  children: ReactNode
  isAuthenticated: boolean
}

export const AuthCredentialsProvider = ({ children, isAuthenticated }: Props) => {
  const [credentials, setCredentials] = useState<ICredentials>()

  useEffect(() => {
    if (!isAuthenticated) return
    let credentialsTimeout: number
    async function loadCredentials() {
      try {
        // currentCredentials() will only request new credentials after expiration
        setCredentials(await Auth.currentCredentials())
      } catch (e) {
        if (e !== 'cannot get guest credentials when mandatory signin enabled') {
          console.error(e)
        }
      }
      credentialsTimeout = window.setTimeout(loadCredentials, 5 * 1000)
    }
    loadCredentials()
    return () => window.clearTimeout(credentialsTimeout)
  }, [isAuthenticated])

  useEffect(() => {
    if (credentials) setSigV4Credentials(credentials)
  }, [credentials])

  return <AuthCredentialsContext.Provider value={credentials}>{children}</AuthCredentialsContext.Provider>
}
