import { Box, Button, ButtonProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import translations from './ConfirmModal.i18n.json'
import { useIntl } from 'react-intl'
import { Modal } from 'components'

export type ConfirmModalProps = {
  options?: {
    title: string
    subtitle: ReactNode
    submit: string
    cancel?: string
    onSubmit: (result: boolean) => void
  }
  visible: boolean
  loading: boolean
}

const ActionButton = (props: ButtonProps) => <Button fontSize="xs" px={8} py={4} {...props} />

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ options, loading, visible }) => {
  const intl = useIntl()

  return !options ? null : (
    <Modal
      isVisible={visible}
      title={options.title}
      onClose={() => options.onSubmit(false)}
      footer={
        <Box>
          <ActionButton variant="outline" borderColor="primary.main" mr={3} onClick={() => options.onSubmit(false)}>
            {options?.cancel ?? intl.formatMessage(translations.cancel)}
          </ActionButton>
          <ActionButton
            bg="red.0"
            color="light.4"
            _hover={{ bg: 'red.2' }}
            isLoading={loading}
            onClick={() => options.onSubmit(true)}
          >
            {options.submit}
          </ActionButton>
        </Box>
      }
    >
      {options.subtitle}
    </Modal>
  )
}
