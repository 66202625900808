import React, { useMemo } from 'react'
import { Box, HeaderTitle } from 'components'
import { useAuthContext, useQuery, useTextInput, useTranslations } from 'hooks'
import {
  AuthorizationEventResolution,
  DeviceItem,
  DeviceType,
  EventDetail,
  EventType,
  fetchDevicesList,
  // fetchEventsList,
  fetchPeopleList,
  getAuthorizationEventResolutionsList,
  getDeviceTypesList,
  getRolesList,
  // getEventTypesList,
  // getLabelsList,
  getUsersList,
  Label,
  PersonListItem,
  Role,
  User,
} from 'libs/api'
import { SearchInput } from 'modules/Common/ListPage/components/SearchInput'
import { buildColumns as buildDevicesColumns } from 'modules/Devices/helpers/buildColumns'
import { buildColumns as buildEventsColumns } from 'modules/Events/helpers/buildColumns'
import { buildColumns as buildPeopleColumns } from 'modules/People/helpers/buildColumns'
import { buildColumns as buildUsersColumns } from 'modules/Admin/tabs/Users/Users'
import { buildColumns as buildLabelsColumns } from 'modules/Admin/tabs/Labels/Labels'
import { buildColumns as buildDeviceTypesColumns } from 'modules/Admin/tabs/DeviceTypes/DeviceTypes'
import { buildColumns as buildEventTypesColumns } from 'modules/Admin/tabs/EventTypes/EventTypes'
import { buildColumns as buildAuthorizationEventResolutionsColumns } from 'modules/Admin/tabs/AuthorizationEventResolutions/AuthorizationEventResolutions'
import { useIntl } from 'react-intl'
import * as routes from 'routes'
import { useDebounce } from 'use-debounce/lib'
import translations from './OmniSearch.i18n.json'
import { MAX_RESULTS, ResultsSection } from './components/ResultsSection'
import { flatten, pull } from 'lodash-es'
import { useHealthMode } from 'hooks/useHealthMode'
import { OmniSearchSkeleton } from './OmniSearchSkeleton'
import { can } from 'utils/access'

export type OmniSearchType =
  | 'default'
  | 'people'
  | 'events'
  | 'devices'
  | 'users'
  | 'labels'
  | 'deviceTypes'
  | 'eventTypes'
  | 'authorizationEventResolutions'

type SearchResults = {
  people: PersonListItem[]
  events: EventDetail[]
  devices: DeviceItem[]
  users: User[]
  labels: Label[]
  deviceTypes: DeviceType[]
  eventTypes: EventType[]
  authorizationEventResolutions: AuthorizationEventResolution[]
}

async function searchAll({ search, currentRole }: { search: string; currentRole?: Role }): Promise<SearchResults> {
  const getItems = (response: any) => response.items
  const variables = { search, limit: MAX_RESULTS + 1 }

  const [
    people,
    events,
    devices,
    users,
    labels,
    deviceTypes,
    eventTypes,
    authorizationEventResolutions,
  ] = await Promise.allSettled([
    can(currentRole, 'list', 'people') ? fetchPeopleList(variables) : Promise.reject(),
    Promise.reject(), //fetchEventsList(variables),
    can(currentRole, 'list', 'things') ? fetchDevicesList(variables) : Promise.reject(),
    can(currentRole, 'list', 'users') ? getUsersList(variables) : Promise.reject(),
    Promise.reject(), // isAdmin ? getLabelsList(variables) : Promise.reject(),
    can(currentRole, 'list', 'thing_types') ? getDeviceTypesList(variables) : Promise.reject(),
    Promise.reject(), // isAdmin ? getEventTypesList(variables) : Promise.reject(),
    can(currentRole, 'list', 'authorization_event_resolutions')
      ? getAuthorizationEventResolutionsList(variables)
      : Promise.reject(),
  ]).then((results) => results.map((result) => (result.status === 'fulfilled' ? getItems(result.value) : [])))

  return { people, events, devices, users, labels, deviceTypes, eventTypes, authorizationEventResolutions }
}

type Props = {
  type: OmniSearchType
}

export function OmniSearch({ type }: Props) {
  const { currentRole } = useAuthContext()
  const t = useTranslations(translations)
  const [searchValue, handleSearchChange] = useTextInput()
  const [debouncedSearch] = useDebounce(searchValue, 300)
  const intl = useIntl()
  const { healthMode } = useHealthMode()
  const { data, isFetching } = useQuery(
    searchAll,
    { search: debouncedSearch, currentRole },
    { enabled: !!debouncedSearch }
  )
  const rolesQuery = useQuery(getRolesList, {}, { staleTime: Infinity })

  const noResults = useMemo(() => {
    if (!data) return false
    return flatten(Object.values(data as Object)).length === 0
  }, [data])

  const sections = {
    people: (
      <ResultsSection
        title={t.people}
        items={data?.people}
        getId={(person) => person.person_id}
        columns={buildPeopleColumns(intl, healthMode)}
        getItemPath={routes.personDetailWithId}
        pathToAll={routes.people}
        search={debouncedSearch}
      />
    ),
    events: (
      <ResultsSection
        title={t.events}
        items={data?.events}
        getId={(event) => event.event_id}
        columns={buildEventsColumns(intl, healthMode)}
        getItemPath={routes.eventDetailWithId}
        pathToAll={routes.events}
        search={debouncedSearch}
      />
    ),
    devices: (
      <ResultsSection
        title={t.devices}
        items={data?.devices}
        getId={(device) => device.thing_id}
        columns={buildDevicesColumns({ intl, noEdit: true })}
        getItemPath={routes.deviceDetailWithId}
        pathToAll={routes.devices}
        search={debouncedSearch}
      />
    ),
    users: (
      <ResultsSection
        title={t.users}
        items={data?.users}
        getId={(user) => user.userName}
        columns={buildUsersColumns(intl, rolesQuery.data?.items ?? [], false)}
        getItemPath={routes.userDetailWithUsername}
        pathToAll={routes.users}
        search={debouncedSearch}
      />
    ),
    labels: (
      <ResultsSection
        title={t.labels}
        items={data?.labels}
        getId={(label) => label.category_id}
        columns={buildLabelsColumns(intl)}
        getItemPath={undefined}
        pathToAll={routes.labels}
        search={debouncedSearch}
      />
    ),
    deviceTypes: (
      <ResultsSection
        title={t.deviceTypes}
        items={data?.deviceTypes}
        getId={(type) => type.thing_type_id}
        columns={buildDeviceTypesColumns(intl)}
        getItemPath={undefined}
        pathToAll={routes.deviceTypes}
        search={debouncedSearch}
      />
    ),
    eventTypes: (
      <ResultsSection
        title={t.eventTypes}
        items={data?.eventTypes}
        getId={(type) => type.event_type_id}
        columns={buildEventTypesColumns(intl)}
        getItemPath={undefined}
        pathToAll={routes.eventTypes}
        search={debouncedSearch}
      />
    ),
    authorizationEventResolutions: (
      <ResultsSection
        title={t.authorizationEventResolutions}
        items={data?.authorizationEventResolutions}
        getId={(resolution) => resolution.resolution_id}
        columns={buildAuthorizationEventResolutionsColumns(intl)}
        getItemPath={undefined}
        pathToAll={routes.authorizationEventResolutions}
        search={debouncedSearch}
      />
    ),
  }

  type ResultKey = Exclude<OmniSearchType, 'default'>

  const resultsKeys: ResultKey[] = [
    'people',
    'events',
    'devices',
    'users',
    'labels',
    'deviceTypes',
    'eventTypes',
    'authorizationEventResolutions',
  ]

  if (type === 'default' && data) {
    // order by number of results ascending
    resultsKeys.sort((a, b) => data[a].length - data[b].length)
  } else if (type !== 'default') {
    // move section to beginning of array
    pull(resultsKeys, type)
    resultsKeys.unshift(type)
  }

  const sortedSections = resultsKeys.map((key) => <React.Fragment key={key}>{sections[key]}</React.Fragment>)

  return (
    <Box pb={8}>
      <HeaderTitle>{t.title}</HeaderTitle>
      <SearchInput autoFocus placeholder={t.placeholder} value={searchValue} onChange={handleSearchChange} />
      {isFetching ? (
        <OmniSearchSkeleton />
      ) : data && noResults ? (
        <Box shade={2} mt={4}>
          {t.noResults}
        </Box>
      ) : (
        <Box>{sortedSections}</Box>
      )}
    </Box>
  )
}
