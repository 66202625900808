import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const StarsIcon = createIcon({
  displayName: 'StarsIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.48001 6.46997 2 11.99 2C17.52 2 22 6.48001 22 12C22 17.52 17.52 22 11.99 22C6.46997 22 2 17.52 2 12ZM12 15.45L16.23 18L15.11 13.18L18.8401 9.95001L13.92 9.53L12 5L10.0801 9.54001L5.16003 9.95999L8.89001 13.19L7.77002 18L12 15.45Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
