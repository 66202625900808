import { useMemo } from 'react'

const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

export function getMobileDetect(userAgent: string) {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))

  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
  }
}

export function useMobileDetect() {
  return useMemo(() => getMobileDetect(userAgent), [])
}

export function useIsIOS() {
  const mobileDetect = useMobileDetect()
  return useMemo(() => mobileDetect.isIos(), [mobileDetect])
}
