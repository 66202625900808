import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  path: (
    <path
      id="icon/navigation/menu_24px"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
