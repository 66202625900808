import useIntercom from '@reclaim-ai/react-intercom-hook'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useCurrentUser } from 'hooks'

const appId = process.env.REACT_APP_INTERCOM_APP_ID ?? ''

const _useIntercomWithLocation = () => {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const { email, 'custom:tos_accepted': tos_accepted = 0 } = currentUser.attributes
  const intercom = useIntercom({
    app_id: appId,
    name: currentUser.fullName,
    email,
    created_at: `${Math.ceil(tos_accepted / 1000)}`,
  })

  useEffect(() => {
    intercom('update') // Track route changes
  }, [intercom, location])
}

export const useIntercomWithLocation = appId ? _useIntercomWithLocation : () => {}
