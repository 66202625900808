import { createIcon } from '@chakra-ui/icon'

export const ReportingIcon = createIcon({
  displayName: 'ReportingIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 17.5V2.5H17.5V17.5H2.5ZM5.83333 14.1667H7.5V8.33333H5.83333V14.1667ZM10.8333 14.1667H9.16667V5.83333H10.8333V14.1667ZM12.5 14.1667H14.1667V10.8333H12.5V14.1667Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
})
