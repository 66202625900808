import React from 'react'
import { ParsedColumns } from 'modules/Common/ListPage/helpers/parseColumns'
import { TableCell, TableRow } from '../../../../components/core/Table'
import { Skeleton } from 'components'

interface Props<T> {
  columns: ParsedColumns<T>
  items: T[]
  getId: ((item: T) => string | number) | undefined
  refetch: () => Promise<any>
  onItemClick?: (item: T) => void
  bg?: string
}

export function ListPageContent<T>({ items, columns, getId, refetch, onItemClick, bg }: Props<T>) {
  return (
    <>
      {items.map((item, index) => (
        <TableRow key={getId ? getId(item) : index} onClick={onItemClick ? () => onItemClick(item) : undefined}>
          {columns.map((column) => (
            <TableCell key={column.key} textAlign={column.textAlign} bg={bg}>
              <Skeleton isInline>{column.render(item, { refetch })}</Skeleton>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
