import { deleteUser } from 'libs/api'
import { createDeleteButton } from '../../../../components'
import translations from './UserDeleteButton.i18n.json'

export const UserDeleteButton = createDeleteButton({
  translations,
  onConfirm: deleteUser,
  getName: (user) => user.userName,
  subject: 'users',
})
