import { SystemProps } from '@chakra-ui/react'
import { Chip } from 'components'
import { useBgColorFromShade } from 'components/core/utils'
import { useTranslations } from 'hooks'
import { User } from 'libs/api'
import React from 'react'
import translations from './EnabledChip.i18n.json'

type Props = SystemProps & {
  user: User
}

export const EnabledChip = ({ user, ...systemProps }: Props) => {
  const t = useTranslations(translations)
  const color = useBgColorFromShade(4)
  return (
    <Chip
      bgColor={user.enabled ? 'green.2' : 'red.0'}
      border="none"
      borderRadius={20}
      lineHeight={1}
      {...systemProps}
      color={color}
    >
      {user.enabled ? t.enabled : t.disabled}
    </Chip>
  )
}
