import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const VolumeOffIcon = createIcon({
  displayName: 'VolumeOffIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92999 4.345L4.33997 2.935L21.07 19.655L19.66 21.065L17.61 19.015C16.57 19.845 15.34 20.465 14 20.765V18.705C14.8 18.475 15.53 18.085 16.18 17.595L12 13.415V20.005L7 15.005H3V9.005H7L7.28998 8.70499L2.92999 4.345ZM18.59 14.345C18.85 13.615 19 12.825 19 12.005C19 8.835 16.89 6.155 14 5.295V3.235C18.01 4.145 21 7.725 21 12.005C21 13.395 20.68 14.705 20.12 15.875L18.59 14.345ZM12 4.005L10.12 5.88499L12 7.765V4.005ZM14 7.975C15.48 8.715 16.5 10.235 16.5 12.005C16.5 12.085 16.49 12.165 16.48 12.245L14 9.765V7.975Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
