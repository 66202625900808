import React, { ReactNode } from 'react'
import { Modal as ChakraModal, ModalContent, ModalOverlay, ModalFooter, ModalBody, ModalProps } from '@chakra-ui/react'
import { Box } from './Box'
import { useTextColorFromShade } from './utils'
// import { useIsIOS } from 'hooks'

type Props = {
  children: ReactNode
  footer?: ReactNode
  visible?: boolean
  isVisible?: boolean
  onClose?: () => void
  title?: string
} & Omit<ModalProps, 'isOpen'>

export const MODAL_CLOSE_DURATION = 150

export const Modal: React.FC<Props> = ({ children, footer, visible, onClose, title, isVisible, ...modalProps }) => {
  const contentColor = useTextColorFromShade(2)
  // const isIOS = useIsIOS()

  if (typeof visible !== 'undefined') {
    console.warn('Modal `visible` prop will be deprecated. Use `isVisible` instead')
  }

  visible = typeof isVisible !== 'undefined' ? isVisible : (visible as boolean)
  return (
    <ChakraModal
      isOpen={visible}
      onClose={onClose!}
      //Fixes the issue when the modal cutting off on Ipad devices
      // isCentered={!isIOS}
      isCentered={false}
      autoFocus={false}
      motionPreset="slideInBottom"
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent color={contentColor} fontSize="sm" borderRadius={0}>
        <ModalBody p={8}>
          {title && (
            <Box fontWeight="bold" fontFamily="label" mb={2} shade={0}>
              {title}
            </Box>
          )}
          {children}
        </ModalBody>
        {footer && (
          <ModalFooter p={8} pt={2}>
            {footer}
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  )
}
