import React from 'react'
import { Box, Label, Link, Table, TableBody } from 'components'
import { ListPageContent } from 'modules/Common/ListPage/components/ListPageContent'
import { parseColumns } from 'modules/Common/ListPage/helpers/parseColumns'
import { Column } from 'modules/Common/ListPage'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'hooks'
import translations from '../OmniSearch.i18n.json'

export const MAX_RESULTS = 3

export function ResultsSection<T>({
  title,
  items,
  columns,
  getId,
  getItemPath,
  pathToAll,
  search,
}: {
  title: string
  items: T[] | undefined
  columns: Column<T>[]
  getId: ((item: T) => string) | undefined
  getItemPath: ((id: string) => string) | undefined
  pathToAll: string
  search: string
}) {
  const history = useHistory()
  const t = useTranslations(translations)

  if (!items?.length) {
    return null
  }

  const onItemClick = !getItemPath || !getId ? undefined : (item: T) => history.push(getItemPath(getId(item)))

  return (
    <Box mt={10}>
      <Label mb={2}>{title}</Label>
      <Table>
        <TableBody>
          <ListPageContent
            columns={parseColumns(columns)}
            items={items.slice(0, MAX_RESULTS)}
            getId={getId}
            refetch={() => Promise.resolve()}
            onItemClick={onItemClick}
          />
        </TableBody>
      </Table>
      {items.length > MAX_RESULTS && (
        <Link
          display="inline-block"
          color="primary.main"
          fontFamily="label"
          mt={4}
          fontSize="sm"
          to={{ pathname: pathToAll, search: `?search=${search}` }}
        >
          {t.viewAll}
        </Link>
      )}
    </Box>
  )
}
