import React, { ReactElement } from 'react'
import { UpsertAuthorizationEventResolution } from './UpsertAuthorizationEventResolution'
import { UpsertModal } from '../../../../components'
import { useTranslations } from 'hooks'
import { AuthorizationEventResolution } from 'libs/api'
import translations from '../Actions.i18n.json'

interface Props {
  children: ({ open }: { open: () => void }) => ReactElement
  refetch: () => Promise<unknown>
  item?: AuthorizationEventResolution
}

export function UpsertAuthorizationEventResolutionModal({ children, refetch, item }: Props) {
  const labels = useTranslations(translations)

  return (
    <UpsertModal labels={labels} Modal={UpsertAuthorizationEventResolution} item={item} onSuccess={refetch}>
      {children}
    </UpsertModal>
  )
}
