import React, { ComponentType } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useTranslations } from 'hooks'
import translations from './UploadArea.i18n.json'
import { Text, Box } from 'components'
import { useBgColorFromShade } from 'components/core/utils/shade'
import { ImageIcon } from 'components/icons'
import { Icon } from '@chakra-ui/react'

const browseLinkStyles = {
  fontWeight: 'bold',
  color: 'primary.main',
  cursor: 'pointer',
}

export type UploadAreaProps = DropzoneOptions & {
  placeholder?: string
  icon?: ComponentType
}

export const UploadArea = ({ placeholder, icon, ...options }: UploadAreaProps) => {
  const t = useTranslations(translations)
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options)
  const dragOverBg = useBgColorFromShade(2)
  return (
    <Box
      backgroundColor={isDragActive ? dragOverBg : 'transparent'}
      border="1px dashed"
      borderColor="light.0"
      textAlign="center"
      outline="none"
      p={6}
      _focus={{ borderColor: 'primary.main' }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Box shade={3} mb={2}>
        <Icon as={icon ?? ImageIcon} boxSize="20px" />
      </Box>
      {isDragActive ? (
        <Text fontSize="sm">{t.dropFilesHere}</Text>
      ) : (
        <Text fontSize="sm">
          {placeholder ?? t.dragAndDropAnImageOr}{' '}
          <Box as="span" {...browseLinkStyles}>
            {t.browse}
          </Box>
        </Text>
      )}
    </Box>
  )
}
