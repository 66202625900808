import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const CheckCircleIcon = createIcon({
  displayName: 'CheckCircleIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.48 6.47998 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.47998 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41003 10.59L5 12Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
