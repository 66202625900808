import React, { useContext } from 'react'
import { Switch, useColorMode } from '@chakra-ui/react'
import { Flex, Text } from './index'
import { SidebarNavContext } from '../SidebarNav'

export function DarkModeSwitch() {
  const { colorMode, toggleColorMode } = useColorMode()
  const { isCollapsed } = useContext(SidebarNavContext)

  return (
    <Flex align="center" mt="5">
      <Switch isChecked={colorMode === 'dark'} size="sm" onChange={toggleColorMode} />
      {!isCollapsed && (
        <Text fontSize="xs" fontFamily="label" fontWeight="bold" ml={2}>
          Dark mode
        </Text>
      )}
    </Flex>
  )
}
