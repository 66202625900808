import React, { ComponentType, Dispatch, SetStateAction, useState } from 'react'
import { useListQuery, useRealTimeUpdatesForList } from 'hooks'
import { Box } from '@chakra-ui/react'
import { Column } from 'modules/Common/ListPage/types'
import { OrderDirection } from 'libs/api'
import { ViewModeList } from './components/ViewModeList'
import { ActionBar } from './components/ActionBar'
import { useHistory } from 'react-router-dom'

interface Props<ItemType, CursorType, FiltersType> {
  fetchItems: (cursor?: CursorType) => Promise<{ items: ItemType[]; nextCursor?: CursorType; totalCount: number }>
  columns: Column<ItemType>[]
  getId: (item: ItemType) => string | number
  getName?: (item: ItemType) => string
  getRoute?: (item: ItemType) => string
  dummyItem?: ItemType
  itemName: string
  realTimeChannel: string
  components: {
    Actions?: React.FC<{
      refetch: () => Promise<unknown>
    }>
    Filters?: React.FC<{
      defaultFilters: FiltersType
      applyFilters: Dispatch<SetStateAction<FiltersType>>
    }>
    EmptyState?: ComponentType
  }
  countLabel: string
  variables?: CursorType
}

export const ListPage = <ItemType extends {}, CursorType extends {}, FiltersType extends {} = {}>({
  fetchItems,
  columns,
  components,
  countLabel,
  getId,
  getName,
  getRoute,
  variables = {} as CursorType,
  dummyItem,
  itemName,
  realTimeChannel,
}: Props<ItemType, CursorType, FiltersType>) => {
  const history = useHistory()
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDirection, setOrderDirection] = useState<OrderDirection>('DESC')
  const [filters, setFilters] = useState<FiltersType>({} as FiltersType)
  const query = useListQuery<ItemType, CursorType>(fetchItems, {
    limit: 20,
    ...filters,
    orderBy,
    orderDirection,
    ...variables,
  })

  useRealTimeUpdatesForList<ItemType>({
    channel: realTimeChannel,
    isDisabled: query.isFetching,
    itemName,
    getName,
    getRoute,
    refreshAction: query.refetch,
  })

  return (
    <Box mb={8}>
      <ActionBar data={query.data} countLabel={countLabel} modes={[]}>
        {components.Filters && <components.Filters defaultFilters={filters} applyFilters={setFilters} />}
        {components.Actions && <components.Actions refetch={query.refetch} />}
      </ActionBar>
      <ViewModeList
        query={query}
        columns={columns}
        orderProps={{ orderBy, setOrderBy, orderDirection, setOrderDirection }}
        getId={getId}
        onItemClick={getRoute ? (item) => history.push(getRoute(item)) : undefined}
        EmptyState={components.EmptyState}
        dummyItem={dummyItem}
      />
    </Box>
  )
}
