import { uploadFile } from 'utils/aws/util'
import { Attachment } from 'types'
import { identity } from 'lodash-es'
import { MixedSchema } from 'yup'

export interface Cancel {
  message: string
}

export interface CancelToken {
  promise: Promise<Cancel>
  reason?: Cancel
  throwIfRequested(): void
}

export interface Canceler {
  (message?: string): void
}

export interface CancelTokenSource {
  token: CancelToken
  cancel: Canceler
}

export const fileToAttachment = async (
  file: File,
  onUploadProgress?: (e: ProgressEvent) => void,
  collectCancelSource?: (cancelSource: CancelTokenSource) => void
): Promise<Attachment> => {
  return {
    name: file.name,
    size: file.size,
    date: new Date().toISOString(),
    type: file.type,
    url: await uploadFile(file, onUploadProgress, collectCancelSource),
  }
}

export const requiredIf = <T = unknown>(condition: (other: T) => boolean = identity) => (
  other: T,
  schema: MixedSchema
) => (condition(other) ? schema.required() : schema)

export function isNotUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export function isTruthy<T>(x: T | undefined | null): x is T {
  return Boolean(x)
}
