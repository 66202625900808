import React from 'react'
import { SelectField, SelectFieldProps } from './SelectField'
import { components } from 'react-select'
import { Box, Flex } from '@chakra-ui/react'

const ColorSquare = ({ value }: { value: string }) => {
  return <Box backgroundColor={value} borderRadius={4} w={6} h={6} mr={2} />
}

export const ColorSelectField = (props: SelectFieldProps) => {
  return (
    <SelectField
      SelectProps={{
        components: {
          SingleValue: (props) => (
            <Flex>
              <ColorSquare value={props.data.value} />
              <Box>
                <components.SingleValue {...props} />
              </Box>
            </Flex>
          ),
          Option: (props) => (
            <components.Option {...props}>
              <Flex>
                <ColorSquare value={props.data.value} />
                <Box>{props.data.label}</Box>
              </Flex>
            </components.Option>
          ),
        },
      }}
      {...props}
    />
  )
}
