import { deleteAuthorizationEventResolution } from 'libs/api'
import { createDeleteButton } from '../../../../components'
import translations from './AuthorizationEventResolutionDeleteButton.i18n.json'

export const AuthorizationEventResolutionDeleteButton = createDeleteButton({
  translations,
  onConfirm: deleteAuthorizationEventResolution,
  getName: (user) => user.name,
  subject: 'authorization_event_resolutions',
})
