import { API } from 'aws-amplify'
import { ListResponse, OrderParams, PaginationSearchParams } from './types'
import { Attachment, GeocodingFeature } from 'types'
import { pickBy } from 'lodash-es'
import { sub } from 'date-fns'
import { getMemoizedLabels, Label } from './labels'
import { getNextCursor } from './utils'

export enum ScreeningStatus {
  APPROVED = 'approved',
  NOT_APPROVED = 'not_approved',
}

export type EventsFilters = {
  event_type?: string
  status?: string[]
  subject_id?: string
  subject_type?: string
  source_id?: string
  ignore_event_type?: string
  screening?: ScreeningStatus
}

export type EventsDateFilters = {
  start_date: Date
  end_date?: Date
}

type FetchEventsParams = PaginationSearchParams & EventsFilters & Partial<EventsDateFilters> & OrderParams

export interface EventDetailMatch {
  boundingBox: { height: number; left: number; top: number; width: number }
  croppedImageUrl: string
  similarity: number
  sourceImageUrl: string
  timestamp: string // date string
}

export type EventDetailAttributes = {
  matches: EventDetailMatch[]
  attachments?: Attachment[]
  similarity: number
  screening?: ScreeningStatus
  // add attributes here when used
}

export type EventDetail = {
  event_id: string
  name: string
  description: string
  notes?: string
  timestamp: string
  event_type: string
  source: {
    id: string
    type: string
    name: string
    place_name: string
  }
  subject: {
    id: string
    type: string // 'thing' or 'person'
    labels?: Label[]
    color?: string
    first_name?: string
    last_name?: string
    image_url?: string
    email?: string
  }
  attributes?: EventDetailAttributes
  lat: number
  lng: number
  address?: Pick<GeocodingFeature, 'place_name' | 'place_type'>
  status: string
}

type APIEventDetail = Omit<EventDetail, 'subject'> & {
  subject: Omit<EventDetail['subject'], 'labels'> & {
    labels?: string[]
  }
}
// TODO Update API and remove this transformation
export const transformApiEvent = (labelsMap: Record<string, Label>) => (event: APIEventDetail): EventDetail => ({
  ...event,
  subject: {
    ...event.subject,
    labels: event.subject.labels?.map((category_id) => labelsMap[category_id]).filter(Boolean),
  },
})

async function apiGetEvents({
  search,
  offset = 0,
  limit = 10,
  status,
  event_type,
  ignore_event_type,
  subject_id,
  subject_type,
  source_id,
  screening,
  start_date,
  end_date,
  orderBy,
  orderDirection,
  responseFormat,
}: FetchEventsParams & { responseFormat?: 'list' }) {
  return API.get('events', '/events', {
    queryStringParameters: {
      responseFormat,
      offset,
      limit,
      ...pickBy({
        search,
        event_type,
        ignore_event_type,
        subject_id,
        subject_type,
        source_id,
        screening,
        start_date: start_date?.toISOString(),
        end_date: end_date?.toISOString(),
        orderBy,
        orderDirection,
      }),
      ...pickBy({ status }, 'length'),
    },
  })
}

// TODO: Remove this and use fetchEventsList everywhere
export async function fetchEvents(params: FetchEventsParams): Promise<EventDetail[]> {
  const [labelsMap, apiEvents] = await Promise.all([getMemoizedLabels(), apiGetEvents(params)])

  return apiEvents.map(transformApiEvent(labelsMap))
}

export async function fetchEventsList(
  params: FetchEventsParams
): Promise<ListResponse<EventDetail, FetchEventsParams>> {
  const [labelsMap, eventsResponse] = await Promise.all([
    getMemoizedLabels(),
    apiGetEvents({ ...params, responseFormat: 'list' }),
  ])

  const { items } = eventsResponse

  return {
    ...eventsResponse,
    items: items.map(transformApiEvent(labelsMap)),
    nextCursor: getNextCursor(eventsResponse, params),
  }
}

export interface EventsChartItem {
  timestamp: string
  count: number
}

export type EventsChartFilters = Pick<
  EventsFilters,
  'event_type' | 'ignore_event_type' | 'subject_type' | 'source_id' | 'subject_id'
>

type FetchEventsChartParams = EventsChartFilters &
  EventsDateFilters & {
    group_duration: Duration
  }

export async function fetchEventsChart({
  start_date,
  end_date,
  group_duration,
  event_type,
  ignore_event_type,
  subject_type,
}: FetchEventsChartParams): Promise<EventsChartItem[]> {
  const date = new Date()
  return await API.get('events', '/events/chart', {
    queryStringParameters: {
      start_date: start_date.toISOString(),
      end_date: end_date?.toISOString(),
      group_duration: (date.getTime() - sub(date, group_duration).getTime()) / 1000,
      ...pickBy({
        event_type,
        ignore_event_type,
        subject_type,
      }),
    },
  })
}

type FetchEventParams = { id: string }

export async function fetchEvent({ id }: FetchEventParams): Promise<EventDetail> {
  const [labelsMap, eventDetail] = await Promise.all([getMemoizedLabels(), API.get('events', `/events/${id}`, {})])

  return transformApiEvent(labelsMap)(eventDetail)
}

export const deleteEvent = async (event: EventDetail): Promise<void> => {
  return API.del('events', `/events/${event.event_id}`, {})
}

export type UpdateEventPayload = Partial<EventDetail>

export const updateEvent = async (event: EventDetail, payload: UpdateEventPayload) => {
  return API.put('events', `/events/${event.event_id}`, {
    body: payload,
  })
}

interface CreateEventPayload {
  // not final
  name: string
  description: string
  event_type: string
  attributes?: Partial<EventDetailAttributes>
  location?: Partial<GeocodingFeature>
  status: string
  source_id: string
  source_type: string
  subject_id: string
  subject_type: string
}

export const createEvent = async (payload: CreateEventPayload) => {
  return API.post('events', '/events/', {
    body: payload,
  })
}

type EventsHealthInfo = {
  total: number
  pending: number
  approved: number
  not_approved: number
}

export async function fetchEventsHealthInfo({ start_date, end_date }: EventsDateFilters): Promise<EventsHealthInfo> {
  return await API.get('events', '/events/health-info', {
    queryStringParameters: {
      start_date: start_date.toISOString(),
      end_date: end_date?.toISOString(),
    },
  })
}
