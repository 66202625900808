import { createIcon } from '@chakra-ui/icon'

export const DashboardIcon = createIcon({
  displayName: 'DashboardIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7917 35.2083H8.125V8.125H29.7917V35.2083ZM29.7917 56.875H8.125V40.625H29.7917V56.875ZM35.2083 56.875H56.875V29.7917H35.2083V56.875ZM35.2083 24.375V8.125H56.875V24.375H35.2083Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 65 65',
})
