import { useNotify } from 'hooks/useNotify'
import { useRealTimeUpdates, RealTimeUpdatesProps } from './useRealTimeUpdates'
import { useIntl } from 'react-intl'
import translations from './messages.i18n.json'
import { useAuthContext } from 'hooks/useAuthContext'

export type ForItemProps<T> = Omit<RealTimeUpdatesProps<T>, 'onMessage'> & {
  itemName: string
  getName?: (item: T) => string
  refreshAction: () => Promise<any>
  deletedAction: () => void
}

const messages = {
  create: translations.itemWasAddedByAnotherUser,
  delete: translations.itemWasDeletedByAnotherUser,
  update: translations.itemWasUpdatedByAnotherUser,
}

export const useRealTimeUpdatesForItem = <T>({
  itemName,
  getName = (item: any) => item.name,
  refreshAction,
  deletedAction,
  ...otherProps
}: ForItemProps<T>) => {
  const intl = useIntl()
  const notify = useNotify()
  const { currentAuthenticatedUser } = useAuthContext()

  return useRealTimeUpdates<T>({
    ...otherProps,
    onMessage: ({ eventName, data, userId }) => {
      if (userId === currentAuthenticatedUser?.attributes.sub) return

      const description = intl.formatMessage(messages[eventName], {
        itemName,
        name: getName(data),
      })

      switch (eventName) {
        case 'delete':
          notify.notifyWithActions({ description, duration: 5000, status: 'error' }, [
            {
              title: intl.formatMessage(translations.back),
              onClick: deletedAction,
            },
          ])
          break
        case 'update':
          notify.notifyWithActions({ description, duration: 5000 }, [
            {
              title: intl.formatMessage(translations.refresh),
              onClick: refreshAction,
            },
          ])
          break
      }
    },
  })
}
