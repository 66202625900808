import React, { ReactNode } from 'react'
import { Box, Flex, Icon, useRadioGroupContext } from '@chakra-ui/react'
import { RadioActiveIcon, RadioInactiveIcon } from './icons'

type CustomRadioProps = {
  value: string
  children: ReactNode
}

const styles = {
  cursor: 'pointer',
  outline: 'none',
}

export const Radio = ({ children, value }: CustomRadioProps) => {
  const { value: groupValue, onChange } = useRadioGroupContext()
  return (
    <Flex mb={4} sx={styles} onClick={() => onChange(value)}>
      <Icon mr={2} as={value === groupValue ? RadioActiveIcon : RadioInactiveIcon} boxSize="18px" />
      <Box>{children}</Box>
    </Flex>
  )
}
