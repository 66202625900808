import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ThumbsDownIcon = createIcon({
  displayName: 'ThumbsDownIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.667 10V7.068l2.46-5.733h8.207v8.547l-4.78 4.786-1.094-1.08.747-3.586H.667zm14.667-8.666h-2.667v8h2.667v-8z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
})
