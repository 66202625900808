import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Flex, Text } from '@chakra-ui/react'
import { SubmitButton, TextField, CancelButton, TextareaField, SelectField } from 'components/form'
import { createDeviceType, CreateDeviceTypePayload, DeviceType, updateDeviceType } from 'libs/api'
import { useNotify, useTranslations, useValidation } from 'hooks'
import translations from '../Actions.i18n.json'
import { PhotoUploadField } from 'components/form/PhotoUploadField'

interface Props {
  item?: DeviceType
  onSuccess: () => void
  onCancel: () => void
}

export const UpsertDeviceType: React.FC<Props> = ({ onSuccess, onCancel, item }) => {
  const t = useTranslations(translations)
  const snackbar = useNotify()

  const validationSchema = useValidation((rules) =>
    rules.object({
      name: rules.string().required().label(t.deviceTypeNameLabel),
      description: rules.string().label(t.descriptionLabel),
    })
  )

  const onSubmit = async (values: CreateDeviceTypePayload) => {
    try {
      await (item ? updateDeviceType(item, values) : createDeviceType(values))
      snackbar.success(item ? t.editSuccess : t.createSuccess)
      onSuccess()
    } catch (e) {
      snackbar.error()
    }
  }

  const initialValues = item ?? {
    name: '',
    description: '',
  }

  const deviceKindOptions = useMemo(
    () => [
      { label: t.camera, value: 'camera' },
      { label: t.turnstile, value: 'turnstile' },
      { label: t.barcodeScanner, value: 'barcode-scanner' },
      { label: t.mainUnit, value: 'main-unit' },
      { label: t.doorController, value: 'door-controller' },
    ],
    [t]
  )

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Text>{t.createDescription}</Text>

        <Flex direction="column" mt={6}>
          <TextField name="name" label={t.deviceTypeNameLabel} placeholder={t.deviceTypeNamePlaceholder} mb={6} />

          <TextareaField name="description" label={t.descriptionLabel} placeholder={t.descriptionPlaceholder} mb={6} />

          <SelectField
            name="kind"
            label={t.kindLabel}
            placeholder={t.descriptionPlaceholder}
            mb={6}
            options={deviceKindOptions}
          />

          <PhotoUploadField name="image_url" label={t.photoUpload} />

          <Flex flexDirection="row-reverse" mt={10}>
            <SubmitButton>{item ? t.updateButton : t.createButton}</SubmitButton>
            <CancelButton onClick={onCancel} mr={4} />
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}
