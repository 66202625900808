import { ToastAction } from 'components/ToastWithActions'
import { useHistory } from 'react-router-dom'
import { useNotify } from 'hooks/useNotify'
import { useRealTimeUpdates, RealTimeUpdatesProps } from './useRealTimeUpdates'
import { useIntl } from 'react-intl'
import translations from './messages.i18n.json'
import { useAuthContext } from 'hooks/useAuthContext'

type ForListProps<T> = Omit<RealTimeUpdatesProps<T>, 'onMessage'> & {
  itemName: string
  getName?: (item: T) => string
  getRoute?: (item: T) => string
  refreshAction: () => Promise<any>
}

const messages = {
  create: translations.itemWasAddedByAnotherUser,
  delete: translations.itemWasDeletedByAnotherUser,
  update: translations.itemWasUpdatedByAnotherUser,
}

export const useRealTimeUpdatesForList = <T>({
  itemName,
  getName = (item: any) => item.name,
  getRoute,
  refreshAction,
  ...otherProps
}: ForListProps<T>) => {
  const intl = useIntl()
  const history = useHistory()
  const notify = useNotify()
  const { currentAuthenticatedUser } = useAuthContext()

  return useRealTimeUpdates({
    ...otherProps,
    onMessage: ({ eventName, data, userId }) => {
      if (userId === currentAuthenticatedUser?.attributes.sub) return

      const actions: ToastAction[] = [
        {
          title: intl.formatMessage(translations.refresh),
          onClick: refreshAction,
        },
      ]
      if (getRoute && eventName !== 'delete') {
        actions.push({
          title: intl.formatMessage(translations.open),
          onClick: () => history.push(getRoute(data)),
        })
      }

      const description = intl.formatMessage(messages[eventName], {
        itemName,
        name: getName(data),
      })
      notify.notifyWithActions({ description, duration: 5000 }, actions)
    },
  })
}
