import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const MailOutlineIcon = createIcon({
  displayName: 'MailOutlineIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 4H2.01L2 20H22V4ZM4 8L12 13L20 8V18H4V8ZM4 6L12 11L20 6H4Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
