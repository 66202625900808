import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Box } from 'components'
import { BoxProps, CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import translations from './PhotoUpload.i18n.json'
import { useTranslations, useNotify, useAuthCredentials } from 'hooks'
import { Label } from 'components/core/Label'
import { fileToAttachment } from 'utils/form'
import { signUrl } from 'utils/aws/util'
import { Button } from 'components'
import { UploadArea } from 'components/UploadArea'
import { DropzoneOptions } from 'react-dropzone'

const overlayStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

type Props = BoxProps & {
  label?: string
  placeholder?: string
  value?: string
  options?: DropzoneOptions
  onURL?: (url: string, setSelectedImageURL: Dispatch<SetStateAction<string | undefined>>) => void
}

export function PhotoUpload({ label, placeholder, onURL, value, options, ...boxProps }: Props) {
  const t = useTranslations(translations)
  const credentials = useAuthCredentials()
  const notify = useNotify()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [selectedImageURL, setSelectedImageURL] = useState(value ? signUrl(value, credentials) : undefined)

  const handleDropRejected = useCallback(([file]) => {
    const messages = file.errors.map((error: any) => error.message).join()
    notify.error(messages || t.invalidFile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDropAccepted = useCallback(
    async ([file]) => {
      setIsUploading(true)
      setSelectedImageURL(URL.createObjectURL(file))
      try {
        const attachment = await fileToAttachment(file, (progressEvent) => {
          setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
        })
        if (attachment) {
          onURL?.(attachment.url, setSelectedImageURL)
        }
      } catch (e) {
        throw e
      } finally {
        setIsUploading(false)
      }
    },
    [setSelectedImageURL, onURL]
  )

  function handleChangeClick() {
    setSelectedImageURL(undefined)
  }

  return (
    <Box {...boxProps}>
      {label && (
        <Label fontSize="xs" shade={1} mb={1}>
          {label}
        </Label>
      )}
      <Box>
        {selectedImageURL ? (
          <Box
            width="118px"
            height="118px"
            bgShade={1}
            backgroundImage={`url("${selectedImageURL}")`}
            backgroundSize="cover"
            backgroundPosition="center"
          >
            {isUploading ? (
              <>
                <Box {...overlayStyles} style={{ backdropFilter: `grayscale(100%) brightness(0.2)` }}>
                  <CircularProgress value={uploadProgress} color="primary">
                    <CircularProgressLabel>{uploadProgress}%</CircularProgressLabel>
                  </CircularProgress>
                </Box>
              </>
            ) : (
              <Box {...overlayStyles} opacity={0} _hover={{ opacity: 1 }} bg="rgba(0,0,0,0.8)">
                <Button onClick={handleChangeClick} variant="outline" fontSize="xs" px={4}>
                  {t.change}
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <UploadArea
            accept="image/*"
            multiple={false}
            onDropAccepted={handleDropAccepted}
            onDropRejected={handleDropRejected}
            placeholder={placeholder}
            {...options}
          />
        )}
      </Box>
    </Box>
  )
}
