import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const CheckboxIcon = createIcon({
  displayName: 'CheckboxIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H21V21H3V3ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
