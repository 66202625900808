import React, { SVGProps } from 'react'

type Props = SVGProps<any> & {
  colorMode?: 'light' | 'dark'
}

export const LogoIconOnly = ({ colorMode, ...props }: Props) => (
  <svg
    width="18"
    height="30"
    viewBox="0 0 18 30"
    color={colorMode === 'light' ? '#0C73FF' : '#fff'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2365 0C6.82247 0 0 6.71493 0 14.9996C0 23.2843 6.82247 30 15.2365 30C15.8256 30 16.3904 29.7697 16.8069 29.3596C17.2234 28.9496 17.4574 28.3935 17.4574 27.8137V2.18632C17.4574 1.60647 17.2234 1.05037 16.8069 0.640359C16.3904 0.230344 15.8256 0 15.2365 0V0ZM8.7287 18.5342C8.01859 18.5342 7.32442 18.3269 6.73399 17.9385C6.14355 17.5501 5.68337 16.9981 5.41162 16.3523C5.13987 15.7064 5.06877 14.9957 5.2073 14.3101C5.34584 13.6244 5.68779 12.9946 6.18992 12.5003C6.69204 12.006 7.33178 11.6694 8.02825 11.533C8.72472 11.3966 9.44662 11.4666 10.1027 11.7341C10.7587 12.0017 11.3195 12.4547 11.714 13.0359C12.1085 13.6172 12.3191 14.3006 12.3191 14.9996C12.3191 15.9371 11.9408 16.8361 11.2675 17.4989C10.5942 18.1618 9.68093 18.5342 8.7287 18.5342Z"
      fill="currentColor"
    />
  </svg>
)
