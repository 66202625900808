export const colors = {
  // 500 indicates it is the main color (e.g. for Button colorScheme)
  // other keys are based on Figma
  dark: {
    0: '#001432',
    1: '#33435B',
    2: '#4C5A6F',
    3: '#8F90A6',
    4: '#C7C9D9',
  },
  light: {
    0: '#E4E4EB',
    1: '#EBEBF0',
    2: '#F2F2F5',
    3: '#F8FAFB',
    4: '#fff',
  },
  primary: {
    200: '#0D73FF', // dark mode bg
    500: '#0D73FF',
    600: '#6EABFF', // button hover bg, same as 'lighter'
    700: '#084DAB', // button active bg, same as 'darker'
    800: '#00ff00',
    main: '#0D73FF',
    darker: '#084DAB',
    lighter: '#6EABFF',
    subtle: '#CFE3FF',
  },
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  blue: {
    500: '#004FC4',
    0: '#004FC4',
    1: '#0063F7',
    2: '#5B8DEF',
    3: '#9DBFF9',
    4: '#E5F0FF',
  },
  green: {
    0: '#05A660',
    1: '#06C270',
    2: '#39d98a',
    3: '#57EBA1',
    4: '#e3fff1',
    500: '#39D98A',
  },
  yellow: {
    0: '#E6B800',
    1: '#FFCC00',
    2: '#FDDD48',
    3: '#FDED72',
    4: '#FFFEE6',
  },
  orange: {
    0: '#E67A00',
    1: '#FF8800',
    2: '#FDAC42',
    3: '#FCCC75',
    4: '#FFF8E6',
  },
  teal: {
    0: '#00B7C4',
    1: '#00CFDE',
    2: '#73DFE7',
    3: '#A9EFF2',
    4: '#E6FFFF',
  },
  red: {
    0: '#E53535',
    1: '#FF3B3B',
    2: '#FF5C5C',
    3: '#FF8080',
    4: '#FFE6E6',
    500: '#FF3B3B',
  },
  purple: {
    0: '#4D0099',
    1: '#6600CC',
    2: '#AC5DD9',
    3: '#DDA5E9',
    4: '#FFE6FF',
  },
}
