import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Stack } from 'components/index'
import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  InputProps as InputPropsType,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import geocodingService, { GeocodeService } from '@mapbox/mapbox-sdk/services/geocoding'
import { useField } from 'formik'
import { useDebounce } from 'use-debounce'
import { FormLabel } from 'components/form/FormLabel'
import { useBorderColor } from 'components/form/hooks/useBorderColor'
import { SearchIcon } from '@chakra-ui/icons'
import { CloseIcon } from 'components/icons'
import OutsideClickHandler from 'react-outside-click-handler'

const mbxClient = require('@mapbox/mapbox-sdk')

export type GeocoderSearchResult = {
  place_name: string
  description: string | React.ComponentType
  text: string
  center: [number, number]
  place_type: string[]
  image_url?: string
  color?: string
}

type Props = {
  placeholder?: string
  name: string
  label?: string
  id?: string
  InputProps?: InputPropsType
  mapboxToken?: string
  additionalSearch?: (query: string) => Promise<GeocoderSearchResult[]>
  onSuggestionClick?: (item: GeocoderSearchResult) => void
  renderSuggestion?: (item: GeocoderSearchResult) => ReactNode
  shouldSuggestInitially?: boolean
} & BoxProps

export function GeocoderField({
  onChange,
  placeholder,
  name,
  id,
  label,
  InputProps,
  mapboxToken,
  additionalSearch,
  onSuggestionClick,
  renderSuggestion,
  shouldSuggestInitially,
  ...boxProps
}: Props) {
  const [field, meta, helpers] = useField(name)
  const [rawValue, setRawValue] = useState(field?.value?.place_name || '')
  const [debouncedSearch, cancelDebounce] = useDebounce(rawValue, 300)
  const [suggestions, setSuggestions] = useState<GeocoderSearchResult[]>()
  const [isSearching, setIsSearching] = useState(false)
  const isTouched = useRef(false)
  const { colorMode } = useColorMode()
  const hoverBg = useColorModeValue('light.3', 'gray.700')

  if (shouldSuggestInitially) {
    isTouched.current = true
  }

  const geocodingClient = useMemo<GeocodeService | undefined>(() => {
    try {
      const baseClient = mbxClient({ accessToken: mapboxToken || process.env.REACT_APP_MAPBOX_TOKEN })
      return geocodingService(baseClient)
    } catch (e) {
      console.error(e)
    }
  }, [mapboxToken])

  function handleSuggestionClick(feature: GeocoderSearchResult) {
    onSuggestionClick?.(feature)
    setRawValue(feature.place_name)
    helpers.setValue(feature)
    setSuggestions(undefined)
    setTimeout(cancelDebounce) // this prevents suggestions from showing up again 300ms after clicking
  }

  useEffect(() => {
    if (!rawValue) {
      helpers.setValue('')
      setSuggestions(undefined)
    }
  }, [rawValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!field.value) setRawValue('')
  }, [field.value])

  useEffect(() => {
    if (debouncedSearch && isTouched.current) {
      setIsSearching(true)
      const promises = []
      if (additionalSearch) {
        promises.push(additionalSearch(debouncedSearch))
      }
      promises.push(
        geocodingClient
          ?.forwardGeocode({ query: debouncedSearch, mode: 'mapbox.places' })
          .send()
          .then((response) => response.body.features)
      )

      Promise.all(promises)
        .then((results) => setSuggestions(results.flat()))
        .finally(() => setIsSearching(false))
    }
  }, [geocodingClient, debouncedSearch, additionalSearch])

  function handleInputChange(e: any) {
    setRawValue(e.target.value)
    isTouched.current = true
  }

  return (
    <Box position="relative" {...boxProps}>
      <OutsideClickHandler onOutsideClick={() => setSuggestions(undefined)}>
        <FormControl isInvalid={!!meta.error && meta.touched}>
          {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
          <InputGroup>
            <InputLeftElement h="100%">
              <SearchIcon color="dark.3" />
            </InputLeftElement>
            <Input
              isDisabled={!geocodingClient}
              id={id}
              placeholder={placeholder}
              fontSize="sm"
              borderRadius={0}
              border="none"
              borderColor={useBorderColor(field)}
              py="22px"
              boxShadow={colorMode === 'light' ? 'light.1' : 'dark.1'}
              {...field}
              value={rawValue}
              onChange={handleInputChange}
              autoComplete="off"
              pr={rawValue ? 8 : 0}
              {...InputProps}
            />
            {(rawValue || isSearching) && (
              <InputRightElement h="100%">
                {isSearching ? (
                  <Box>
                    <Spinner color="dark.3" size="sm" />
                  </Box>
                ) : (
                  <CloseIcon aria-label={'clear'} cursor="pointer" onClick={() => setRawValue('')} />
                )}
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
        {suggestions && (
          <Box position="absolute" left={0} right={0} zIndex={10} bgShade={1} mt="1px">
            <Stack spacing="1px" boxShadow={colorMode === 'light' ? 'light.6' : 'dark.6'}>
              {suggestions.map((feature) => (
                <Box
                  key={feature.place_name}
                  p={4}
                  cursor="pointer"
                  shade={1}
                  bgShade={4}
                  onClick={() => handleSuggestionClick(feature)}
                  _hover={{ bg: hoverBg }}
                >
                  {renderSuggestion?.(feature) || feature.place_name}
                </Box>
              ))}
            </Stack>
          </Box>
        )}
      </OutsideClickHandler>
    </Box>
  )
}
