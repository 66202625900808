import React from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderProps,
} from '@chakra-ui/react'
import { FormLabel } from './FormLabel'

export type SliderFieldProps = {
  label?: string
  name: string
  SliderProps?: SliderProps
} & FormControlProps

export const SliderField = ({ label, name, SliderProps, ...props }: SliderFieldProps) => {
  const [field, meta, helpers] = useField(name)
  const { value } = field

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      {label && <FormLabel>{label}</FormLabel>}
      <Box>
        <Slider
          {...field}
          {...SliderProps}
          value={Number(value)}
          onChange={(value) => {
            helpers.setValue(`${value}`)
          }}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb boxSize={6} fontWeight="bold" fontSize={10}>
            {value}
          </SliderThumb>
        </Slider>
      </Box>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
