import React, { useState, useCallback } from 'react'
import { Icon } from '@chakra-ui/react'
import { TextField, TextFieldProps } from './TextField'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

export const PasswordField = (props: Omit<TextFieldProps, 'type'>) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisible = useCallback(() => {
    setPasswordVisible(!passwordVisible)
  }, [passwordVisible])
  return (
    <TextField
      type={passwordVisible ? 'text' : 'password'}
      inputRightElement={
        <Icon
          boxSize="20px"
          marginRight="6px"
          as={passwordVisible ? ViewIcon : ViewOffIcon}
          color="dark.3"
          onClick={togglePasswordVisible}
          cursor="pointer"
        />
      }
      {...props}
    />
  )
}
