import { Auth } from 'aws-amplify'

export const useLogout = () => async () => {
  try {
    await Auth.signOut()
    window.location.reload()
  } catch (e) {
    alert(e)
  }
}
