import { useCallback, useEffect, useState } from 'react'

export function useMediaDevices(kind: MediaDeviceInfo['kind']) {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(kind ? mediaDevices.filter((it) => it.kind === kind) : mediaDevices),
    [setDevices, kind]
  )

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  return devices
}
