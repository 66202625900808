import { API } from 'aws-amplify'
import { TransitEvent } from './authorizationEvents'
import { DeviceItem } from './devices'
import { ListResponse } from './types'
import { Zone } from './zones'

export type DeviceGroupDevice = Pick<DeviceItem, 'thing_id' | 'name'> & { direction: DeviceDirection }

export type DeviceGroup = {
  device_group_id: string
  created_at: string
  name: string
  zone_id?: string | null
  updated_at: string
  zone?: Zone
  devices: DeviceGroupDevice[]
  last_transit_event?: TransitEvent | null
  managing_device_id?: string | null
  managing_device?: DeviceItem | null
  device_group_type: 'lane' | 'door'
  status: 'free_transit' | 'secure' | 'locked'
}

export type DeviceGroupFormValues = Pick<DeviceGroup, 'name' | 'zone_id' | 'device_group_type'>

export type CreateDeviceGroupPayload = DeviceGroupFormValues

export async function getDeviceGroupsList(): Promise<ListResponse<DeviceGroup>> {
  return API.get('device-groups', '/device-groups', {
    queryStringParameters: {
      responseFormat: 'list',
    },
  })
}

export async function getDeviceGroups(params: any): Promise<DeviceGroup[]> {
  return API.get('device-groups', '/device-groups', { queryStringParameters: params })
}

export async function fetchDeviceGroup({ id }: { id: number | string }): Promise<DeviceGroup> {
  return API.get('device-groups', `/device-groups/${id}`, {})
}

export const deleteDeviceGroup = async (deviceGroup: DeviceGroup): Promise<void> => {
  return API.del('device-groups', `/device-groups/${deviceGroup.device_group_id}`, {})
}

export async function createDeviceGroup(body: CreateDeviceGroupPayload): Promise<DeviceGroup> {
  return API.post('device-groups', `/device-groups`, { body })
}

export async function updateDeviceGroup(deviceGroup: DeviceGroup, payload: Partial<DeviceGroup>): Promise<DeviceGroup> {
  return API.put('device-groups', `/device-groups/${deviceGroup.device_group_id}`, { body: payload })
}

export type DeviceDirection = 'secure' | 'unsecure' | 'both'

export type updateDeviceGroupDevicesPayload = {
  devices: Array<{ device_id: string; direction: DeviceDirection }>
}

export async function updateDeviceGroupDevices(
  deviceGroupId: string,
  body: updateDeviceGroupDevicesPayload
): Promise<any> {
  return API.put('device-groups', `/device-groups/${deviceGroupId}/devices`, { body })
}

export async function lockDeviceGroup(id: string): Promise<void> {
  return Promise.reject('not implemented')
}
