import * as Yup from 'yup'
import translations from './messages.i18n.json'
import { useTranslations } from 'hooks/useTranslations'

// const twitterAccountRegex = /^[a-zA-Z0-9_]{1,15}$/
// const facebookAccountRegex = /^\w[\w.]+$/
// const youtubeAccountRegex = /^\w[\w.-]*$/
// const genericAccountRegex = /^\w+$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,512}$/
// const hasInvalidCharacter = /^[a-zA-Z '.-]*$/

// const emptyString = (cv: any, ov: any) => (ov === '' ? null : cv)

export const useValidationRules = () => {
  const t = useTranslations(translations)

  const password = (message = t.invalidPassword) =>
    Yup.string().matches(passwordRegex, { message, excludeEmptyString: true }).label(t.passwordLabel)

  const confirmPassword = (refName: any, message = t.passwordMismatch) =>
    Yup.string()
      .oneOf([Yup.ref(refName), null], message)
      .label(t.confirmPasswordLabel)

  const email = (msg = t.invalidEmail) => Yup.string().email(msg)

  const cognitoPhoneNumber = () =>
    Yup.string()
      .matches(/^\+\d{7,}$/, t.incorrectPhoneNumber)
      .label(t.phoneNumberLabel)

  const badgeNumber = () => Yup.number() //.min(1_000_000).max(15_999_999)

  // const currentYear = new Date().getFullYear()

  // const year = Yup.number()
  //   .positive()
  //   .integer()
  //   .min(currentYear - 100, t.incorrectYear)
  //   .max(currentYear - 14, t.incorrectYear)
  //   .nullable()
  //   .label(t.yearLabel)
  //   .transform(emptyString)

  // const day = Yup.number()
  //   .positive()
  //   .integer()
  //   .min(1, t.incorrectDay)
  //   .max(31, t.incorrectDay)
  //   .nullable()
  //   .label(t.dayLabel)
  //   .transform(emptyString)

  // const month = Yup.number().positive().integer().nullable().label(t.monthLabel).transform(emptyString)

  // const loosePhoneNumber = Yup.string()
  //   .min(7, t.incorrectPhoneNumber)
  //   .label(t.phoneNumberLabel)
  //   .transform((input) => input.replace(/\D/g, ''))

  // const hereLoc = Yup.object({
  //   lon: Yup.number().nullable(true),
  //   lat: Yup.number().nullable(true),
  //   addr: Yup.string().nullable(true),
  //   building: Yup.object({
  //     floorId: Yup.string().nullable(true),
  //     buildingId: Yup.string().nullable(true),
  //     buildingName: Yup.string().nullable(true),
  //   }).nullable(false),
  // })

  return {
    ...Yup,
    confirmPassword,
    email,
    password,
    cognitoPhoneNumber,
    badgeNumber,
  }
}
