import { ChevronDownIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuItem, MenuList, Button } from '@chakra-ui/react'
import { Box } from 'components/core'
import { useAuthContext, useQuery } from 'hooks'
import { fetchCurrentUserRoles } from 'libs/api'

export function CurrentRoleDropdown() {
  const { currentRole, setCurrentRole } = useAuthContext()
  const { data: userRoles } = useQuery(fetchCurrentUserRoles, {})

  return (
    <Box zIndex={10}>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon color="dark.2" boxSize="20px" />}
          bgColor="transparent"
          color="primary.main"
          fontSize="sm"
        >
          {currentRole?.name}
        </MenuButton>
        <MenuList>
          {userRoles?.map((role) => (
            <MenuItem key={role.role_id} fontSize="sm" fontWeight="bold" onClick={() => setCurrentRole(role)}>
              {role.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}
