import { ListPageHeader, OrderProps } from 'modules/Common/ListPage/components/ListPageHeader'
import InfiniteScroll from 'react-infinite-scroller'
import { ListPageContent } from 'modules/Common/ListPage/components/ListPageContent'
import { Table, TableBody, TableCell, TableRow } from 'components/core/Table'
import React, { ComponentType } from 'react'
import { Box, Skeleton, SkeletonProvider } from 'components'
import { parseColumns } from 'modules/Common/ListPage/helpers/parseColumns'
import { Column } from 'modules/Common/ListPage'
import { Spinner } from '@chakra-ui/react'

const GenericSkeleton = ({ rows = 10, numberOfColumns = 5 }) => (
  <TableBody>
    {Array.from({ length: rows }).map((_, index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton boxSize={10} />
        </TableCell>
        {Array.from({ length: numberOfColumns - 1 }).map((_, index) => (
          <TableCell key={index}>
            <Skeleton width="80%" height={5} />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
)

const DefaultEmptyState = () => (
  <Box mt={2} opacity={0.4}>
    No data
  </Box>
)

export type ListViewProps<T> = {
  items: T[] | undefined
  fetchNextPage: () => any
  hasNextPage?: boolean
  refetch: () => any
  orderProps?: OrderProps
  columns: Column<T>[]
  getId: (t: T) => string | number
  onItemClick?: (item: T) => void
  EmptyState?: ComponentType
  dummyItem?: T
  bg?: string
}

export function ListView<T>({
  items,
  fetchNextPage,
  hasNextPage,
  refetch,
  orderProps,
  columns: _columns,
  getId,
  onItemClick,
  EmptyState = DefaultEmptyState,
  dummyItem,
  bg,
}: ListViewProps<T>) {
  const columns = parseColumns(_columns)

  return items && items.length === 0 ? (
    <EmptyState />
  ) : (
    <Table>
      <ListPageHeader columns={columns} orderProps={orderProps} />
      {items ? (
        <InfiniteScroll
          element="tbody"
          loader={
            <tr key={0}>
              <Box as="td" p={6}>
                <Spinner />
              </Box>
            </tr>
          }
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}
        >
          <ListPageContent<T>
            columns={columns}
            items={items}
            getId={getId}
            refetch={refetch}
            onItemClick={onItemClick}
            bg={bg}
          />
        </InfiniteScroll>
      ) : (
        <SkeletonProvider isLoaded={false}>
          {dummyItem ? (
            <TableBody>
              <ListPageContent
                items={Array(10).fill(dummyItem)}
                columns={columns}
                getId={undefined}
                refetch={() => Promise.resolve()}
              />
            </TableBody>
          ) : (
            <GenericSkeleton numberOfColumns={columns.length} />
          )}
        </SkeletonProvider>
      )}
    </Table>
  )
}
