import { API } from 'aws-amplify'
import { PaginationSearchMapParams, OrderParams, ListResponse } from './types'
import { Attachment, GeocodingFeature } from 'types'
import { getNextCursor, multiValueParam } from './utils'
import { isUndefined, omitBy } from 'lodash-es'

export type DeviceLink = {
  device_link_id: string
  device_1_id: string
  device_2_id: string
  device: DeviceItem
  created_at: string
  updated_at: string
  tag: string
}

export type DeviceItem = {
  thing_id: string
  name: string
  description: string
  notes?: string
  thing_type_name: string
  thing_type_id: string
  place_name: string
  lat: number
  lng: number
  image_url: string
  color: string
  attributes?: {
    mute_notifications?: boolean
    attachments?: Attachment[]
  }
  last_event_timestamp: any
  total_count?: number
  device_string?: string
  local_ip_address?: string
  mac_address?: string
  certificate_cn?: string
  links: DeviceLink[]
  zone?: {
    name: string
  }
  device_group?: {
    name: string
  }
  config: Record<string, any> | null
  device_group_id: string
}

export type DeviceKind = 'camera' | 'edge-compute' | 'barcode-scanner' | 'metal-detector' | 'turnstile'

type FetchDevicesParams = PaginationSearchMapParams &
  OrderParams & {
    device_kind?: DeviceKind
    device_group_id?: (string | null)[]
    zone_id?: string[]
  }

export async function fetchDevicesList({
  search,
  device_kind,
  device_group_id,
  zone_id,
  offset = 0,
  limit = 10,
  bbox,
  orderBy,
  orderDirection,
}: FetchDevicesParams): Promise<ListResponse<DeviceItem, FetchDevicesParams>> {
  const queryStringParameters = {
    responseFormat: 'list',
    search,
    device_kind,
    device_group_id,
    offset,
    limit,
    bbox,
    orderBy,
    orderDirection,
    zone_id: multiValueParam(zone_id),
  }

  const response = await API.get('things', '/things', {
    queryStringParameters: omitBy(queryStringParameters, isUndefined),
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, { offset }),
  }
}

export function fetchDevice({ id }: { id: string }): Promise<DeviceItem> {
  return API.get('things', `/things/${id}`, {})
}

export type DeviceFormValues = {
  name: string
  description?: string
  color?: string
  image_url?: string
  thing_type_id?: string
  location?: GeocodingFeature
  device_string?: string
  local_ip_address?: string
  mac_address?: string
  certificate_cn?: string
}

export type DeviceLinkFormValues = {
  to_device?: DeviceItem
  tag: string
}

export function createDevice(body: DeviceFormValues): Promise<DeviceItem> {
  return API.post('things', '/things', { body })
}
export const deleteDevice = async (device: DeviceItem): Promise<void> => {
  return API.del('things', `/things/${device.thing_id}`, {})
}

type UpdateDevicePayload = DeviceFormValues | Partial<DeviceItem>

export function updateDevice(device: DeviceItem, payload: UpdateDevicePayload): Promise<DeviceItem> {
  return API.put('things', `/things/${device.thing_id}`, {
    body: payload,
  })
}

export function addDeviceLink(link: Pick<DeviceLink, 'device_1_id' | 'device_2_id' | 'tag'>): Promise<DeviceItem> {
  return API.post('things', `/things/links`, {
    body: link,
  })
}

export function updateDeviceLink({
  device_link_id,
  ...payload
}: Pick<DeviceLink, 'device_1_id' | 'device_2_id' | 'tag' | 'device_link_id'>): Promise<DeviceItem> {
  return API.put('things', `/things/links/${device_link_id}`, {
    body: payload,
  })
}

export function deleteDeviceLink({ device_link_id }: DeviceLink): Promise<DeviceItem> {
  return API.del('things', `/things/links/${device_link_id}`, {})
}

export type ImportDevicePayload = {
  thing_type_id: string
  name: string
  description?: string
  color?: string
  image_url?: string
  device_string?: string
  local_ip_address?: string
  mac_address?: string
  certificate_cn?: string
}

export function bulkAddDevices(payload: ImportDevicePayload[]): Promise<DeviceItem[]> {
  return API.post('things', '/things/bulk', { body: payload })
}
