import { ButtonProps } from '@chakra-ui/react'
import { Button } from '../core/Button'
import { useFormikContext } from 'formik'
import React from 'react'

export const SubmitButton = (props: ButtonProps) => {
  const { isSubmitting } = useFormikContext()

  return <Button type="submit" isLoading={isSubmitting} fontSize="xs" {...props} />
}
