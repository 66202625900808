import { DateRangeProps, DateRange } from './DateRange'
import { useField } from 'formik'

export type DateRangeInputProps = {
  name: string
  onChange: () => void
} & Omit<DateRangeProps, 'value'>

export function DateRangeField({ name, ...props }: DateRangeInputProps) {
  const [field, , helper] = useField(name)
  return (
    <DateRange
      {...props}
      value={field.value}
      onChange={(changes) => {
        helper.setTouched(true)
        helper.setValue(changes)
        props.onChange()
      }}
    />
  )
}
