import { Button as ChakraButton, ButtonProps, IconButton as ChakraIconButton, IconButtonProps } from '@chakra-ui/react'
import React from 'react'
import { useBgColorFromShade, useTextColorFromShade, withShade } from './utils'

export const Button = (props: ButtonProps) => {
  return (
    <ChakraButton
      borderRadius="0.2rem"
      letterSpacing="0.2px"
      boxShadow="0px 2px 4px rgba(96, 97, 112, 0.16), 0px 0px 1px rgba(40, 41, 61, 0.04)"
      variant="solid"
      colorScheme="primary"
      color="white"
      fontFamily="label"
      fontSize="sm"
      px={8}
      {...props}
    />
  )
}

export const ActionButton = (props: ButtonProps) => {
  return <Button fontSize="xs" {...props} />
}

export const ButtonLink = (props: ButtonProps) => {
  return <ChakraButton variant="link" colorScheme="primary" fontWeight="bold" fontSize="xs" {...props} />
}

export const ActionButtonLink = (props: ButtonProps) => <ButtonLink fontFamily="label" {...props} />

export const ButtonOutlined = (props: ButtonProps) => {
  const color = useTextColorFromShade(2)
  return (
    <ChakraButton
      variant="outline"
      color={color}
      borderColor="primary.main"
      fontFamily="label"
      fontSize="xs"
      px={8}
      {...props}
    />
  )
}

export const IconButton = withShade<IconButtonProps>(ChakraIconButton)

export const RoundedButton = (props: ButtonProps) => {
  const bg = useBgColorFromShade(4)
  const color = useTextColorFromShade(3)
  return <Button bg={bg} color={color} borderRadius="5em" variant="outline" border="none" {...props} />
}

export const RoundedButtonFlat = (props: ButtonProps) => (
  <RoundedButton size="sm" px={4} border="1px solid currentColor" {...props} />
)
