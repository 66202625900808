import React from 'react'
import { Box, useColorMode } from '@chakra-ui/react'
import { FiltersIcon } from './icons'

interface Props {
  color?: string
  onClick: () => void
}

export const FilterIcon: React.FC<Props> = ({ color, onClick }) => {
  const { colorMode } = useColorMode()
  const iconColor = colorMode === 'light' ? 'dark.3' : 'dark.4'
  return (
    <Box p={2} cursor="pointer" color={color ?? iconColor}>
      <FiltersIcon boxSize="24px" onClick={onClick} />
    </Box>
  )
}
