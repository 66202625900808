import { TransitEvent } from 'libs/api'

export type WsAuthorizationEvent = {
  id: string
  authorized: boolean
  badge_string: string
  device_group_id: string
  device_id: string
  device_time: string
  metadata: {
    image?: string
    image_url?: string // only present in transformed events
    bounding_box?: {
      x: number
      y: number
      width: number
      height: number
    }
  }
  note: string
  person_id: string
  resolution_id?: string
  transit_event_id?: string
  person?: any
  device?: any
  zone?: any
}

export type WsDeviceGroupStatus = 'free_transit' | 'secure' | 'locked'

export enum MessageType {
  AUTHORIZATION_EVENT = 'authorization_event',
  AUTHORIZATION_EVENT_RESOLVED = 'authorization_event_resolved',
  AUTHORIZATION_EVENT_ACKNOWLEDGED = 'authorization_event_acknowledged',
  LANE_CLEAR = 'lane_clear',
  LANE_UNLOCK = 'unlock_lane',
  LANE_LOCK = 'lock_lane',
  ZONE_UNLOCK = 'unlock_zone',
  ZONE_LOCK = 'lock_zone',
  TRANSIT_EVENT = 'transit_event',
  DEVICE_GROUP = 'device_group',
  DEVICE_GROUP_WITH_EVENTS = 'device_group_with_events',
  GET_DEVICE_GROUP = 'get_device_group',
  ALLOW_SINGLE_TURNSTILE_TRANSIT = 'allow_single_turnstile_transit',
}

export type EventResolutionPayload = {
  authorization_event_id: string
  resolution_id: string
  note: string
  resolved_by: string
}

export type LaneClearPayload = {
  device_group_id: string
}

export type EventAcknowledgmentPayload = {
  authorization_event_id: string
}

export type LanesUnlockPayload = {
  device_group_id: string
}[]

export type LanesLockPayload = {
  device_group_id: string
}[]

export type TransitEventPayload = Pick<TransitEvent, 'device_group_id' | 'person_id' | 'direction' | 'status'>

export type DeviceGroupPayload = {
  status: WsDeviceGroupStatus
  device_ids: string[]
  id: string
  name: string
  zone_id: string
  current_authorization_event_ids: string[]
}

export type DeviceGroupUpdatePayload = Pick<DeviceGroupPayload, 'id' | 'status'>

export type AuthorizationEventMessage = { type: MessageType.AUTHORIZATION_EVENT; data: WsAuthorizationEvent }
export type EventResolutionMessage = { type: MessageType.AUTHORIZATION_EVENT_RESOLVED; data: EventResolutionPayload }
export type EventAcknowledgmentMessage = {
  type: MessageType.AUTHORIZATION_EVENT_ACKNOWLEDGED
  data: EventAcknowledgmentPayload
}
export type LaneClearMessage = { type: MessageType.LANE_CLEAR; data: LaneClearPayload }
export type LanesUnlockMessage = { type: MessageType.LANE_UNLOCK; data: LanesUnlockPayload }
export type LanesLockMessage = { type: MessageType.LANE_LOCK; data: LanesLockPayload }
export type TransitEventMessage = { type: MessageType.TRANSIT_EVENT; data: TransitEventPayload }
export type DeviceGroupMessage = { type: MessageType.DEVICE_GROUP; data: DeviceGroupPayload }
export type DeviceGroupUpdateMessage = {
  type: MessageType.DEVICE_GROUP
  data: DeviceGroupUpdatePayload
}

export type Message =
  | AuthorizationEventMessage
  | EventResolutionMessage
  | EventAcknowledgmentMessage
  | LaneClearMessage
  | LanesUnlockMessage
  | LanesLockMessage
  | TransitEventMessage
  | DeviceGroupMessage

export type MessageHistory = Message[]
