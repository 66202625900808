import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const AdminIcon = createIcon({
  displayName: 'AdminIcon',
  path: (
    <g>
      <path
        key="1"
        d="M14.1667 13.8491C14.6822 13.8491 15.1001 13.4312 15.1001 12.9158C15.1001 12.4003 14.6822 11.9824 14.1667 11.9824C13.6513 11.9824 13.2334 12.4003 13.2334 12.9158C13.2334 13.4312 13.6513 13.8491 14.1667 13.8491Z"
        fill="currentColor"
      />
      <path
        key="2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1665 14.584C13.5581 14.584 12.3415 14.884 12.2998 15.484C12.7165 16.0757 13.3998 16.459 14.1665 16.459C14.9331 16.459 15.6165 16.0757 16.0331 15.484C15.9915 14.884 14.7748 14.584 14.1665 14.584Z"
        fill="currentColor"
      />
      <path
        key="3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9.24167V5.225L8.75 2.5L2.5 5.225V9.31667C2.5 13.1 5.16667 16.6417 8.75 17.5C9.20833 17.3917 9.65 17.2333 10.0833 17.0417C10.9833 18.325 12.475 19.1667 14.1667 19.1667C16.925 19.1667 19.1667 16.925 19.1667 14.1667C19.1667 11.6917 17.3667 9.64167 15 9.24167ZM9.16667 14.1667C9.16667 14.6333 9.23333 15.0917 9.35833 15.5167C9.15833 15.6083 8.95833 15.7 8.75 15.7667C6.10833 14.9333 4.16667 12.2333 4.16667 9.31667V6.31667L8.75 4.31667L13.3333 6.31667V9.24167C10.9667 9.64167 9.16667 11.6917 9.16667 14.1667ZM14.1667 17.5C12.325 17.5 10.8333 16.0083 10.8333 14.1667C10.8333 12.325 12.325 10.8333 14.1667 10.8333C16.0083 10.8333 17.5 12.325 17.5 14.1667C17.5 16.0083 16.0083 17.5 14.1667 17.5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
})
