import { createIcon } from '@chakra-ui/icon'

export const DoorsIcon = createIcon({
  displayName: 'DoorsIcon',
  path: (
    <g>
      <rect x="8" y="54" width="49" height="3" fill="currentColor" />
      <rect x="15" y="7" width="36" height="49" fill="currentColor" />
      <rect x="39" y="30" width="7" height="3" rx="1.5" fill="white" />
    </g>
  ),
  viewBox: '0 0 65 65',
})
