import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const MapViewIcon = createIcon({
  displayName: 'MapViewIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.005L15 5.10504L21 2.77502V18.975L15 20.995L9 18.895L3 21.225V5.02502L9 3.005ZM9 16.785L15 18.8951V7.22504L9 5.11505V16.785Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
