import React from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputFieldProps,
} from '@chakra-ui/react'
import { FormLabel } from './FormLabel'
import { useBorderColor } from './hooks/useBorderColor'

export type NumberFieldProps = {
  label?: string
  name: string
  placeholder?: string
  InputProps?: NumberInputFieldProps
  noStepper?: boolean
} & FormControlProps

export const NumberField = ({ label, name, id, placeholder, InputProps, noStepper, ...props }: NumberFieldProps) => {
  const [field, meta, helper] = useField(name)
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <NumberInput value={field.value} onChange={helper.setValue} onBlur={() => helper.setTouched(true)}>
        <NumberInputField
          id={id}
          placeholder={placeholder}
          fontSize="sm"
          borderRadius={0}
          borderColor={useBorderColor(field)}
          py="22px"
          {...InputProps}
        />
        {!noStepper && (
          <NumberInputStepper py={1} mr={2}>
            <NumberIncrementStepper border="none" fontSize={8} />
            <NumberDecrementStepper border="none" fontSize={8} />
          </NumberInputStepper>
        )}
      </NumberInput>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
