import { ColorMode } from '@chakra-ui/react'

export default function mapboxGlobalStyles({ colorMode }: { colorMode: ColorMode }) {
  const borderColor = colorMode === 'light' ? `light.3` : '#353742'
  return {
    body: {
      '.mapboxgl-popup-anchor-bottom, .mapboxgl-popup-anchor-bottom-right, .mapboxgl-popup-anchor-bottom-left': {
        '.mapboxgl-popup-tip': {
          borderTopColor: borderColor,
        },
      },
      '.mapboxgl-popup-anchor-top, .mapboxgl-popup-anchor-top-right, .mapboxgl-popup-anchor-top-left': {
        '.mapboxgl-popup-tip': {
          borderBottomColor: borderColor,
        },
      },
      '.mapboxgl-popup-anchor-right .mapboxgl-popup-tip': {
        borderLeftColor: borderColor,
      },
      '.mapboxgl-popup-anchor-left .mapboxgl-popup-tip': {
        borderRightColor: borderColor,
      },
      '.mapboxgl-popup-content': {
        background: colorMode === 'light' ? 'light.3' : 'gray.800',
        boxShadow: colorMode === 'light' ? '0 1px 2px rgba(0,0,0,.1)' : '0 0 25px 10px rgba(0, 0, 0, 0.5)',
        borderStyle: 'solid',
        borderWidth: colorMode === 'dark' ? 1 : 0,
        borderColor: `${borderColor}`,
      },
    },
  }
}
