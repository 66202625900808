import React from 'react'
import {
  Tab as ChakraTab,
  useColorMode,
  useTab,
  useStyles,
  Button,
  ButtonProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import theme from '../theme'

const tabStyles = {
  borderBottom: 0,
  fontFamily: 'label',
  fontWeight: 'bold',
  // minWidth: '116px',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginRight: '2.5rem',
  px: 0,
  py: 0,
}

// tab size is not available from component
const tabSizes: Record<string, TextProps> = {
  // sm
  '0.85rem': {
    fontSize: 'sm',
    py: theme.sizes['2'],
  },
  // md
  '1rem': {
    fontSize: 'sm',
    py: theme.sizes['2'],
  },
  // lg
  '1.15rem': {
    fontSize: 'md',
    py: theme.sizes['3'],
  },
}

export const Tab = React.forwardRef<typeof ChakraTab, any>((props, ref) => {
  const tabProps = useTab(props)
  const styles = useStyles()
  const isSelected = !!tabProps['aria-selected']

  const { colorMode } = useColorMode()
  // white can be mistaken for "active" in dark mode:
  const inactiveColor = colorMode === 'dark' ? 'dark.3' : 'dark.2'
  return (
    <Button
      __css={styles.tab}
      sx={tabStyles}
      color={inactiveColor}
      _selected={{
        color: 'primary.main',
      }}
      _focus={{ outline: 'none' }}
      {...(tabProps as ButtonProps)}
    >
      <Text
        borderBottom="2px solid"
        textAlign="left"
        borderColor={isSelected ? 'primary.main' : 'transparent'}
        {...tabSizes[(styles.tab as any).fontSize]}
        position="relative"
        top="-1px"
      >
        {props.children}
      </Text>
    </Button>
  )
})
