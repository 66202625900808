import React, { useEffect, useState } from 'react'
import { useLocation, RouteComponentProps } from 'react-router-dom'

type LastLocation = RouteComponentProps['location'] | undefined

export const LastLocationContext = React.createContext<LastLocation>(undefined)

export const LastLocationProvider: React.FC = ({ children }) => {
  const [lastLocation, setLastLocation] = useState<LastLocation>()
  const location = useLocation()
  useEffect(() => {
    return () => setLastLocation(location)
  }, [location])
  return <LastLocationContext.Provider value={lastLocation}>{children}</LastLocationContext.Provider>
}
