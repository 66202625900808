import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const TimerCircleIcon = createIcon({
  displayName: 'TimerCircleIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zM11 7h1.5v5.25l4.5 2.67-.75 1.23L11 13V7z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
