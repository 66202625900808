import React from 'react'
import { Flex, BoxProps } from '@chakra-ui/react'
import { Button } from '../../core/Button'
import translations from './ErrorState.i18n.json'
import { useTranslations } from 'hooks'

type Props = BoxProps & {
  retry?: () => void
}

export default function ErrorState({ retry, ...props }: Props) {
  const t = useTranslations(translations)
  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column" {...props}>
      {t.errorOccurred} {retry && <Button onClick={retry}>{t.retry}</Button>}
    </Flex>
  )
}
