import React, { useEffect, useRef, useState } from 'react'
import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import theme from 'theme'
import { ChartValue, FocusedBar } from './types'
import { BarChartTooltip } from 'components/BarChart/BarChartTooltip'
import { useColorMode, useColorModeValue } from '@chakra-ui/react'

type Props = {
  data: Array<ChartValue>
  getTooltipLabel: (value: ChartValue) => string
  isSkeleton?: boolean
}

export function BarChart({ data, getTooltipLabel, isSkeleton }: Props) {
  const [focusedBarIndex, setFocusedBarIndex] = useState(-1)
  const [focusedBar, setFocusedBar] = useState<FocusedBar>()
  const { colorMode } = useColorMode()

  const textStyle = {
    fontSize: '12px',
    fill: colorMode === 'light' ? theme.colors.dark[2] : theme.colors.light[2],
    fontWeight: 'bold',
    fontFamily: theme.fonts.label,
  }

  const barColor = colorMode === 'light' ? theme.colors.blue[3] : theme.colors.green[3]
  const focusedBarColor = colorMode === 'light' ? theme.colors.blue[1] : theme.colors.green[1]

  const MIN_OPACITY = 0.5

  const [skeletonOpacity, setSkeletonOpacity] = useState(MIN_OPACITY)
  const animFrame = useRef<number>(0)
  const skeletonColor = useColorModeValue('#CBD5E0', '#4A5568')

  useEffect(() => {
    let direction = -1
    const animate = () => {
      animFrame.current = requestAnimationFrame(animate)
      setSkeletonOpacity((oldValue) => {
        if (oldValue <= MIN_OPACITY) direction = 1
        if (oldValue >= 1) direction = -1
        return oldValue + 0.01 * direction
      })
    }

    if (isSkeleton) {
      animate()
    } else {
      setSkeletonOpacity(MIN_OPACITY)
      cancelAnimationFrame(animFrame.current)
    }
  }, [isSkeleton])

  function handleMouseEnter(bar: FocusedBar, index: number) {
    setFocusedBarIndex(index)
    setFocusedBar(bar)
  }

  function handleMouseLeave() {
    setFocusedBarIndex(-1)
    setFocusedBar(undefined)
  }

  return (
    <ResponsiveContainer>
      <RechartsBarChart data={data}>
        <XAxis dataKey="valueX" tickLine={false} tick={textStyle} dy={4} />
        <YAxis tickCount={7} tick={textStyle} tickLine={false} axisLine={false} />
        <CartesianGrid vertical={false} stroke={theme.colors.light[1]} />
        <Tooltip
          allowEscapeViewBox={{ y: true }}
          position={focusedBar && { y: focusedBar.y, x: focusedBar.x + focusedBar.width / 2 }}
          cursor={false}
          // isAnimationActive={false}
          content={() => <BarChartTooltip focusedBar={focusedBar} getTooltipLabel={getTooltipLabel} />}
        />
        <Bar
          cursor="pointer"
          maxBarSize={60}
          dataKey="valueY"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              fillOpacity={isSkeleton ? skeletonOpacity : 1}
              fill={isSkeleton ? skeletonColor : focusedBarIndex === index ? focusedBarColor : barColor}
            />
          ))}
        </Bar>
      </RechartsBarChart>
    </ResponsiveContainer>
  )
}
