import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ExternalLinkIcon = createIcon({
  displayName: 'ExternalLinkIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5V19H19V12H21V21H3V3H12V5H5ZM14 5V3H21V10H19V6.41L9.17 16.24L7.76 14.83L17.59 5H14Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
