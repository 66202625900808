import React from 'react'

type Props<T> = {
  DeleteButton: React.FC<{
    item: T
    refetch: () => Promise<unknown>
  }>
  EditButton: React.FC<{
    item: T
    refetch: () => Promise<unknown>
  }>
}

export function buildActionColumns<T>({ EditButton, DeleteButton }: Props<T>) {
  return [
    {
      textAlign: 'center' as const,
      key: 'edit',
      render: (item: T, { refetch }: any) => <EditButton item={item} refetch={refetch} />,
      width: '7%',
    },
    {
      textAlign: 'center' as const,
      key: 'delete',
      render: (item: T, { refetch }: any) => <DeleteButton item={item} refetch={refetch} />,
      width: '7%',
    },
  ]
}
