import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ZoneIcon = createIcon({
  displayName: 'ZoneIcon',
  path: (
    <g strokeLinecap="round" strokeLinejoin="round">
      <path
        d="m4 8.63107c0-.69812.36402-1.34571.96042-1.70859l5.99998-3.65068c.6386-.38856 1.4406-.38856 2.0792 0l6 3.65068c.5964.36288.9604 1.01047.9604 1.70859v6.73783c0 .6981-.364 1.3457-.9604 1.7086l-6 3.6507c-.6386.3886-1.4406.3886-2.0792 0l-5.99998-3.6507c-.5964-.3629-.96042-1.0105-.96042-1.7086z"
        stroke="currentColor"
      />
      <path d="m8 10 4 1.8667v4.1333-4.1333l4-1.8667" opacity=".3" stroke="currentColor" />
    </g>
  ),
  viewBox: '0 0 24 24',
})
