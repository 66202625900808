import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

interface Props {
  name: string
  color: string
}

const StyledBox = styled(Box)`
  display: inline-block;
  width: 9rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0.5rem;
  text-align: center;
`

const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NameRenderer: React.FC<Props> = ({ name, color }) => {
  return (
    <StyledBox borderColor={color}>
      <StyledText fontFamily="label" fontSize="sm" fontWeight="bold" color={color}>
        {name}
      </StyledText>
    </StyledBox>
  )
}
