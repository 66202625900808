import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const TimerIcon = createIcon({
  displayName: 'TimerIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1.5h6v2H9v-2zm2 13v-6h2v6h-2zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 5.24 14.12 4.5 12 4.5c-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zM5 13.5c0 3.87 3.13 7 7 7s7-3.13 7-7-3.13-7-7-7-7 3.13-7 7z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
})
