import { getQueryData } from 'hooks'
import produce from 'immer'
import { DeviceGroupDevice, fetchDeviceGroup } from 'libs/api'
import { last, findLast } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import create from 'zustand'
import { Message, MessageType, WsAuthorizationEvent, WsDeviceGroupStatus } from './types'

type Indicator = 'passed' | 'failed' | 'idle' | 'locked'

type DeviceGroupState = {
  id: string
  currentAuthorizationEvents: WsAuthorizationEvent[]
  status: WsDeviceGroupStatus
}

type State = {
  authorizationEvents: Record<string, WsAuthorizationEvent>
  messageHistory: Message[]
  deviceGroupsStates: Record<string, DeviceGroupState>
  devices: Record<string, DeviceGroupDevice>
  dispatch: (message: Message) => void
  setMessageHistory: Dispatch<SetStateAction<Message[]>>
  setDevice: (device: DeviceGroupDevice) => void
  setDeviceGroupStatus: (id: string, status: WsDeviceGroupStatus) => void
}

const createDeviceGroupState = (id: string, data?: Partial<DeviceGroupState>): DeviceGroupState => ({
  id,
  currentAuthorizationEvents: [],
  status: getQueryData(fetchDeviceGroup, { id })?.status ?? 'secure',
  ...data,
})

const reducer = produce((state: State, message: Message) => {
  const getDeviceGroup = (id: string) =>
    (state.deviceGroupsStates[id] = state.deviceGroupsStates[id] ?? createDeviceGroupState(id))

  let shouldAddToHistory = true

  switch (message.type) {
    case MessageType.AUTHORIZATION_EVENT: {
      const { id, device_group_id } = message.data
      state.authorizationEvents[id] = message.data
      const deviceGroup = getDeviceGroup(device_group_id)
      deviceGroup.currentAuthorizationEvents.push(message.data)
      break
    }

    case MessageType.TRANSIT_EVENT: {
      if (message.data.status !== 'in_progress') {
        const { device_group_id } = message.data
        const deviceGroup = getDeviceGroup(device_group_id)
        deviceGroup.currentAuthorizationEvents = []
      }
      break
    }

    case MessageType.AUTHORIZATION_EVENT_RESOLVED:
    case MessageType.AUTHORIZATION_EVENT_ACKNOWLEDGED: {
      const deviceGroupId = state.authorizationEvents[message.data.authorization_event_id]?.device_group_id
      if (!deviceGroupId) break
      const deviceGroup = getDeviceGroup(deviceGroupId)
      deviceGroup.currentAuthorizationEvents = []
      if (message.type === MessageType.AUTHORIZATION_EVENT_RESOLVED) {
        state.authorizationEvents[message.data.authorization_event_id].resolution_id = message.data.resolution_id
        state.authorizationEvents[message.data.authorization_event_id].note = message.data.note
      }
      break
    }

    case MessageType.DEVICE_GROUP: {
      const deviceGroup = getDeviceGroup(message.data.id)
      deviceGroup.currentAuthorizationEvents = (message.data.current_authorization_event_ids ?? []).map(
        (id) => state.authorizationEvents[id]
      )
      deviceGroup.status = message.data.status
      break
    }

    default: {
      // If message is none of the above (e.g. phx_join), don't add it to history
      shouldAddToHistory = false
    }
  }

  if (shouldAddToHistory) {
    state.messageHistory.push(message)
  }
})

export const useChellStore = create<State>((set, get) => ({
  authorizationEvents: {},
  deviceGroupsStates: {},
  devices: {},
  messageHistory: [],
  dispatch: (message: Message) => set((state: State) => reducer(state, message)),
  setMessageHistory: (arg) =>
    set(({ messageHistory }) => ({ messageHistory: typeof arg === 'function' ? arg(messageHistory) : arg })),
  setDevice: (device) => set(({ devices }) => ({ devices: { ...devices, [device.thing_id]: device } })),

  setDeviceGroupStatus: (id, status) =>
    set(
      produce(({ deviceGroupsStates }: State) => {
        deviceGroupsStates[id] = deviceGroupsStates[id] ?? createDeviceGroupState(id)
        deviceGroupsStates[id].status = status
      })
    ),
}))

export function useCurrentDeviceEvent(deviceId: string, deviceGroupId: string) {
  return useChellStore((state) =>
    findLast(state.deviceGroupsStates[deviceGroupId]?.currentAuthorizationEvents, (e) => e.device_id === deviceId)
  )
}

export function useLatestDeviceGroupEvent(deviceGroupId: string) {
  const state = useChellStore(
    useCallback(
      (state) => {
        return state.deviceGroupsStates[deviceGroupId]
      },
      [deviceGroupId]
    )
  )
  return useMemo(() => {
    return last(state?.currentAuthorizationEvents)
  }, [state])
}

export function useDeviceGroupIndicator(deviceGroupId: string): Indicator {
  const state = useChellStore((state) => state.deviceGroupsStates[deviceGroupId])
  return useMemo(() => {
    if (!state) return 'idle'
    if (state.status === 'locked') return 'locked'
    const lastEvent = last(state.currentAuthorizationEvents)
    if (!lastEvent) return 'idle'
    return lastEvent.authorized ? 'passed' : 'failed'
  }, [state])
}

;(window as any).useChellStore = useChellStore
