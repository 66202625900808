import { LimitOffsetCursor, ListResponse, OrderParams, PaginationSearchParams } from './types'
import { API } from 'aws-amplify'
import { fromPairs, pickBy } from 'lodash-es'
import { getUserFullName } from '../../hooks'
import { Role } from './roles'
import { isNotUndefined } from 'utils/form'

export enum BuiltinUserRoles {
  KeeeAdmin = 'keeeAdmin',
  Admin = 'admin',
}

const builtinRoles = [
  { role_id: BuiltinUserRoles.KeeeAdmin, name: 'keee Admin' },
  { role_id: BuiltinUserRoles.Admin, name: 'Org. Admin' },
]

export function buildUserRoleOptions(customRoles: Role[], includeKeeeAdmin: boolean) {
  return [...builtinRoles, ...customRoles]
    .map((role) => ({ label: role.name, value: role.role_id }))
    .filter((option) => includeKeeeAdmin || option.value !== BuiltinUserRoles.KeeeAdmin)
}

export function mapRoleNames(roleIds: string[], customRoles: Role[]) {
  const allRoles = [...builtinRoles, ...customRoles]
  return roleIds.map((roleId) => allRoles.find((role) => role.role_id === roleId)?.name).filter(isNotUndefined)
}

export const rolesWithoutLocationRestrictions = Object.values(BuiltinUserRoles) as string[]

export enum UserPasswordTypes {
  Generate = 'generate',
  Specify = 'specify',
}

interface UserAttribute {
  Name: string
  Value: string
}

export interface APIUser {
  Username: string
  UserCreateDate: string
  UserLastModifiedDate: string
  UserStatus: string
  Enabled: boolean
  Attributes: UserAttribute[]
}

type APIUserDetail = Omit<APIUser, 'Attributes'> & {
  UserAttributes: UserAttribute[]
}

export interface User {
  fullName: string
  firstName: string
  lastName: string
  enabled: boolean
  userName: string
  status: string
  roles: string[]
  locationIds: string[]
  tenantId: string
  email: string
  sub: string
  phoneNumber?: string
  emailVerified?: string
  phoneNumberVerified?: string
}

export interface AuditLog {
  table_name: string
  row_id: string
  old_row_data: any
  new_row_data: any
  dml_type: 'INSERT' | 'UPDATE' | 'DELETE'
  dml_timestamp: string
  dml_created_by: string
}

export interface CognitoAuthEvent {
  ChallengeResponses: Array<{ ChallengeName: string; ChallengeResponse: string }>
  CreationDate: string
  EventContextData: {
    City: string
    Country: string
    DeviceName: string
    IpAddress: string
  }
  EventId: string
  EventResponse: string
  EventRisk: {
    CompromisedCredentialsDetected: boolean
    RiskDecision: string
  }
  EventType: string
}

function transformApiUser(user: APIUser): User {
  const attributes = fromPairs(user.Attributes.map((a) => [a.Name, a.Value]))
  return {
    fullName: getUserFullName(user.Username, attributes),
    firstName: attributes.given_name,
    lastName: attributes.family_name,
    enabled: user.Enabled,
    userName: user.Username,
    status: user.UserStatus,
    roles: (attributes['custom:role'] ?? '').split(',').filter(Boolean),
    locationIds: (attributes['custom:location_ids'] ?? '').split(',').filter(Boolean),
    tenantId: attributes['custom:tenant_id'],
    email: attributes.email,
    sub: attributes.sub,
    phoneNumber: attributes.phone_number,
    emailVerified: attributes.email_verified,
    phoneNumberVerified: attributes.phone_number_verified,
  }
}

export enum UserStatus {
  Active = 'enabled',
  Inactive = 'disabled',
  All = '',
}

export interface UsersFilters {
  status?: UserStatus
}

type UsersCursor = UsersFilters &
  OrderParams & {
    paginationToken?: string
    limit?: number
    search?: string // Not supported by the API yet
  }

interface BaseUserPayload {
  firstName?: string
  lastName?: string
  roles: string[]
  locationIds: string[]
  email: string
}

interface UpdateUserPayload extends BaseUserPayload {
  phoneNumber?: string
}

interface CreateUserPayload extends BaseUserPayload {
  userName: string
  temporaryPassword?: string
}

export const createUser = async (payload: CreateUserPayload) =>
  API.post('users', '/users/', {
    body: {
      ...pickBy(payload, Boolean),
    },
  })

export const updateUser = async (user: User, payload: UpdateUserPayload): Promise<APIUser> =>
  API.put('users', `/users/${user.userName}`, {
    body: {
      ...payload,
      phoneNumber: payload.phoneNumber ?? '', // required by API
    },
  })

export const deleteUser = async (user: User): Promise<void> => API.del('users', `/users/${user.userName}`, {})

interface UsersAPIResponse {
  Users: APIUser[]
  PaginationToken: string
}

export async function getUsers(params?: PaginationSearchParams): Promise<User[]> {
  const response = await API.get('users', '/users', { queryStringParameters: params })
  return response?.Users?.map(transformApiUser)
}

export const getUsersList = async (cursor: UsersCursor = {}): Promise<ListResponse<User, UsersCursor>> => {
  const { Users, PaginationToken }: UsersAPIResponse = await API.get('users', '/users', {
    queryStringParameters: {
      ...cursor,
      ...pickBy({ enabled: cursor.status, orderBy: cursor.orderBy, orderDirection: cursor.orderDirection }, Boolean),
    },
  })

  return {
    nextCursor: PaginationToken ? { paginationToken: PaginationToken } : undefined,
    totalCount: Users.length,
    items: Users.map(transformApiUser),
  }
}

type FetchUserParams = { userName: string }

export async function fetchUser({ userName }: FetchUserParams): Promise<User> {
  const userDetail: APIUserDetail = await API.get('users', `/users/${userName}`, {})
  return transformApiUser({ ...userDetail, Attributes: userDetail.UserAttributes })
}

type FetchUserActivityParams = { userName: string } & LimitOffsetCursor

export const fetchUserActivity = async ({ userName, limit, offset }: FetchUserActivityParams): Promise<AuditLog[]> => {
  return await API.get('users', `/users/${userName}/activity`, {
    queryStringParameters: {
      limit,
      offset,
    },
  })
}

type FetchUserAuthEventsParams = {
  userName: string
  limit?: number
  nextToken?: string
}

export const getUserAuthEventsList = async ({
  userName,
  limit,
  nextToken,
}: FetchUserAuthEventsParams): Promise<ListResponse<CognitoAuthEvent, FetchUserAuthEventsParams>> => {
  const { AuthEvents, NextToken } = await API.get('users', `/users/${userName}/auth-events`, {
    queryStringParameters: pickBy({
      limit,
      nextToken,
    }),
  })
  return {
    items: AuthEvents,
    totalCount: undefined as any,
    nextCursor: NextToken ? { userName, nextToken: NextToken } : undefined,
  }
}

export async function updateUserAccount(user: User, enabled: boolean) {
  return API.put('users', `/users/${user.userName}/account`, {
    body: { enabled },
  })
}

export async function resendUserInvite(user: User) {
  return API.post('users', '/users', {
    body: pickBy(
      {
        userName: user.userName,
        messageAction: 'RESEND',
      },
      Boolean
    ),
  })
}

export async function resetUserPassword(user: User) {
  return API.post('users', `/users/${user.userName}/reset-user-password`, {})
}
