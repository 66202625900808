import { createIcon } from '@chakra-ui/icon'

export const InactivityIcon = createIcon({
  displayName: 'InactivityIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.875 10.8332H48.75V5.4165H43.3333V10.8332H21.6667V5.4165H16.25V10.8332H8.15208L8.125 59.5832H56.875V10.8332ZM25.2146 48.7498L31.8229 42.1415L38.4313 48.7498L41.3021 45.879L34.6938 39.2707L41.3021 32.6623L38.4313 29.7915L31.8229 36.3998L25.2146 29.7915L22.3438 32.6623L28.9521 39.2707L22.3438 45.879L25.2146 48.7498ZM13.5417 54.1665H51.4583V24.3748H13.5417V54.1665Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 65 65',
})
