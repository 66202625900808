import React from 'react'
import { Text } from 'react-konva'
import Konva from 'konva'

type Props = {
  value: string
  placeholder?: string
  onChange?: (newValue: string) => void
} & Omit<Konva.TextConfig, 'text'>

export const EditableText: React.FC<Props> = ({ value, placeholder, onChange, ...textProps }) => {
  const displayPlaceholder = !value

  function onDoubleClick() {
    const newText = window.prompt('New text', value)
    if (newText) {
      onChange?.(newText)
    }
  }

  return (
    <Text
      {...textProps}
      onDblClick={onDoubleClick}
      text={value || placeholder || ''}
      fill={displayPlaceholder ? '#e0e0e0' : 'black'}
    />
  )
}
