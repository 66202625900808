import { useMemo } from 'react'
import { useValidationRules } from './useValidationRules/useValidationRules'
import { MixedSchema } from 'yup'

export const useValidation = (factory: (rules: typeof validationRules) => MixedSchema) => {
  const validationRules = useValidationRules()

  return useMemo(
    () => factory(validationRules),
    [validationRules] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
