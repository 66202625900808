import { useCallback, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useSearchParam(key: string) {
  const { search } = useLocation()
  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const param: string = searchParams.get(key) || ''

  const setParam = useCallback(
    (value: string) => {
      if (value) {
        searchParams.set(key, value)
      } else {
        searchParams.delete(key)
      }
      history.replace({ search: searchParams.toString() })
    },
    [history, key, searchParams]
  )

  return [param, setParam] as const
}
