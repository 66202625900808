import 'libs/api/amplify'
import React from 'react'
import { isRootDomain } from 'utils/isRootDomain'
import { SubdomainConfigLoader } from './SubdomainConfigLoader'
import { SubdomainForm } from './SubdomainForm'

export const CognitoConfigLoader: React.FC = ({ children }) => {
  if (isRootDomain()) {
    return <SubdomainForm children={children} />
  }
  return <SubdomainConfigLoader children={children} />
}
