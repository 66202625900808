import React, { ReactElement } from 'react'
import { useTranslations } from 'hooks'
import translations from './UpsertDeviceModal.i18n.json'
import { DeviceItem } from 'libs/api'
import { UpsertModal } from 'modules/Admin/components'
import { DeviceForm } from '../DeviceForm'
import { useHistory } from 'react-router-dom'
import * as routes from 'routes'

type EditProps = {
  refetch: () => Promise<unknown>
  item: DeviceItem
}

type Props = (EditProps | { item?: never }) & {
  children: ({ open }: { open: () => void }) => ReactElement
}

export function UpsertDeviceModal({ children, ...props }: Props) {
  const labels = useTranslations(translations)
  const history = useHistory()

  const handleSuccess = async (savedItem: DeviceItem) => {
    if (props.item) return props.refetch()
    history.push(routes.deviceDetailWithId(savedItem.thing_id))
  }
  return (
    <UpsertModal labels={labels} Modal={DeviceForm} item={props.item} onSuccess={handleSuccess}>
      {children}
    </UpsertModal>
  )
}
