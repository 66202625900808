import { LimitOffsetCursor, ListResponse } from './types'

export function getNextCursor(
  response: ListResponse<any, any>,
  cursor: LimitOffsetCursor
): LimitOffsetCursor | undefined {
  if (!response?.items?.length) {
    return undefined
  }

  const nextOffset = (cursor.offset ?? 0) + response.items.length

  if (nextOffset >= response.totalCount) {
    return undefined
  }

  return { offset: nextOffset }
}

// Empty array is lost during encoding
export function multiValueParam(values: string[] | undefined) {
  if (values === undefined) return values
  return values.length ? values : ''
}
