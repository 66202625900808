import React, { useContext } from 'react'
import { Box } from '../index'
import { TableStyleContext } from './TableStyleProvider'

interface Props {}

export const TableHeadRow: React.FC<Props> = ({ children }) => {
  const { bgShade } = useContext(TableStyleContext)
  return (
    <thead>
      <Box as="tr" bgShade={bgShade} height="40px">
        {children}
      </Box>
    </thead>
  )
}
