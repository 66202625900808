import { withProps } from 'components/core/utils'
import { Text } from 'components'
import { IntlShape } from 'react-intl'
import translations from '../Devices.i18n.json'
import { Column } from 'modules/Common/ListPage'
import React from 'react'
import { DeviceItem } from 'libs/api/devices'
import { DeviceEditButton, DeviceImage } from '../components'

const RowText = withProps(Text, { fontSize: 'sm', shade: 1 })

type Props = ({ noEdit: true } | { noEdit?: false; refetch: () => Promise<unknown> }) & {
  intl: IntlShape
}

export const buildColumns = ({ intl, ...props }: Props): Column<DeviceItem>[] => [
  {
    title: '',
    width: '40px',
    render: (item) => (
      <DeviceImage url={item.image_url} color={item.color} fallbackImageSize="24px" w="40px" h="40px" fit="cover" />
    ),
  },
  {
    sortKey: 'name',
    title: intl.formatMessage(translations.name),
    render: (item) => <RowText>{item.name}</RowText>,
  },
  {
    sortKey: 'mac_address',
    title: intl.formatMessage(translations.macAddress),
    render: (item) => <RowText>{item.mac_address || '--'}</RowText>,
  },
  {
    sortKey: 'device_string',
    title: intl.formatMessage(translations.serialNumber),
    render: (item) => <RowText>{item.device_string || '--'}</RowText>,
  },
  // {
  //   sortKey: 'status',
  //   title: intl.formatMessage(translations.status),
  //   render: (item) => <DeviceChip device={item} />,
  // },
  {
    sortKey: 'description',
    title: intl.formatMessage(translations.description),
    render: (item) => <RowText>{item.description}</RowText>,
  },
  {
    sortKey: 'type',
    title: intl.formatMessage(translations.type),
    render: (item) => <RowText>{item.thing_type_name}</RowText>,
  },
  // {
  //   sortKey: 'location',
  //   title: intl.formatMessage(translations.location),
  //   render: (item) => <RowText>{item.place_name}</RowText>,
  // },
  {
    title: '',
    key: 'edit',
    render: (item) => (props.noEdit ? null : <DeviceEditButton item={item} refetch={props.refetch} />),
  },
]
