import React from 'react'
import { Form, Formik } from 'formik'
import { Flex, Text } from '@chakra-ui/react'
import { SubmitButton, TextField, CancelButton, TextareaField } from 'components/form'
import { createEventType, CreateEventTypePayload, EventType, updateEventType } from 'libs/api'
import { useNotify, useTranslations, useValidation } from 'hooks'
import translations from '../Actions.i18n.json'
import { PhotoUploadField } from 'components/form/PhotoUploadField'

interface Props {
  item?: EventType
  onSuccess: () => void
  onCancel: () => void
}

export const UpsertEventType: React.FC<Props> = ({ onSuccess, onCancel, item }) => {
  const t = useTranslations(translations)
  const snackbar = useNotify()

  const validationSchema = useValidation((rules) =>
    rules.object({
      name: rules.string().required().label(t.eventTypeNameLabel),
      description: rules.string().required().label(t.descriptionLabel),
    })
  )

  const onSubmit = async (values: CreateEventTypePayload) => {
    try {
      await (item ? updateEventType(item, values) : createEventType(values))
      snackbar.success(item ? t.editSuccess : t.createSuccess)
      onSuccess()
    } catch (e) {
      snackbar.error()
    }
  }

  const initialValues = item ?? {
    name: '',
    description: '',
  }

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <Text>{t.createDescription}</Text>

        <Flex direction="column" mt={6}>
          <TextField name="name" label={t.eventTypeNameLabel} placeholder={t.eventTypeNamePlaceholder} mb={6} />

          <TextareaField name="description" label={t.descriptionLabel} placeholder={t.descriptionPlaceholder} mb={6} />

          <PhotoUploadField
            name="image_url"
            label={t.iconUpload}
            placeholder={t.iconUploadPlaceholder}
            options={{ accept: ['image/svg+xml', 'image/png'] }}
          />

          <Flex flexDirection="row-reverse" mt={10}>
            <SubmitButton>{item ? t.updateButton : t.createButton}</SubmitButton>
            <CancelButton onClick={onCancel} mr={4} />
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}
