import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const SubmittedIcon = createIcon({
  displayName: 'SubmittedIcon',
  path: (
    <>
      <path
        d="M17 19C17 15.6863 13.4183 13 9 13C4.58172 13 1 15.6863 1 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="#E5F0FF"
      />
      <circle cx="16" cy="18" r="7" fill="#E5F0FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 12.1666C12.7801 12.1666 10.1667 14.78 10.1667 18C10.1667 21.22 12.7801 23.8333 16.0001 23.8333C19.2201 23.8333 21.8334 21.22 21.8334 18C21.8334 14.78 19.2201 12.1666 16.0001 12.1666ZM16.0001 22.6666C13.4276 22.6666 11.3334 20.5725 11.3334 18C11.3334 15.4275 13.4276 13.3333 16.0001 13.3333C18.5726 13.3333 20.6667 15.4275 20.6667 18C20.6667 20.5725 18.5726 22.6666 16.0001 22.6666ZM14.8334 19.2658L18.6776 15.4216L19.5001 16.25L14.8334 20.9166L12.5001 18.5833L13.3226 17.7608L14.8334 19.2658Z"
        fill="currentColor"
      />
      <circle cx="8.5" cy="5.5" r="4.5" stroke="currentColor" strokeWidth="2" fill="#E5F0FF" />
    </>
  ),
  viewBox: '0 0 23 25',
})
