import { ViewMode } from 'components'
import ErrorState from 'components/State/ErrorState'
import { useViewMode } from 'hooks'
import { ListResponse } from 'libs/api'
import { flatten, map } from 'lodash-es'
import { GridView } from 'modules/Common/GridView/GridView'
import { ListView, ListViewProps } from 'modules/Common/ListView/ListView'
// import MapView from 'modules/Common/MapView/MapView'
import React from 'react'
import { UseInfiniteQueryResult } from 'react-query'

type Props<T, C> = Omit<ListViewProps<T>, 'items' | 'fetchNextPage' | 'hasNextPage' | 'refetch'> & {
  query: UseInfiniteQueryResult<ListResponse<T>, C>
  gridItemComponent?: React.FC<{ data: T }>
  gridSkeletonItemComponent?: React.ComponentType
}

export function ViewModeList<T, C>({
  query,
  gridItemComponent,
  gridSkeletonItemComponent,
  dummyItem,
  ...viewProps
}: Props<T, C>) {
  const { activeMode } = useViewMode()
  // if (activeMode === ViewMode.MAP) {
  //   return <MapView />
  // }

  const { data, error, refetch, fetchNextPage, hasNextPage } = query
  if (error) {
    return <ErrorState retry={refetch} />
  }
  const queryProps = {
    items: data?.pages.length ? flatten(map(data.pages, 'items') || []) : undefined,
    refetch,
    fetchNextPage,
    hasNextPage: !!hasNextPage,
  }
  if (activeMode === ViewMode.LIST) {
    return <ListView dummyItem={dummyItem} {...viewProps} {...queryProps} />
  }
  if (activeMode === ViewMode.GRID && gridItemComponent) {
    return (
      <GridView
        gridItemComponent={gridItemComponent}
        skeletonItemComponent={gridSkeletonItemComponent}
        {...viewProps}
        {...queryProps}
      />
    )
  }
  throw Error('Unexpected view mode')
}
