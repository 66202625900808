import FlatfileImporter from 'flatfile-csv-importer'
import { useEffect, useState } from 'react'

export function getImporter(fields: object) {
  const apiKey = process.env.REACT_APP_FLATFILE_API_KEY

  if (!apiKey) {
    throw Error('Flatfile api key not found')
  }

  return new FlatfileImporter(apiKey, {
    managed: true,
    fields,
  })
}

export function useImporter(fields: object[]) {
  const [importer, setImporter] = useState<FlatfileImporter>()
  useEffect(() => {
    if (fields) {
      setImporter(getImporter(fields))
    }
  }, [fields])

  return importer
}
