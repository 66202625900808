import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const AuthyIcon = createIcon({
  displayName: 'AuthyIcon',
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 3.277 3.277"
      preserveAspectRatio="xMidYMid"
    >
      <path
        d="M3.277 1.638a1.64 1.64 0 0 1-1.638 1.638A1.64 1.64 0 0 1 0 1.638 1.64 1.64 0 0 1 1.638 0a1.64 1.64 0 0 1 1.638 1.638z"
        fill="currentColor"
      />
      <path
        d="M1.498 1.29l.393.393a.145.145 0 1 0 .206-.206l-.393-.393A.74.74 0 0 0 .68 1.07l-.015.014a.737.737 0 0 0 .007 1.03l.393.393a.145.145 0 1 0 .206-.206L.88 1.91c-.17-.17-.173-.45-.004-.623s.45-.168.622.004zm.507-.523a.145.145 0 0 0 0 .206l.393.393c.17.17.173.45.003.623s-.45.168-.622-.004l-.393-.393a.145.145 0 1 0-.206.206l.393.393c.28.28.735.286 1.023.014l.015-.014a.737.737 0 0 0-.007-1.03L2.21.768a.145.145 0 0 0-.206 0z"
        fill="#fff"
      />
    </svg>
  ),
  viewBox: '0 0 64 64',
})
