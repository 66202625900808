import { deleteEventType } from 'libs/api'
import { createDeleteButton } from '../../../../components'
import translations from './EventTypeDeleteButton.i18n.json'

export const EventTypeDeleteButton = createDeleteButton({
  translations,
  onConfirm: deleteEventType,
  getName: (user) => user.name,
  subject: 'event_types' as never, // TODO add event_types subject / remove event_types for good
})
