import { Input, InputGroup, InputProps, InputLeftElement, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { SearchIcon } from '@chakra-ui/icons'

type Props = InputProps

export const SearchInput: React.FC<Props> = (props) => {
  const { colorMode } = useColorMode()
  return (
    <InputGroup>
      <InputLeftElement h="100%" children={<SearchIcon color="dark.3" />} />
      <Input
        boxShadow="light.1"
        borderRadius={0}
        border="none"
        fontSize="sm"
        py="22px"
        color={colorMode === 'light' ? 'dark.1' : 'light.1'}
        bg={colorMode === 'light' ? 'light.4' : 'gray.800'}
        {...props}
      />
    </InputGroup>
  )
}
