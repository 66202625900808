import React from 'react'
import ReactCodeInput from 'react-code-input'
import { useField } from 'formik'
import theme from 'theme'

type Props = {
  name: string
  type: 'text' | 'number' | 'password' | 'tel'
  fields: number
}

const inputStyle = {
  backgroundColor: theme.colors.dark[4],
  fontSize: theme.fontSizes.xl,
  fontWeight: 'bold',
  height: 48,
  width: 'min(13%, 48px)',
  textAlign: 'center',
  marginLeft: theme.sizes[1],
  marginRight: theme.sizes[1],
  outline: 'none',
}

export const CodeField = ({ name, ...props }: Props) => {
  const [field, , helpers] = useField(name)
  return (
    <ReactCodeInput
      value={field.value}
      onChange={(value: string) => helpers.setValue(value)}
      inputStyle={inputStyle}
      {...props}
    />
  )
}
