import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  path: (
    <path
      d="M14.167 5.83333L13.0003 7L15.167 9.16667H6.66699V10.8333H15.167L13.0003 13L14.167 14.1667L18.3337 10L14.167 5.83333ZM3.33366 4.16667H10.0003V2.5H3.33366C2.41699 2.5 1.66699 3.25 1.66699 4.16667V15.8333C1.66699 16.75 2.41699 17.5 3.33366 17.5H10.0003V15.8333H3.33366V4.16667Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
})
