import { API } from 'aws-amplify'
import { LimitOffsetCursor, ListResponse } from './types'
import { getNextCursor } from './utils'

export enum NotificationType {
  Slack = 'Slack',
  MicrosoftTeams = 'Microsoft Teams',
  PagerDuty = 'Pager Duty',
  Webhook = 'Webhook',
  Email = 'Email',
}

export type SlackChannel = { id: string; name: string }
export type SlackMember = { id: string; name: string }
export type TeamsChannel = { id: string; name: string }

export type NotificationItem = {
  notification_id: string
  notification_type: NotificationType
  destination: NotificationDestination
  activity: NotificationActivity[]
}

export type NotificationDestination = {
  channel?: SlackChannel | TeamsChannel
  integration_key?: string
  severity?: 'critical' | 'warning' | 'error' | 'info'
  webhook_url?: string
  mentions?: SlackMember[]
  emails?: string[]
}

export type NotificationActivity = {
  channel: 'events' | 'people' | 'things'
  eventName: 'create' | 'update'
  filters: string[]
  category_names?: string
  event_type_names?: string
  thing_type_names?: string
}

type NotificationActivityInputItem = {
  enabled: boolean
  filters: string[]
}

export type NotificationActivityInput = {
  events_create?: NotificationActivityInputItem
  people_create?: NotificationActivityInputItem
  things_create?: NotificationActivityInputItem
  events_update?: NotificationActivityInputItem
  people_update?: NotificationActivityInputItem
  things_update?: NotificationActivityInputItem
}

export type NotificationFormValues = Omit<NotificationDestination, 'emails'> & {
  notification_id?: string
  notification_type: NotificationType
  emails: string[]
  activity: NotificationActivityInput
}

function convertNotificationActivity(values: NotificationActivityInput) {
  const activity: NotificationActivity[] = []
  for (const key in values) {
    const [channel, eventName] = key.split('_') as [NotificationActivity['channel'], NotificationActivity['eventName']]
    const value = values[key as keyof typeof values]
    if (value?.enabled) {
      activity.push({ channel, eventName, filters: value.filters })
    }
  }
  return activity
}

function convertNotificationFormValues({
  notification_id,
  notification_type,
  activity,
  channel,
  emails,
  mentions,
  webhook_url,
  integration_key,
  severity,
}: NotificationFormValues) {
  let destination: NotificationDestination = {}
  if (notification_type === NotificationType.Slack) {
    destination = { channel, mentions }
  } else if (notification_type === NotificationType.MicrosoftTeams) {
    destination = { channel, webhook_url }
  } else if (notification_type === NotificationType.PagerDuty) {
    destination = { integration_key, severity }
  } else if (notification_type === NotificationType.Webhook) {
    destination = { webhook_url }
  } else if (notification_type === NotificationType.Email) {
    destination = { emails }
  }
  return {
    notification_id,
    notification_type,
    destination,
    webhook_url,
    activity: convertNotificationActivity(activity),
  }
}

export function getNotifications(): Promise<NotificationItem[]> {
  return API.get('notifications', '/notifications', {})
}

export const getNotificationsList = async (cursor: LimitOffsetCursor = {}): Promise<ListResponse<NotificationItem>> => {
  const response = await API.get('notifications', '/notifications', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

type FetchNotificationParams = { id: string }

export async function fetchNotification({ id }: FetchNotificationParams): Promise<NotificationItem> {
  return API.get('notifications', `/notifications/${id}`, {})
}

export async function createNotification(body: NotificationFormValues) {
  return API.post('notifications', '/notifications', {
    body: convertNotificationFormValues(body),
  })
}

export function updateNotification(notification: NotificationItem, body: NotificationFormValues) {
  return API.put('notifications', `/notifications/${notification.notification_id}`, {
    body: convertNotificationFormValues(body),
  })
}

export function deleteNotification(notification: NotificationItem) {
  return API.del('notifications', `/notifications/${notification.notification_id}`, {})
}

export function fetchSlackChannels(): Promise<SlackChannel[]> {
  return API.get('notifications', '/notifications/slack-channels', {})
}

export function fetchSlackMembers(): Promise<SlackMember[]> {
  return API.get('notifications', '/notifications/slack-members', {})
}

type FetchSlackOauthUrlParams = { redirect_page_url: string }

export function fetchSlackOauthUrl({ redirect_page_url }: FetchSlackOauthUrlParams): Promise<{ url: string }> {
  return API.get('notifications', '/notifications/slack-oauth-url', {
    queryStringParameters: {
      redirect_page_url,
    },
  })
}
