import { useContext, useEffect, useState } from 'react'
import { WebsocketContext } from 'components/WebsocketProvider'

export type EventName = 'create' | 'delete' | 'update'

type JsonMessage<T> = {
  channel: string
  eventName: EventName
  data: T
  timestamp: number
  userId: string
}

export type RealTimeUpdatesProps<T> = {
  channel: string
  isDisabled?: boolean
  onMessage: (message: JsonMessage<T>) => void
  filter?: (message: JsonMessage<T>) => boolean
}

export const useRealTimeUpdates = <T>({
  channel,
  isDisabled = false,
  onMessage,
  filter,
}: RealTimeUpdatesProps<T>): void => {
  const [enabledTime, setEnabledTime] = useState(0)
  const { lastJsonMessage, subscribe, unsubscribe, serverOffset } = useContext(WebsocketContext)

  useEffect(() => {
    subscribe?.(channel)
    return () => unsubscribe?.(channel)
  }, [channel, subscribe, unsubscribe])

  useEffect(() => {
    if (!isDisabled) setEnabledTime(Date.now())
  }, [isDisabled])

  useEffect(() => {
    const jsonMessage: JsonMessage<T> | null = lastJsonMessage
    if (!jsonMessage?.eventName || jsonMessage.channel !== channel) {
      //console.log(`Expected channel "${channel}" but got "${jsonMessage.channel}"`)
      return
    }
    if (isDisabled || jsonMessage.timestamp + serverOffset < enabledTime || (filter && !filter(jsonMessage))) {
      return
    }
    onMessage(jsonMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])
}
