import React, { useContext } from 'react'
import { useConnectionStatus } from '../../hooks/useConnectionStatus'
import { SidebarNavContext } from '.././SidebarNav'
import { Flex, Tag, TagLabel } from '@chakra-ui/react'

export function OnlineIndicator() {
  const online = useConnectionStatus()

  const { isCollapsed } = useContext(SidebarNavContext)

  return (
    <Flex mb={4} align="center">
      <Tag size="sm" borderRadius="full" variant="solid" colorScheme={online ? 'green' : 'red'}>
        {isCollapsed && <TagLabel></TagLabel>}
        {!isCollapsed && <TagLabel>{online ? 'Online' : 'Offline'}</TagLabel>}
      </Tag>
    </Flex>
  )
}
