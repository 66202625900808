import React from 'react'
import { useField } from 'formik'
import { FormControl, FormControlProps, FormErrorMessage, Textarea, TextareaProps } from '@chakra-ui/react'
import { FormLabel } from './FormLabel'
import { useBorderColor } from './hooks/useBorderColor'

export type TextareaFieldProps = {
  label?: string
  name: string
  placeholder?: string
  TextareaProps?: TextareaProps
} & FormControlProps

export const TextareaField = ({ label, name, placeholder, TextareaProps, ...props }: TextareaFieldProps) => {
  const [field, meta] = useField(name)
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Textarea
        id={name}
        placeholder={placeholder}
        fontSize="sm"
        borderRadius={0}
        borderColor={useBorderColor(field)}
        {...field}
        {...TextareaProps}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
