import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const GoogleAuthenticatorIcon = createIcon({
  displayName: 'GoogleAuthenticatorIcon',
  path: (
    <g clipPath="url(#clip0)">
      <path
        d="M20 40.002c11.047 0 20.002-8.955 20.002-20.001C40.002 8.955 31.047 0 20 0 8.955 0 0 8.955 0 20c0 11.047 8.955 20.002 20 20.002z"
        fill="#616161"
      />
      <path
        d="M20 32.728c-7.03 0-12.727-5.697-12.727-12.727S12.971 7.273 20.001 7.273c3.513 0 6.695 1.425 9 3.728l5.142-5.143A19.94 19.94 0 0 0 20.001 0C8.954 0 0 8.954 0 20c0 11.047 8.954 20.002 20 20.002a19.949 19.949 0 0 0 14.145-5.857l-5.143-5.143a12.705 12.705 0 0 1-9.001 3.726z"
        fill="#9E9E9E"
      />
      <path
        d="M32.728 20.001h-6.364a6.363 6.363 0 1 0-10.95 4.41l-.004.005 7.879 7.879.002.002c5.434-1.45 9.437-6.405 9.437-12.296z"
        fill="#424242"
      />
      <path
        d="M40 20h-7.274c0 5.89-4.005 10.845-9.436 12.295l5.617 5.617C35.482 34.636 40 27.846 40 20z"
        fill="#616161"
      />
      <path
        d="M20 39.773C8.994 39.773.065 30.88.003 19.887L0 20c0 11.046 8.954 20.001 20 20.001 11.047 0 20.002-8.955 20.002-20L40 19.886c-.063 10.993-8.993 19.886-20 19.886z"
        fill="#212121"
        fillOpacity=".1"
      />
      <path
        d="M23.292 32.295l.177.178c5.341-1.509 9.26-6.418 9.26-12.246V20c0 5.89-4.006 10.845-9.437 12.295z"
        fill="#fff"
        fillOpacity=".05"
      />
      <path d="M36.364 18.182H20a1.818 1.818 0 1 0 0 3.636h16.363a1.818 1.818 0 0 0 .002-3.636z" fill="#9E9E9E" />
      <path
        opacity=".5"
        d="M36.364 18.182H20a1.818 1.818 0 1 0 0 3.636h16.363a1.818 1.818 0 0 0 .002-3.636z"
        fill="#BDBDBD"
      />
      <path
        d="M3.636 21.366a1.363 1.363 0 1 0 0-2.727 1.363 1.363 0 0 0 0 2.727zM20.001 5a1.363 1.363 0 1 0 0-2.726 1.363 1.363 0 0 0 0 2.726zM20.001 37.727a1.363 1.363 0 1 0 0-2.727 1.363 1.363 0 0 0 0 2.727zM8.41 9.793a1.363 1.363 0 1 0 0-2.727 1.363 1.363 0 0 0 0 2.727zM8.41 32.956a1.364 1.364 0 1 0 0-2.728 1.364 1.364 0 0 0 0 2.727z"
        fill="#BDBDBD"
      />
      <path d="M31.592 32.956a1.364 1.364 0 1 0 0-2.728 1.364 1.364 0 0 0 0 2.727z" fill="#757575" />
      <path
        d="M20 18.409h16.364c.965 0 1.755.754 1.811 1.705.002-.039.007-.076.007-.114a1.818 1.818 0 0 0-1.818-1.818H20A1.818 1.818 0 0 0 18.183 20c0 .038.001.075.006.114a1.814 1.814 0 0 1 1.812-1.705z"
        fill="#fff"
        fillOpacity=".2"
      />
      <path
        d="M38.175 20.113a1.82 1.82 0 0 1-1.811 1.706H20a1.815 1.815 0 0 1-1.812-1.706.887.887 0 0 0-.006.113c0 1.004.814 1.818 1.818 1.818h16.363a1.818 1.818 0 0 0 1.818-1.818.887.887 0 0 0-.007-.113z"
        fill="#212121"
        fillOpacity=".2"
      />
      <path
        d="M20 7.501c3.514 0 6.696 1.425 9 3.728l5.254-5.257-.112-.115L29 11a12.692 12.692 0 0 0-9-3.728C12.97 7.272 7.274 12.97 7.274 20l.002.114c.061-6.977 5.734-12.613 12.726-12.613z"
        fill="#212121"
        fillOpacity=".1"
      />
      <path
        d="M20 40.002c11.047 0 20.002-8.955 20.002-20.001C40.002 8.955 31.047 0 20 0 8.955 0 0 8.955 0 20c0 11.047 8.955 20.002 20 20.002z"
        fill="url(#paint0_radial)"
      />
    </g>
  ),
  viewBox: '0 0 40 40',
})
