import { useAuthContext } from './useAuthContext'
import { CognitoUser } from '../types'

export const getUserFullName = (username: string, attributes: CognitoUser['attributes']) => {
  const { given_name, family_name } = attributes || {}
  return given_name || family_name ? `${given_name} ${family_name}` : username
}

export const getUserLocationIds = (attributes: CognitoUser['attributes']) => {
  const value: string = attributes['custom:location_ids'] ?? ''
  return value.split(',').filter(Boolean)
}

export const useCurrentUser = () => {
  const { currentAuthenticatedUser } = useAuthContext()

  if (!currentAuthenticatedUser) {
    throw new Error('useCurrentUser can only be used in Authorized contexts')
  }

  return {
    ...currentAuthenticatedUser,
    fullName: getUserFullName(currentAuthenticatedUser.username, currentAuthenticatedUser.attributes),
    locationIds: getUserLocationIds(currentAuthenticatedUser.attributes),
  }
}
