import { PaginationSearchParams, ListResponse } from './types'
import { API } from 'aws-amplify'
import { getNextCursor } from './utils'

export interface Role {
  role_id: string
  name: string
  permissions: string[]
}

export type CreateRolePayload = Omit<Role, 'role_id'>

export type UpdateRolePayload = CreateRolePayload

export const getRolesList = async (cursor: PaginationSearchParams = {}): Promise<ListResponse<Role>> => {
  const response = await API.get('users', '/users/roles', {
    queryStringParameters: {
      responseFormat: 'list',
      ...cursor,
    },
  })

  return {
    ...response,
    nextCursor: getNextCursor(response, cursor),
  }
}

export const fetchPermissions = async (): Promise<string[]> => {
  return API.get('users', `/users/permissions`, {})
}

export const fetchCurrentUserRoles = async (): Promise<Role[]> => {
  return API.get('users', `/users/auth-user-roles`, {})
}

export const deleteRole = async (role: Role) => {
  return API.del('users', `/users/roles/${role.role_id}`, {})
}

export const createRole = async (payload: CreateRolePayload) => {
  return API.post('users', `/users/roles`, {
    body: payload,
  })
}

export const updateRole = async (role: Role, payload: UpdateRolePayload) => {
  return API.put('users', `/users/roles/${role.role_id}`, {
    body: payload,
  })
}
