import React from 'react'
import { Heading as ChakraHeading, HeadingProps, forwardRef, ComponentWithAs } from '@chakra-ui/react'
import { ShadeProps, withShade } from './utils'

type Props = HeadingProps & ShadeProps

const Heading = withShade(ChakraHeading)

function createHeading(n: number, fontSize: string): ComponentWithAs<'h1', Props> {
  const Component = forwardRef<Props, 'h1'>((props, ref) => {
    return <Heading ref={ref} as={`h${n}` as any} fontSize={fontSize} lineHeight="2.5rem" {...props} />
  })

  Component.displayName = `H${n}`

  return Component
}

export const H1 = createHeading(1, '4xl')
export const H2 = createHeading(2, '3xl')
export const H3 = createHeading(3, '2xl')
export const H4 = createHeading(4, 'xl')
export const H5 = createHeading(5, 'lg')
export const H6 = createHeading(6, 'md')
