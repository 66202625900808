import { createIcon } from '@chakra-ui/icon'

export const ArrowForwardIcon = createIcon({
  displayName: 'ArrowForwardIcon',
  path: (
    <path
      d="M10.5 3.5L9.26625 4.73375L14.1488 9.625H3.5V11.375H14.1488L9.26625 16.2663L10.5 17.5L17.5 10.5L10.5 3.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 21',
})
