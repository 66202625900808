import React from 'react'
import { Box } from '@chakra-ui/react'
import { UserCreateButton } from './UserCreateButton/UserCreateButton'

interface Props {
  refetch: () => Promise<unknown>
}

export const Actions: React.FC<Props> = ({ refetch }) => {
  return (
    <Box>
      <UserCreateButton refetch={refetch} />
    </Box>
  )
}
