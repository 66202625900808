import { pickBy, uniq } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ReadyState } from 'react-use-websocket'
import { WebSocketHook } from 'react-use-websocket/dist/lib/types'

export type SubscribeHook = {
  subscribe: (channel: string) => void
  unsubscribe: (channel: string) => void
  serverOffset: number
}

type SubscribeMessage = {
  status: boolean
  action: 'subscribe' | 'unsubscribe'
  channel: string
  timestamp: number
}

export const useChannelSubscribe = ({ sendJsonMessage, readyState, lastJsonMessage }: WebSocketHook) => {
  const [serverOffset, setServerOffset] = useState(0)
  const [expectedChannelsMap, setExpectedChannelsMap] = useState<Record<string, number>>({})
  const subscribedChannels = useRef<string[]>([])

  const subscribe = useCallback((channel: string) => {
    setExpectedChannelsMap((map) => ({
      ...map,
      [channel]: (map[channel] ?? 0) + 1,
    }))
  }, [])
  const unsubscribe = useCallback((channel: string) => {
    setExpectedChannelsMap((map) => ({
      ...map,
      [channel]: map[channel] - 1,
    }))
  }, [])

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) {
      subscribedChannels.current = []
    }
  }, [readyState])

  const expectedChannels = Object.keys(pickBy(expectedChannelsMap, (count) => count > 0))
  useEffect(() => {
    if (readyState !== ReadyState.OPEN) return
    let retryTimeout: number
    let retryCount = 0
    const syncChannels = () => {
      let pending = false
      expectedChannels.forEach((channel) => {
        if (!subscribedChannels.current.includes(channel)) {
          pending = true
          sendJsonMessage({ action: 'subscribe', channel })
        }
      })
      subscribedChannels.current.forEach((channel) => {
        if (!expectedChannels.includes(channel)) {
          pending = true
          sendJsonMessage({ action: 'unsubscribe', channel })
        }
      })
      if (retryCount > 10) {
        console.log('Max subscribe retries reached')
        return
      }
      if (pending) {
        retryCount++
        retryTimeout = window.setTimeout(syncChannels, 3000)
      }
    }
    syncChannels()
    return () => window.clearTimeout(retryTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState, expectedChannels.join()])

  useEffect(() => {
    const subscribeMessage: SubscribeMessage | null = lastJsonMessage
    if (subscribeMessage?.action === 'subscribe') {
      setServerOffset(Date.now() - subscribeMessage.timestamp)
      subscribedChannels.current = uniq([...subscribedChannels.current, subscribeMessage.channel])
      return
    }
    if (subscribeMessage?.action === 'unsubscribe') {
      subscribedChannels.current = subscribedChannels.current.filter((channel) => channel !== subscribeMessage.channel)
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  return { subscribe, unsubscribe, serverOffset }
}
