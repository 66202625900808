import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'

export const TOCtext = () => {
  return (
    <Box>
      <Text mb={6} fontSize="sm" as="i">
        Effective date: 23rd of October, 2020
      </Text>

      <Text mb={6} fontSize="sm">
        Welcome to Keee. Please read on to learn the rules and restrictions that govern your use of our website(s),
        products, services and applications (the “Services”). If you have any questions, comments, or concerns regarding
        these terms or the Services, please contact us at:
      </Text>

      <Text mb={6} fontSize="sm">
        These Terms of Use (the “Terms”) are a binding contract between you and Keee (“Keee,” “we” and “us”). Your use
        of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect
        while you use the Services. These Terms include the provisions in this document as well as those in the Privacy
        Policy and any other relevant policies. Your use of or participation in certain Services may also be subject to
        additional policies, rules and/or conditions (“Additional Terms”), which are incorporated herein by reference,
        and you understand and agree that by using or participating in any such Services, you agree to also comply with
        these Additional Terms.
      </Text>

      <Text mb={6} fontSize="sm">
        Please read these Terms carefully. They cover important information about Services provided to you and any
        charges, taxes, and fees we bill you. These Terms include information about future changes to these Terms,
        automatic renewals, limitations of liability, a class action waiver and resolution of disputes by arbitration
        instead of in court. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS;
        IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
      </Text>

      <Text mb={6} fontSize="sm">
        ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION
        AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
        ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
      </Text>

      <Heading size="md" mb={3} mt={6}>
        Will these Terms ever change?
      </Heading>
      <Text mb={6} fontSize="sm">
        We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We
        reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at
        https://keee.ai/, send you an email, and/or notify you by some other means.
      </Text>
      <Text mb={6} fontSize="sm">
        If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer
        be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that
        means you agree to all of the changes.
      </Text>
      <Text mb={6} fontSize="sm">
        Except for changes by us as described here, no other amendment or modification of these Terms will be effective
        unless in writing and signed by both you and us.
      </Text>

      <Heading size="md" mb={3} mt={6}>
        What about my privacy?
      </Heading>
      <Text mb={6} fontSize="sm">
        Keee takes the privacy of its users very seriously. For the current Keee Privacy Policy, please click here.
      </Text>

      <Text mb={6} fontSize="sm" as="i">
        Children’s Online Privacy Protection Act
      </Text>

      <Text mb={6} fontSize="sm">
        The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
        consent before they knowingly collect personally identifiable information online from children who are under
        thirteen (13). We do not knowingly collect or solicit personally identifiable information from children under
        thirteen (13); if you are a child under thirteen (13), please do not attempt to register for or otherwise use
        the Services or send us any personal information. If we learn we have collected personal information from a
        child under thirteen (13), we will delete that information as quickly as possible. If you believe that a child
        under thirteen (13) may have provided us personal information, please contact us at hello@Keee.app.
      </Text>
    </Box>
  )
}
