export const palette = {
  red: '#E63535',
  green: '#05A660',
  blue: '#004FC4',
  yellow: '#E6B800',
  orange: '#E67A00',
  teal: '#00B7C4',
  purple: '#4D0099',
}

const camel2title = (camelCase: string) =>
  camelCase.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase())

export function hexToColorName(hex: string): string {
  if (!hex) {
    return ''
  }
  const colorName = Object.keys(palette).find((key) => {
    const colors = palette as any
    return typeof colors[key] === 'string' && colors[key].toUpperCase() === hex.toUpperCase()
  })
  return colorName ? camel2title(colorName) : hex
}

export const paletteColorOptions = Object.values(palette).map((value) => ({
  label: hexToColorName(value as string),
  value: value as string,
}))
