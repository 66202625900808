import { deleteDeviceType } from 'libs/api'
import { createDeleteButton } from '../../../../components'
import translations from './DeviceTypeDeleteButton.i18n.json'

export const DeviceTypeDeleteButton = createDeleteButton({
  translations,
  onConfirm: deleteDeviceType,
  getName: (user) => user.name,
  subject: 'thing_types',
})
