import React, { MutableRefObject, useCallback, useRef, useState } from 'react'
import { Layer, Stage, Rect, Text } from 'react-konva'
import { Button, ImageURL, Barcode, EditableText } from 'components'
import Konva from 'konva'
import printJs from 'print-js'
import styled from '@emotion/styled'
import { FaPrint } from 'react-icons/fa'
import { BadgeInformation, badgeRoles, Person } from 'libs/api'
import { jsPDF } from 'jspdf'
import { useNotify, useTranslations, useValidationRules } from 'hooks'
import translations from './Badge.i18n.json'

const Container = styled.div``

const BadgeFaceControls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
`

const BadgeFaceContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0.4px 1.3px rgba(0, 0, 0, 0.021), 0 1px 3.2px rgba(0, 0, 0, 0.03), 0 1.9px 6px rgba(0, 0, 0, 0.036),
    0 3.4px 10.7px rgba(0, 0, 0, 0.043), 0 6.3px 20.1px rgba(0, 0, 0, 0.051), 0 15px 48px rgba(0, 0, 0, 0.07);

  ${BadgeFaceControls} {
    transition: opacity 0.3s;
    opacity: 0;
  }

  &:hover {
    ${BadgeFaceControls} {
      opacity: 1;
    }
  }
`

type BadgeProps = {
  employee: Person
  templateFront: string
  templateBack: string
  width: number
  height: number
  onUpdate?: (changes: Partial<BadgeInformation>) => void
  isLoading?: boolean
}

export const BadgeControls = (props: { canvasRef: MutableRefObject<Konva.Stage | null> }) => {
  function onPrint() {
    if (props.canvasRef.current) {
      const canvas = props.canvasRef.current?.toCanvas({})

      canvas?.toBlob(
        (blob) => {
          const url = URL.createObjectURL(blob)
          printJs(url, 'image')
        },
        'image/jpeg',
        0.9
      )
    }
  }

  return (
    <BadgeFaceControls>
      <Button onClick={onPrint}>
        <FaPrint />
      </Button>
    </BadgeFaceControls>
  )
}

export const Badge: React.FC<BadgeProps> = ({
  employee,
  templateFront,
  templateBack,
  width,
  height,
  onUpdate,
  isLoading,
}) => {
  const barcodeRef = useRef<Konva.Image>()
  const frontStageRef = useRef<Konva.Stage | null>(null)
  const backStageRef = useRef<Konva.Stage | null>(null)
  const [imageDimension, setImageDimension] = useState<{ width: number; height: number }>({ width: 1, height: 1 })
  const [barcodeDimensions, setBarcodeDimensions] = useState<{ width: number; height: number }>({ width: 1, height: 1 })
  const [localBadgeState, setLocalBadgeState] = useState<BadgeInformation>({
    abbreviation: 'CI1', // TODO use location abbreviation
    address: 'CLAYTON, IN', // employee.zones.map((zone) => zone.name).join(', '),
    name: employee.badge?.custom_fields?.name || `${employee.first_name} ${employee.last_name}`.toUpperCase(),
    badgeString: employee.badge?.badge_string || '',
    employeeString: employee.employee_string || '',
  })

  const [enableSaveButton, setEnableSaveButton] = useState(false)
  const t = useTranslations(translations)
  const rules = useValidationRules()
  const notify = useNotify()

  const onImageLoaded = useCallback(
    (img: HTMLImageElement, ref: Konva.Image | null) => {
      if (!ref) {
        return
      }
      setImageDimension({ width: ref.attrs.width, height: ref.attrs.height })
    },
    [setImageDimension]
  )

  const onBarcodeLoaded = useCallback(() => {
    if (barcodeRef.current) {
      // Delay the use of the ref since the barcode library is not resolving the image element right away
      setTimeout(() => {
        const barcodeImage = barcodeRef.current?.attrs.image
        setBarcodeDimensions({ width: barcodeImage.width, height: barcodeImage.height })
      }, 100)
    }
  }, [])

  async function onPrint() {
    if (frontStageRef.current && backStageRef.current) {
      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF({ format: [244.8, 158.4], orientation: 'landscape' })
      doc.addImage(frontStageRef.current!.toCanvas({}), 'JPEG', 0, 0, 244.8, 158.4)
      doc.addPage()
      // Fix printer upside down
      const backStage = backStageRef.current!
      backStage.rotation(180)
      backStage.x = backStage.width
      backStage.y = backStage.height
      doc.addImage(backStage.toCanvas({}), 'JPEG', 0, 0, 244.8, 158.4)
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    }
  }

  const imagePosition = {
    x: width - (imageDimension.width + 100),
    y: 50,
  }

  const barcodePosition = {
    x: width / 2 - barcodeDimensions.width / 2,
    y: height - barcodeDimensions.height - 40,
  }

  function onUpdateBadge(attributeName: keyof BadgeInformation, attributeValue: string) {
    setLocalBadgeState((current) => ({ ...current, [attributeName]: attributeValue }))
    setEnableSaveButton(true)
  }

  const badgeRole = badgeRoles.find((role) => role.name === employee.badge?.custom_fields?.badge_role) ?? badgeRoles[0]

  return (
    <Container>
      <BadgeFaceContainer>
        <Stage width={width} height={height} ref={frontStageRef}>
          <Layer>
            <Rect width={width} height={height} fill="white" />
            <Rect width={58} height={height - 50} y={21} x={width - 78} fill={badgeRole.color} />
            <Text
              text={badgeRole.name.toUpperCase()}
              fontSize={38}
              align="center"
              fill="white"
              x={width - 33}
              y={21}
              width={height - 50}
              rotation={90}
            />
            <ImageURL x={0} y={0} src={templateFront} width={width} />
            <ImageURL
              src={employee.image_url}
              x={imagePosition.x}
              y={imagePosition.y}
              strokeWidth={3}
              stroke="black"
              width={250}
              onLoaded={onImageLoaded}
            />
            <EditableText
              value={localBadgeState.abbreviation}
              onChange={(newValue) => onUpdateBadge('abbreviation', newValue)}
              fontSize={25}
              x={60}
              y={192}
              align="center"
              width={300}
              placeholder="CI1"
            />
            <EditableText
              value={localBadgeState.address}
              onChange={(newValue) => onUpdateBadge('address', newValue)}
              fontSize={25}
              x={60}
              y={227}
              align="center"
              width={300}
              placeholder="CLAYTON, IN"
            />
            <EditableText
              value={localBadgeState.name}
              onChange={(newValue) => onUpdateBadge('name', newValue)}
              fontSize={30}
              x={60}
              y={130}
              fontStyle="bold"
              align="center"
              width={300}
            />
            <EditableText
              value={localBadgeState.employeeString}
              onChange={(newValue) => onUpdateBadge('employeeString', newValue)}
              fontSize={28}
              x={170}
              y={287}
              placeholder={t.employeeString}
            />
            <EditableText
              value={localBadgeState.badgeString}
              onChange={(newValue) => {
                try {
                  rules.badgeNumber().label(t.badgeString).validateSync(newValue)
                  onUpdateBadge('badgeString', newValue)
                } catch (e) {
                  notify.error(e)
                }
              }}
              fontSize={28}
              x={170}
              y={342}
            />
            <Text
              text="S U P P L Y  C H A I N"
              fontStyle="bold"
              fontSize={30}
              align="center"
              fill="black"
              stroke="black"
              letterSpacing={2}
              x={200}
              y={460}
            />
          </Layer>
        </Stage>
        <BadgeControls canvasRef={frontStageRef} />
      </BadgeFaceContainer>

      <BadgeFaceContainer>
        <Stage width={width} height={height} ref={backStageRef}>
          <Layer>
            <Rect width={width} height={height} fill="white" />
            <ImageURL x={0} y={0} src={templateBack} width={width} />
            <Barcode
              code={localBadgeState.badgeString}
              ref={barcodeRef}
              onLoaded={onBarcodeLoaded}
              x={barcodePosition.x}
              y={barcodePosition.y}
            />
          </Layer>
        </Stage>
        <BadgeControls canvasRef={backStageRef} />
      </BadgeFaceContainer>
      <Button onClick={onPrint} marginRight={2}>
        {t.print}
      </Button>
      {enableSaveButton && (
        <Button isLoading={isLoading} onClick={() => onUpdate?.(localBadgeState)}>
          {t.save}
        </Button>
      )}
    </Container>
  )
}
