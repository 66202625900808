import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button as ChakraButton,
  chakra,
  RenderProps,
  Stack,
  useColorModeValue,
  UseToastOptions,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { CloseButton } from '@chakra-ui/close-button'
import { useBgColorFromShade, useTextColorFromShade } from 'components/core/utils'

export type ToastAction = {
  title: string
  onClick: (() => Promise<unknown>) | (() => void)
}

type Props = Pick<UseToastOptions, 'status' | 'title' | 'isClosable' | 'description'> &
  RenderProps & {
    actions: ToastAction[]
  }

export const ToastWithActions = ({ status, id, title, isClosable, onClose, description, actions }: Props) => {
  const color = useTextColorFromShade(1)
  const bgColor = useBgColorFromShade(1)
  const variant = useColorModeValue(undefined, 'solid')
  const errorColor = useColorModeValue('red', color)
  const [isPending, setIsPending] = useState(false)
  return (
    <Alert
      variant={variant}
      status={status}
      id={`${id}`}
      alignItems="start"
      borderRadius="md"
      boxShadow="lg"
      paddingRight={8}
      textAlign="left"
      width="auto"
      {...(status ? {} : { color, bgColor })}
    >
      <chakra.div flex="1">
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && (
          <AlertDescription display="block" fontSize="sm">
            {description}
          </AlertDescription>
        )}
        <Stack direction="row" spacing={2} mt={3} align="center" justify="flex-end">
          {actions.map((action, index) => (
            <ChakraButton
              key={index}
              colorScheme={status === 'error' ? errorColor : 'primary'}
              size="xs"
              variant="outline"
              isLoading={isPending}
              onClick={async () => {
                try {
                  setIsPending(true)
                  await action.onClick()
                  onClose()
                } finally {
                  setIsPending(false)
                }
              }}
            >
              {action.title}
            </ChakraButton>
          ))}
        </Stack>
      </chakra.div>
      {isClosable && <CloseButton size="sm" onClick={onClose} position="absolute" right={1} top={1} />}
    </Alert>
  )
}
