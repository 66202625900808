import { deleteLabel } from 'libs/api'
import { createDeleteButton } from '../../../../components'
import translations from './LabelDeleteButton.i18n.json'

export const LabelDeleteButton = createDeleteButton({
  translations,
  onConfirm: deleteLabel,
  getName: (label) => label.name,
  subject: 'categories' as never, // TODO add subject / remove categories for good
})
