import React, { useContext } from 'react'
import { Box } from '../index'
import { TableStyleContext } from './TableStyleProvider'

interface Props {
  onClick?: () => void
}

export const TableRow: React.FC<Props> = ({ children, onClick }) => {
  const { bgShade } = useContext(TableStyleContext)
  const cursor = onClick ? 'pointer' : 'default'
  return (
    <Box as="tr" bgShade={bgShade} cursor={cursor} onClick={onClick}>
      {children}
    </Box>
  )
}
