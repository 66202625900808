import React, { createContext, useContext } from 'react'
import {
  SkeletonProps as ChakraSkeletonProps,
  SkeletonText as ChakraSkeletonText,
  Skeleton as ChakraSkeleton,
  useColorModeValue,
  SkeletonTextProps,
} from '@chakra-ui/react'
import { withShade } from './utils'

const SkeletonContext = createContext<{ isLoaded: boolean }>({ isLoaded: true })

export function SkeletonProvider({ isLoaded, children }: { isLoaded: boolean; children: React.ReactNode }) {
  return <SkeletonContext.Provider value={{ isLoaded }}>{children}</SkeletonContext.Provider>
}

type SkeletonProps = ChakraSkeletonProps & {
  isInline?: boolean
}

export const Skeleton = withShade<SkeletonProps>(({ isInline, ...skeletonProps }) => {
  const context = useContext(SkeletonContext)
  const isLoaded = skeletonProps.isLoaded ?? context.isLoaded
  const startColor = useColorModeValue('gray.100', 'gray.700')
  const endColor = useColorModeValue('gray.300', 'gray.600')
  return (
    <ChakraSkeleton
      startColor={startColor}
      endColor={endColor}
      isLoaded={isLoaded}
      display={isInline ? 'inline-block' : 'block'}
      textColor={isLoaded ? undefined : 'transparent'}
      {...skeletonProps}
    />
  )
})

export const SkeletonText = withShade<SkeletonTextProps>((props) => {
  const { isLoaded } = useContext(SkeletonContext)
  const startColor = useColorModeValue('gray.100', 'gray.700')
  const endColor = useColorModeValue('gray.300', 'gray.600')
  return (
    <ChakraSkeletonText
      startColor={startColor}
      endColor={endColor}
      isLoaded={isLoaded}
      textColor={isLoaded ? undefined : 'transparent'}
      {...props}
    />
  )
})

export function useSkeletonContext() {
  return useContext(SkeletonContext)
}
