import React from 'react'
import { Box } from 'components'
import { BoxProps } from '@chakra-ui/react'

type Props = BoxProps & {
  color?: string
  children: React.ReactNode
}

export function Chip({ color, ...boxProps }: Props) {
  return (
    <Box
      display="inline-block"
      border="1px solid"
      fontFamily="label"
      fontSize="xs"
      lineHeight={1.33}
      fontWeight="bold"
      p={2}
      borderColor={color}
      color={color}
      borderRadius={2}
      whiteSpace="nowrap"
      {...boxProps}
    />
  )
}
