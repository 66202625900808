import React, { ReactElement } from 'react'
import { useTranslations } from 'hooks'
import { ButtonLink } from 'components'
import translations from './EditButton.i18n.json'
import { Box } from '@chakra-ui/react'
import { can, Subject } from 'utils/access'
import { useAuthContext } from 'hooks'

type Props<T> = {
  item: T
  refetch: () => Promise<unknown>
}

type ComponentType<T> = React.FC<
  Props<T> & {
    children: ({ open }: { open: () => void }) => ReactElement
  }
>

export function createEditButton<T>(EditComponent: ComponentType<T>, subject: Subject) {
  return ({ item, refetch }: Props<T>) => {
    const t = useTranslations(translations)

    const { currentRole } = useAuthContext()

    return (
      <Box>
        {can(currentRole, 'update', subject) && (
          <EditComponent item={item} refetch={refetch}>
            {({ open }) => (
              <ButtonLink
                onClick={(event) => {
                  event.stopPropagation()
                  open()
                }}
              >
                {t.edit}
              </ButtonLink>
            )}
          </EditComponent>
        )}
      </Box>
    )
  }
}
