import React, { useState, useEffect } from 'react'

type HealthModeContextType = {
  healthMode: boolean
  setHealthMode: (mode: boolean) => void
}

export const HealthModeContext = React.createContext<HealthModeContextType>({} as never)

const HEALTH_KEY = 'keee-health'

export const HealthModeProvider: React.FC = ({ children }) => {
  const [healthMode, setHealthMode] = useState(localStorage.getItem(HEALTH_KEY) === 'true')
  useEffect(() => {
    localStorage.setItem(HEALTH_KEY, healthMode.toString())
  }, [healthMode])
  return <HealthModeContext.Provider value={{ healthMode, setHealthMode }}>{children}</HealthModeContext.Provider>
}
