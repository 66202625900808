import React from 'react'
import { createIcon } from '@chakra-ui/icon'

export const ThumbsUpIcon = createIcon({
  displayName: 'ThumbsUpIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.667 6.12l4.78-4.786 1.093 1.08-.746 3.587h5.54v2.933l-2.46 5.733H4.667V6.121zm-1.333.547H.667v8h2.667v-8z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
})
